import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { cleanup, getRemediation, getReport } from "@/reducers/activity/assetVulnerabilityReport/assetVulnerabilityReportAction";
import { Col, Row } from "react-bootstrap";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import DoughnutChart from "@/shared/components/charts/DoughnutChart";
import LineChart from "@/shared/components/charts/LineChart";
import StackedBarsChart from "@/shared/components/charts/StackedBarsChart";
import StackedLinesChart from "@/shared/components/charts/StackedLinesChart";

const AssetVulnerabilityReport = () => {
    const { 
        activity, 
        workflowActivityStepCompletedId, 
        isEditor,
        handleRefreshProgress 
    } = useOutletContext();
    
    const dispatch = useDispatch();
    const { 
        remediation, isLoadingRemediation, getRemediationError,
        report, isLoadingReport, getReportError
    } = useSelector(({ assetVulnerabilityReport }) => assetVulnerabilityReport);
    
    useEffect(() => {
        dispatch(getRemediation(activity.workflowActivityId));
    
        return () => {
          dispatch(cleanup());
        }
    }, []);

    useEffect(() => {
    if(getRemediationError){
        enqueueSnackbar(getRemediationError, {variant: "error"});
    }
    }, [getRemediationError]);

    useEffect(() => {
        if(getReportError){
            enqueueSnackbar(getReportError, {variant: "error"});
        }
    }, [getReportError]);

    useEffect(() => {
        if(remediation){
            dispatch(getReport(remediation.id));
        }
    }, [remediation]);

    
    if(isLoadingReport || !report)
        return <CircularProgressWrapper />

    return(
        <>
            <Row>
                <Col md={6} style={{marginTop: 30}}>
                    <LineChart
                        chartSeries={report.find(r => r.name === "VmReportingAssetVulnerabilityPlannedAll")} 
                        title={{
                            text: 'Vulnerability Planned'
                        }}
                    />
                </Col>
                <Col md={6} style={{marginTop: 30}}>
                    <DoughnutChart
                        chartSeries={report.find(r => r.name === "VmReportingAssetVulnerabilityStatusAll")} 
                        title={{
                            text: 'Vulnerability Status'
                        }}
                        legend={{
                            orient: 'vertical',
                            left: 'left',
                            top: '10%'
                        }}
                    />
                </Col>
                <Col md={6} style={{marginTop: 30}}>
                    <StackedLinesChart
                        chartSeries={report.find(r => r.name === "VmReportingAssetVulnerabilityPlannedContextualScore")} 
                        title={{
                            text: 'Date Planned Contextual Score'
                        }}
                        legend={{
                            top: '10%'
                        }}
                    />
                </Col>
                <Col md={6} style={{marginTop: 30}}>
                    <StackedBarsChart 
                        chartSeries={report.find(r => r.name === "VmReportingAssetVulnerabilityStatusContextualScore")} 
                        title={{
                            text: 'Status Contextual Score'
                        }}
                        legend={{
                            top: '10%'
                        }}
                    />
                </Col>

                <Col md={6} style={{marginTop: 30}}>
                    <StackedLinesChart 
                        chartSeries={report.find(r => r.name === "VmReportingAssetVulnerabilityPlannedCVSS")} 
                        title={{
                            text: 'Date Planned CVSS'
                        }}
                        legend={{
                            top: '10%'
                        }}
                    />
                </Col>
                
                <Col md={6} style={{marginTop: 30}}>
                    <StackedBarsChart 
                        chartSeries={report.find(r => r.name === "VmReportingAssetVulnerabilityStatusCVSS")} 
                        title={{
                            text: 'Status CVSS'
                        }}
                        legend={{
                            top: '10%'
                        }}
                    />
                </Col>

                <Col md={6} style={{marginTop: 30}}>
                    <StackedLinesChart 
                        chartSeries={report.find(r => r.name === "VmReportingAssetVulnerabilityPlannedEPSS")} 
                        title={{
                            text: 'Date Planned EPSS'
                        }}
                        legend={{
                            top: '10%'
                        }}
                    />
                </Col>

                <Col md={6} style={{marginTop: 30}}>
                    <StackedBarsChart 
                        chartSeries={report.find(r => r.name === "VmReportingAssetVulnerabilityStatusEPSS")} 
                        title={{
                            text: 'Status EPSS'
                        }}
                        legend={{
                            top: '10%'
                        }}
                    />
                </Col>

                <Col md={6} style={{marginTop: 30}}>
                    <StackedLinesChart 
                        chartSeries={report.find(r => r.name === "VmReportingAssetVulnerabilityPlannedExploit")} 
                        title={{
                            text: 'Date Planned Exploit'
                        }}
                        legend={{
                            top: '10%'
                        }}
                    />
                </Col>

                <Col md={6} style={{marginTop: 30}}>
                    <StackedBarsChart 
                        chartSeries={report.find(r => r.name === "VmReportingAssetVulnerabilityStatusExploit")} 
                        title={{
                            text: 'Status Exploit'
                        }}
                        legend={{
                            top: '10%'
                        }}
                    />
                </Col>
          </Row>
        </>
    )
}

export default AssetVulnerabilityReport;