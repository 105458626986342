import React from "react";
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountFooter,
  AccountTitle,
  AccountWrapImage,
  Logo,
  FormWrapper,
  LogoCompany,
  FormButtonContainer,
  AccountHeader,
  AccountLogoFooter,
  TitleWrapper,
  SubtitleWrapper
} from "@/shared/components/account/AccountElements";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { useForm } from "react-hook-form";
import { Backdrop, Button, CircularProgress, InputAdornment, Link, Typography } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { forgotPassword, cleanup } from "@/reducers/auth/forgotPassword/forgotPasswordAction";
import { enqueueSnackbar } from "notistack";


const ForgotPassword = () => {
    const navigate = useNavigate();

    const {
        isSendingForgotPasswordRequest, forgotPasswordRequestError, sentForgotPasswordRequest
    } = useSelector(({ forgotPassword }) => forgotPassword);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(forgotPasswordRequestError){
            enqueueSnackbar(forgotPasswordRequestError, {variant: "error"});
        }
    }, [forgotPasswordRequestError]);

    const { handleSubmit, reset, control, setValue, getValues } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        dispatch(forgotPassword(data));
    };

    useEffect(() => {
        if(sentForgotPasswordRequest){
            enqueueSnackbar("Reset password email sent successfully. Please verify your inbox", {variant: "success"});
            reset();
            navigate("/Account/Login");
        }
    }, [sentForgotPasswordRequest]);

    return(
    <AccountWrapImage>
        <AccountContent>
            <AccountCard>
                <AccountHeader />

                <Row>
                    <Col md={12}>
                        <TitleWrapper>
                            <Typography variant="h5">
                                Forgot Password?
                            </Typography>
                        </TitleWrapper>

                        <SubtitleWrapper>
                            <Typography variant="subtitle2">
                                We will send you an email to your inbox for resetting your password.
                            </Typography>
                        </SubtitleWrapper>
                    </Col>
                </Row>

                <FormWrapper>
                    <Row>
                        <Col md={12}>
                            <FormTextField
                                name="emailOrUsername"
                                label="Email or Username"
                                control={control}
                                rules={{ required: 'This field is required' }}
                                size=''
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircleIcon />
                                    </InputAdornment>
                                    ),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormButtonContainer>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                    size="large"
                                >
                                    Continue
                                </Button>
                            </FormButtonContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <ForgotPasswordWrapper>
                                <Link component={ReactRouterLink} to="/Account/Login" underline="hover">
                                    Back to login
                                </Link>
                            </ForgotPasswordWrapper>
                        </Col>
                    </Row>
                </FormWrapper>

                <AccountLogoFooter />
            </AccountCard>
        </AccountContent>

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSendingForgotPasswordRequest}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </AccountWrapImage>
    );
};

const ForgotPasswordWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 5px;
`;

export default ForgotPassword;
