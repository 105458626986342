import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';

const TextWritter = ({ text, delay = 10, onFinish, ...other }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (text && currentIndex <= text.length) {
      timeout = setTimeout(() => {
        if(text[currentIndex])
            setCurrentText(prevText => prevText + text[currentIndex]);

        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);
    }
    else {
      if(onFinish){
        onFinish();
      }
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, text]);

  return <Typography {...other}>{currentText}</Typography>;
};

export default TextWritter;