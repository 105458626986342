import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_BUSINESS_SECTORS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getNaicsRequest = createAction('BUSINESS_SECTORS_GET_NAICS_REQUEST');
export const getNaicsSuccess = createAction('BUSINESS_SECTORS_GET_NAICS_SUCCESS');
export const getNaicsError = createAction('BUSINESS_SECTORS_GET_NAICS_ERROR');

export const getNaics = () => async (dispatch) => {
  try {
    dispatch(getNaicsRequest());
    const resp = await instance.get(`/v1/DictionaryNaics/GetAll`);
    
    dispatch(getNaicsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getNaicsError(e.response.data.description));
    else
      dispatch(getNaicsError(e.message));
  }
};

export const deleteBusinessSectorRequest = createAction('BUSINESS_SECTORS_DELETE_REQUEST');
export const deleteBusinessSectorSuccess = createAction('BUSINESS_SECTORS_DELETE_SUCCESS');
export const deleteBusinessSectorError = createAction('BUSINESS_SECTORS_DELETE_ERROR');

export const deleteBusinessSector = (request) => async (dispatch) => {
  try {
    dispatch(deleteBusinessSectorRequest());
    const resp = await instance.post(`/v1/Asset/AddMetadata`, request);

    dispatch(deleteBusinessSectorSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteBusinessSectorError(e.response.data.description));
    else
      dispatch(deleteBusinessSectorError(e.message));
  }
};