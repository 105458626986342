import { createAction } from 'redux-actions';
import { instance } from "@/config/interceptor";

export const cleanupAction = createAction('CLEANUP_TENANT_SETUP_ADD_BUSINESS_UNIT');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const addBusinessUnitRequest = createAction('TENANT_SETUP_ADD_BUSINESS_UNIT_POST_REQUEST');
export const addBusinessUnitSuccess = createAction('TENANT_SETUP_ADD_BUSINESS_UNIT_POST_SUCCESS');
export const addBusinessUnitError = createAction('TENANT_SETUP_ADD_BUSINESS_UNIT_POST_ERROR');

export const addBusinessUnit = (request) => async (dispatch) => {
  try {
    dispatch(addBusinessUnitRequest());
    const resp = await instance.post(`/v1/TenantSetup/AddBusinessUnit`, request);

    dispatch(addBusinessUnitSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addBusinessUnitError(e.response.data.description));
    else
      dispatch(addBusinessUnitError(e.message));
  }
};