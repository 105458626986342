import MostCommonEntities from './MostCommonEntities';
import SentimentOverTime from './SentimentOverTime';
import { Col, Row } from 'react-bootstrap';
import Stories from './Stories';

const CloudServiceTab = ({
    isLoadingStories,
    stories,
    isLoadingTrends,
    trendsData,
    isLoadingSentiment,
    sentimentData,
    sentimentEntity,
    onSelectEntity
}) => {

    return(
        <>
            <Row style={{ marginBottom: 30 }}>
                <Col md={6}>
                    <MostCommonEntities 
                        isLoading={isLoadingTrends}
                        data={trendsData}
                        onSelectEntity={onSelectEntity}
                    />
                </Col>
                <Col md={6}>
                    <SentimentOverTime 
                        isLoading={isLoadingSentiment}
                        data={sentimentData}
                        entity={sentimentEntity}
                    />
                </Col>
            </Row>
            <Stories 
                isLoading={isLoadingStories}
                stories={stories}
            />
        </>
    );
};

export default CloudServiceTab;