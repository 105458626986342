import { configureStore } from "@reduxjs/toolkit";
import ChangeThemeReducer from "./../reducers/utils/changeTheme/changeThemeReducer";
import AuthReducer from "./../reducers/auth/authReducer";
import SurveysReducer from "./../reducers/surveys/surveysReducer";
import CytoscapeReducer from "./../reducers/cytoscape/cytoscapeReducer";
import AssetTypeReducer from "../reducers/assetType/view/assetTypeReducer";
import AssetTypeCreateReducer from "../reducers/assetType/create/assetTypeCreateReducer";
import AssetTypeUpdateReducer from "../reducers/assetType/update/assetTypeUpdateReducer";
import AssetInfoReducer from "../reducers/asset/info/assetInfoReducer";
import AdvancedFiltersReducer from "./../reducers/cytoscape/advancedFilters/advancedFiltersReducer";
import FieldValuesReducer from "../reducers/fieldValues/fieldValuesReducer";
import AssetViewReducer from "../reducers/asset/view/assetViewReducer";
import AssetVulnerabilityViewReducer from "../reducers/assetVulnerability/view/viewReducer";
import AssetVulnerabilityListReducer from "../reducers/activity/assetVulnerabilityList/assetVulnerabilityListReducer";
import CheckListViewReducer from "../reducers/checkList/view/viewReducer";
import AssetVulnerabilityCheckListReducer from "../reducers/activity/assetVulnerabilityCheckList/assetVulnerabilityCheckListReducer";
import RemediationViewReducer from "../reducers/remediation/view/viewReducer";
import AddRemediationReducer from "../reducers/assetVulnerability/addRemediation/addRemediationReducer";
import ActivityCreateReducer from "../reducers/activity/create/activityCreateReducer";
import EditStepReducer from "../reducers/activity/editStep/editStepReducer";
import GeneralInfoReducer from "../reducers/activity/generalInfo/generalInfoReducer";
import MenuReducer from "../reducers/menu/menuReducer";
import ListCreateActivityReducer from "../reducers/activity/listCreate/listCreateReducer";
import ListCurrentActivitiesReducer from "../reducers/activity/listCurrentActivities/listCurrentActivitiesReducer";
import EditActivityInfoReducer from "../reducers/activity/editActivityInfo/editActivityInfoReducer";
import ServicesReducer from "../reducers/services/servicesReducer";
import AddServiceReducer from "../reducers/services/addService/addServiceReducer";
import UpdateServiceReducer from "../reducers/services/updateService/updateServiceReducer";
import ServiceConnectorsTableReducer from "../reducers/services/serviceConnectorsTable/serviceConnectorsReducer";
import VulnerabilityStatusesReducer from "../reducers/vulnerabilityStatuses/vulnerabilityStatusesReducer";
import AddVulnerabilityStatusReducer from "../reducers/vulnerabilityStatuses/addVulnerabilityStatus/addVulnerabilityStatusReducer";
import UpdateVulnerabilityStatusReducer from "../reducers/vulnerabilityStatuses/updateVulnerabilityStatus/updateVulnerabilityStatusReducer";
import VulnerabilityScoresReducer from "../reducers/vulnerabilityScores/vulnerabilityScoresReducer";
import VulnerabilitiesReducer from "../reducers/vulnerabilities/vulnerabilitiesReducer";
import VulnerabilityInfoReducer from "../reducers/vulnerabilities/vulnerabilityInfo/vulnerabilityInfoReducer";
import AddVulnerabilityScoreReducer from "../reducers/vulnerabilityScores/addVulnerabilityScore/addVulnerabilityScoreReducer";
import UpdateVulnerabilityScoreReducer from "../reducers/vulnerabilityScores/updateVulnerabilityScore/updateVulnerabilityScoreReducer";
import DomainsReducer from "../reducers/domains/domainsReducer";
import AddDomainReducer from "../reducers/domains/addDomain/addDomainReducer";
import UpdateDomainReducer from "../reducers/domains/updateDomain/updateDomainReducer";
import SuppliersReducer from "../reducers/supplier/supplierReducer";
import AddSuppliersReducer from "../reducers/supplier/addSupplier/addSupplierReducer";
import UpdateSupplierReducer from "../reducers/supplier/updateSupplier/updateSupplierReducer";
import BusinessUnitsReducer from "../reducers/businessUnits/businessUnitsReducer";
import AddBusinessUnitReducer from "../reducers/businessUnits/addBusinessUnit/addBusinessUnitReducer";
import UpdateBusinessUnitReducer from "../reducers/businessUnits/updateBusinessUnit/updateBusinessUnitReducer";
import MassiveUpdateCheckListReducer from "../reducers/checkList/massiveUpdate/massiveUpdateReducer";
import UserInfoReducer from "../reducers/account/userInfo/userInfoReducer";
import AvatarChangeReducer from "../reducers/account/userInfo/avatarChange/avatarChangeReducer";
import SystemUserListReducer from "../reducers/system/users/systemUserListReducer";
import SystemAddUserReducer from "../reducers/system/users/addUser/systemAddUserReducer";
import ActivityUserRolesReducer from "../reducers/activity/activityUserRoles/activityUserRolesReducer";
import changePasswordReducer from "../reducers/account/settings/changePassword/changePasswordReducer";
import serviceLogsReducer from "../reducers/services/serviceLogs/serviceLogsReducer";
import forgotPasswordReducer from "../reducers/auth/forgotPassword/forgotPasswordReducer";
import resetPasswordReducer from "../reducers/auth/resetPassword/resetPasswordReducer";
import selectLibrariesReducer from "../reducers/selfAssessment/selectLibraries/selectLibrariesReducer";
import selectSurveysReducer from "../reducers/selfAssessment/selectSurveys/selectSurveysReducer";
import UserOptionReducer from "../reducers/account/settings/userOption/userOptionReducer";
import TwoFactorSettingsReducer from "../reducers/account/settings/twoFactorSettings/twoFactorSettingsReducer";
import Setup2FAReducer from "../reducers/account/settings/twoFactorSettings/twoFactorSettingsReducer";
import Disable2FAReducer from "../reducers/account/settings/twoFactorSettings/twoFactorSettingsReducer";
import surveyListReducer from "../reducers/selfAssessment/surveyList/surveyListReducer"
import answerSurveyReducer from "../reducers/selfAssessment/answerSurvey/answerSurveyReducer";
import AUSelectLibrariesReducer from "../reducers/auditCampaign/selectLibraries/AUSelectLibrariesReducer";
import AUSelectSurveysReducer from "../reducers/auditCampaign/selectSurveys/AUSelectSurveysReducer";
import AUSelectAssetsReducer from "../reducers/auditCampaign/selectAssets/AUSelectAssetsReducer";
import AUUpdateAssetReducer from "../reducers/auditCampaign/updateAsset/AUUpdateAssetReducer";
import AUAssignSurveysReducer from "../reducers/auditCampaign/assignSurveys/AUAssignSurveysReducer";
import AUSendSurveysReducer from "../reducers/auditCampaign/sendSurveys/AUSendSurveysReducer";
import ExternalAuditDasboardReducer from "../reducers/externalAudit/dashboard/externalAuditDasboardReducer";
import AUReportReducer from "../reducers/auditCampaign/report/AUReportReducer";
import AssetVulnerabilityReportReducer from "../reducers/activity/assetVulnerabilityReport/assetVulnerabilityReportReducer";
import AuditLogsReducer from "../reducers/system/auditLogs/auditLogsReducer";
import LogsReducer from "../reducers/system/logs/logsReducer";
import SystemTenantListReducer from "../reducers/system/tenants/systemTenantListReducer";
import SystemAddTenantReducer from "../reducers/system/tenants/addTenant/systemAddTenantReducer";
import systemUpdateTenantReducer from "../reducers/system/tenants/updateTenant/systemUpdateTenantReducer";
import CurrentTenantReducer from "../reducers/currentTenant/currentTenantReducer";
import LogoChangeReducer from "../reducers/currentTenant/logoChange/logoChangeReducer";
import SurveyTestReducer from "../reducers/surveyTest/surveyTestReducer";
import TenantSubscriptionReducer from "../reducers/tenantSubscription/tenantSubscriptionReducer";
import ChatSurveyReducer from "../reducers/selfAssessment/chat/chatSurveyReducer";
import CarbonFootprintSurveyReducer from "../reducers/selfAssessment/carbonFootprintSurvey/carbonFootprintSurveyReducer";
import StakeholderInformationSurveyReducer from "../reducers/selfAssessment/stakeholderInformationSurvey/stakeholderInformationSurveyReducer";
import StakeholderFormReducer from "../reducers/selfAssessment/stakeholderForm/stakeholderFormReducer";
import LocationReducer from "../reducers/supplier/locations/locationReducer";
import AddLocationReducer from "../reducers/supplier/locations/addLocation/addLocationReducer";
import UpdateLocationReducer from "../reducers/supplier/locations/updateLocation/updateLocationReducer";
import ChatbotReducer from "../reducers/chatbot/chatbotReducer";
import ClassificationsReducer from "../reducers/classification/classificationsReducer";
import AddClassificationReducer from "../reducers/classification/addClassification/addClassificationReducer";
import UpdateClassificationReducer from "../reducers/classification/updateClassification/updateClassificationReducer";
import LibrariesReducer from "../reducers/libraries/librariesReducer";
import StakeholderChangeLogo from "../reducers/supplier/logoChange/logoChangeReducer";
import OrganizationReducer from "../reducers/organizations/organizationReducer";
import optionsReducer from "../reducers/options/optionsReducer";
import systemUpdateUserReducer from "../reducers/system/users/updateUser/systemUpdateUserReducer";
import homeDashboardReducer from "../reducers/homeDashboard/homeDashboardReducer";
import homeReducer from "../reducers/home/homeReducer";
import stakeholderAddUserReducer from "../reducers/supplier/stakeholderUsers/addUser/stakeholderAddUserReducer";
import stakeholderUpdateUserReducer from "../reducers/supplier/stakeholderUsers/updateUser/stakeholderUpdateUserReducer";
import stakeholderUserListReducer from "../reducers/supplier/stakeholderUsers/stakeholderUserListReducer";
import AUAssetLogoReducer from "../reducers/auditCampaign/assetLogo/AUAssetLogoReducer";
import sendTestEmailReducer from "../reducers/options/sendTestEmail/sendTestEmailReducer";
import newsMonitorReducer from "../reducers/newsMonitor/newsMonitorReducer";
import StakeholderLocationsSurveyReducer from "../reducers/selfAssessment/stakeholderLocationsSurvey/stakeholderLocationsSurveyReducer";
import StakeholderBusinessSectorsReducer from "../reducers/supplier/businessSectors/stakeholderBusinessSectorsReducer";
import StakeholderAddBusinessSectorReducer from "../reducers/supplier/businessSectors/addBusinessSector/stakeholderAddBusinessSectorReducer";
import StakeholderUpdateBusinessSectorReducer from "../reducers/supplier/businessSectors/updateBusinessSector/stakeholderUpdateBusinessSectorReducer";
import UpdateOrganizationReducer from "../reducers/organizations/updateOrganization/updateOrganizationReducer";
import StakeholderBusinessSectorsSurveyReducer from "../reducers/selfAssessment/stakeholderBusinessSectorsSurvey/stakeholderBusinessSectorsSurveyReducer";
import tenantSetupGeneralInformationReducer from "../reducers/tenantSetup/generalInformation/generalInformationReducer";
import tenantSetupAddBusinessUnitReducer from "../reducers/tenantSetup/businessUnits/addBusinessUnit/addBusinessUnitReducer";
import tenantSetupAddLocationReducer from "../reducers/tenantSetup/locations/addLocation/addLocationReducer";
import stakeholderActivitiesReducer from "../reducers/auditCampaign/stakeholderActivities/stakeholderActivitiesReducer";
import AUAssetReportsReducer from "../reducers/auditCampaign/report/assetReports/AUAssetReportsReducer";


const store = configureStore({
  reducer: {
    auth: AuthReducer,
    changeTheme: ChangeThemeReducer,
    surveys: SurveysReducer,
    cytoscape: CytoscapeReducer,
    assetType: AssetTypeReducer,
    assetTypeCreate: AssetTypeCreateReducer,
    assetTypeUpdate: AssetTypeUpdateReducer,
    assetInfo: AssetInfoReducer,
    advancedFilters: AdvancedFiltersReducer,
    fieldValues: FieldValuesReducer,
    assetView: AssetViewReducer,
    assetVulnerabilityView: AssetVulnerabilityViewReducer,
    checkListView: CheckListViewReducer,
    remediationView: RemediationViewReducer,
    addRemediation: AddRemediationReducer,
    activityCreate: ActivityCreateReducer,
    editStep: EditStepReducer,
    generalInfo: GeneralInfoReducer,
    menu: MenuReducer,
    listCreateActivity: ListCreateActivityReducer,
    listCurrentActivities: ListCurrentActivitiesReducer,
    assetVulnerabilityList: AssetVulnerabilityListReducer,
    assetVulnerabilityCheckList: AssetVulnerabilityCheckListReducer,
    assetVulnerabilityReport: AssetVulnerabilityReportReducer,
    editActivityInfo: EditActivityInfoReducer,
    services: ServicesReducer,
    addService: AddServiceReducer,
    updateService: UpdateServiceReducer,
    serviceConnectorsTable: ServiceConnectorsTableReducer,
    vulnerabilityStatuses: VulnerabilityStatusesReducer,
    addVulnerabilityStatus: AddVulnerabilityStatusReducer,
    updateVulnerabilityStatus: UpdateVulnerabilityStatusReducer,
    vulnerabilityScores: VulnerabilityScoresReducer,
    addVulnerabilityScore: AddVulnerabilityScoreReducer,
    updateVulnerabilityScore: UpdateVulnerabilityScoreReducer,
    domains: DomainsReducer,
    addDomain: AddDomainReducer,
    updateDomain: UpdateDomainReducer,
    vulnerabilities: VulnerabilitiesReducer,
    vulnerabilityInfo: VulnerabilityInfoReducer,
    suppliers: SuppliersReducer,
    addSupplier :AddSuppliersReducer,
    updateSupplier: UpdateSupplierReducer,
    businessUnits : BusinessUnitsReducer,
    addBusinessUnit: AddBusinessUnitReducer,
    updateBusinessUnit: UpdateBusinessUnitReducer,
    massiveUpdateCheckList: MassiveUpdateCheckListReducer,
    userInfo: UserInfoReducer,
    avatarChange: AvatarChangeReducer,
    systemUserList: SystemUserListReducer,
    systemAddUser: SystemAddUserReducer,
    activityUserRoles: ActivityUserRolesReducer,
    changePassword: changePasswordReducer,
    serviceLogs: serviceLogsReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    selectLibraries: selectLibrariesReducer,
    selectSurveys: selectSurveysReducer,
    userOption : UserOptionReducer,
    twoFactorSettings : TwoFactorSettingsReducer,
    setup2FA : Setup2FAReducer,
    disable2FA : Disable2FAReducer,
    surveyList: surveyListReducer,
    answerSurvey: answerSurveyReducer,
    auSelectLibraries: AUSelectLibrariesReducer,
    auSelectSurveys: AUSelectSurveysReducer,
    auSelectAssets: AUSelectAssetsReducer,
    auUpdateAsset: AUUpdateAssetReducer,
    auAssignSurveys: AUAssignSurveysReducer,
    auSendSurveys: AUSendSurveysReducer,
    externalAuditDasboard: ExternalAuditDasboardReducer,
    auReport: AUReportReducer,
    auAssetReports: AUAssetReportsReducer,
    auditLogs: AuditLogsReducer,
    logs: LogsReducer,
    systemTenantList: SystemTenantListReducer,
    systemAddTenant: SystemAddTenantReducer,
    systemUpdateTenant: systemUpdateTenantReducer,
    currentTenant: CurrentTenantReducer,
    logoChange: LogoChangeReducer,
    surveyTest: SurveyTestReducer,
    tenantSubscription: TenantSubscriptionReducer,
    chatSurvey: ChatSurveyReducer,
    carbonFootprintSurvey: CarbonFootprintSurveyReducer,
    stakeholderInformationSurvey: StakeholderInformationSurveyReducer,
    stakeholderLocationsSurvey: StakeholderLocationsSurveyReducer,
    stakeholderForm: StakeholderFormReducer,
    stakeholderBusinessSectors: StakeholderBusinessSectorsReducer,
    stakeholderAddBusinessSector: StakeholderAddBusinessSectorReducer,
    stakeholderUpdateBusinessSector: StakeholderUpdateBusinessSectorReducer,
    stakeholderBusinessSectorsSurvey: StakeholderBusinessSectorsSurveyReducer,
    locations: LocationReducer,
    addLocation: AddLocationReducer,
    updateLocation: UpdateLocationReducer,
    chatbot: ChatbotReducer,
    classifications: ClassificationsReducer,
    addClassification: AddClassificationReducer,
    updateClassification: UpdateClassificationReducer,
    libraries: LibrariesReducer,
    stakeholderChangeLogo: StakeholderChangeLogo,
    organizations: OrganizationReducer,
    updateOrganization: UpdateOrganizationReducer,
    options: optionsReducer,
    systemUpdateUser: systemUpdateUserReducer,
    homeDashboard: homeDashboardReducer,
    home: homeReducer,
    stakeholderAddUser: stakeholderAddUserReducer,
    stakeholderUpdateUser: stakeholderUpdateUserReducer,
    stakeholderUserList: stakeholderUserListReducer,
    auAssetLogo: AUAssetLogoReducer,
    sendTestEmail: sendTestEmailReducer,
    newsMonitor: newsMonitorReducer,
    tenantSetupGeneralInformation: tenantSetupGeneralInformationReducer,
    tenantSetupAddBusinessUnit: tenantSetupAddBusinessUnitReducer,
    tenantSetupAddLocation: tenantSetupAddLocationReducer,
    stakeholderActivities: stakeholderActivitiesReducer,
  },
});
export default store;
