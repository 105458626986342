import { Box } from "@mui/material";
import BotAvatar from "../../../shared/components/chatbot/BotAvatar";
import { useDispatch } from "react-redux";
import { toggleChatbot } from "@/reducers/chatbot/chatbotAction";

const ChatbotOpenButton = () => {

    const dispatch = useDispatch();
    
    const handleClickChatbot = () => {
        dispatch(toggleChatbot());
    }

    return (
        <Box 
            onClick={handleClickChatbot} 
            sx={{
                position: 'fixed',
                right: '30px',
                bottom: '30px',
                zIndex: 999,
                cursor: 'pointer'
            }}
        >
            <BotAvatar size="80px" />
        </Box>
    );
};

export default ChatbotOpenButton;