import React from "react";
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountFooter,
  AccountTitle,
  AccountWrapImage,
  Logo,
  FormWrapper,
  LogoCompany,
  FormButtonContainer,
  AccountHeader,
  AccountLogoFooter,
  TitleWrapper,
  SubtitleWrapper
} from "@/shared/components/account/AccountElements";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Backdrop, Button, Card, CardHeader, CircularProgress, InputAdornment, Link, Typography } from "@mui/material";
import { Link as ReactRouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { resetPassword, cleanup } from "@/reducers/auth/resetPassword/resetPasswordAction";
import { enqueueSnackbar } from "notistack";
import { FormPasswordField } from "@/shared/components/form/material-controls/PasswordField";
import { HasUpperCase, HasLowerCase, HasNonAlphanumeric, HasNumber } from "@/utils/formValidations";

const ResetPassword = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const userId = queryParams.get("userId");
    const code = queryParams.get("code");

    const navigate = useNavigate();

    const {
        isResettingPassword, resetPasswordError, resettedPassword
    } = useSelector(({ resetPassword }) => resetPassword);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(resetPasswordError){
            enqueueSnackbar(resetPasswordError, {variant: "error"});
        }
    }, [resetPasswordError]);

    const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();

    const onSubmit = (data) => {
        const finalData = {
            userId,
            code,
            password: data.password
        };
        console.log(finalData);
        dispatch(resetPassword(finalData));
    };

    useEffect(() => {
        if(resettedPassword){
            enqueueSnackbar("Your password has been reset successfully, you can login now.", {variant: "success"});
            reset();
            navigate("/Account/Login");
        }
    }, [resettedPassword]);

    return(
    <AccountWrapImage>
        <AccountContent>
            <AccountCard>
                <AccountHeader />

                <Row style={{marginBottom: 20}}>
                    <Col md={12}>
                        <TitleWrapper>
                            <Typography variant="h5">
                                Set your new password
                            </Typography>
                        </TitleWrapper>
                    </Col>
                </Row>

                <FormWrapper>
                    <Row>
                        <Col md={12}>
                            <Card variant="outlined">
                                <CardHeader
                                    subheader={
                                        <ul style={{fontSize: 14}}>
                                            <li>Password must be at least <strong>6 characters long</strong></li>
                                            <li>Password must have at least <strong>1 number</strong></li>
                                            <li>Password must have at least <strong>1 uppercase letter</strong></li>
                                            <li>Password must have at least <strong>1 lowercase letter</strong></li>
                                            <li>Password must have at least <strong>1 symbol</strong></li>
                                        </ul>
                                    }
                                />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormPasswordField
                                name="password"
                                label="New Password"
                                control={control}
                                size=''
                                rules={{ 
                                    required: 'This field is required',
                                    minLength: { 
                                        value: 6,
                                        message: "Password must be at least 6 characters long"
                                    },
                                    validate: (val) => {
                                        if (watch('oldPassword') === val) {
                                            return "Your new password can't be equal to your current password";
                                        }
                                        if(!HasNumber(val)){
                                            return "Password must have at least 1 number";
                                        }
                                        if(!HasUpperCase(val)){
                                            return "Password must have at least 1 uppercase letter";
                                        }
                                        if(!HasLowerCase(val)){
                                            return "Password must have at least 1 lowercase letter";
                                        }
                                        if(!HasNonAlphanumeric(val)){
                                            return "Password must have at least 1 symbol";
                                        }
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormPasswordField
                                name="confirmPassword"
                                label="Confirm Password"
                                control={control}
                                size=''
                                rules={{ 
                                    required: 'This field is required',
                                    validate: (val) => {
                                        if (watch('password') !== val) {
                                            return "Passwords don't match";
                                        }
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormButtonContainer>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                    size="large"
                                >
                                    Continue
                                </Button>
                            </FormButtonContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <ForgotPasswordWrapper>
                                <Link component={ReactRouterLink} to="/Account/Login" underline="hover">
                                    Back to login
                                </Link>
                            </ForgotPasswordWrapper>
                        </Col>
                    </Row>
                </FormWrapper>

                <AccountLogoFooter />
            </AccountCard>
        </AccountContent>

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isResettingPassword}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </AccountWrapImage>
    );
};

const ForgotPasswordWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 5px;
`;

export default ResetPassword;
