import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import { colorBackgroundBody } from "@/utils/palette";
import { paddingLeft } from "@/utils/directions";
import Layout from "../Layout";
import NotFound from "@/containers/404";

import Home from "@/containers/Home";

import ListCreateActivity from "@/containers/Activities/ListCreate";
import CreateActivity from "@/containers/Activities/create";
import ActivityGeneralInfo from "@/containers/Activities/create/components/generalInfo";

import EditStep from "@/containers/Activities/EditStep";
import AssetVulnerabilityList from "@/containers/Activities/EditStep/VMSteps/assetVulnerabilityList";
import AssetVulnerabilityCheckList from "@/containers/Activities/EditStep/VMSteps/assetVulnerabilityCheckList";

import EnterpriseArchitecture from "@/containers/AssetManagement/EnterpriseArchitecture";
import AssetInventory from "@/containers/AssetManagement/AssetInventory";
import AssetTypes from "@/containers/AssetManagement/AssetTypes";

import Vulnerabilities from "@/containers/VulnerabilityManagement/Vulnerabilities";
import VulnerabilityStatuses from "@/containers/VulnerabilityManagement/VulnerabilityStatuses";
import VulnerabilityScoring from "@/containers/VulnerabilityManagement/VulnerabilityScoring";
import Domains from "@/containers/VulnerabilityManagement/Domains";

import RemediationPlans from "@/containers/RemediationPlans";
import CheckList from "@/containers/RemediationPlans/CheckList";
import Services from "@/containers/Services";
import Suppliers from "../containers/Registry/Supplier";
import Customers from "../containers/Registry/Customers";
import BusinessUnits from "../containers/Registry/BusinessUnits";

import AccountProfile from "../containers/Account/Profile";
import AccountLockScreen from "../containers/Account/LockScreen";
import VulnerabilityDashboard from "@/containers/VulnerabilityManagement/Dashboard";
import AssetDashboard from "@/containers/AssetManagement/Dashboard";
import AssetVulnerabilityView from "@/containers/Activities/create/components/assetVulnerabilityView";
import SystemUserList from "../containers/SystemSettings/Users";
import SystemAddUser from "../containers/SystemSettings/Users/AddNew";
import AccountSettings from "../containers/Account/Settings";
import SelfAssessmentSelectLibraries from "../containers/Activities/EditStep/SASteps/selfAssessmentSelectLibraries";
import SelfAssessmentSelectSurvey from "../containers/Activities/EditStep/SASteps/selfAssessmentSelectSurvey";
import SelfAssessmentSurvey from "../containers/Activities/EditStep/SASteps/selfAssessmentSurvey";
import SelfAssessmentReport from "../containers/Activities/EditStep/SASteps/selfAssessmentReport";
import AssetVulnerabilityReport from "../containers/Activities/EditStep/VMSteps/assetVulnerabilityReport";
import AUCampaignSelectLibraries from "../containers/Activities/EditStep/AUSteps/AUCampaignSelectLibraries";
import AUCampaignSelectSurvey from "../containers/Activities/EditStep/AUSteps/AUCampaignSelectSurvey";
import AUCampaignSelectAsset from "../containers/Activities/EditStep/AUSteps/AUCampaignSelectAsset";
import AUCampaignReport from "../containers/Activities/EditStep/AUSteps/AUCampaignReport";
import AUCampaignAssignSurvey from "../containers/Activities/EditStep/AUSteps/AUCampaignAssignSurvey";
import AUCampaignAssignSurveyV2 from "../containers/Activities/EditStep/AU2Steps/AUCampaignAssignSurveyV2";
import ExternalAuditDashboard from "../containers/ExternalAudit/Dashboard";
import SelfAssessmentDashboard from "../containers/SelfAssessment/Dashboard";
import AuditLogs from "../containers/SystemSettings/AuditLogs";
import Logs from "../containers/SystemSettings/Logs";
import SystemTenantList from "../containers/SystemSettings/Tenants";
import SystemAddTenant from "../containers/SystemSettings/Tenants/Add";
import SystemUpdateTenant from "../containers/SystemSettings/Tenants/Update";
import TenantSettings from "../containers/Settings/Tenant";
import SurveyTest from "../containers/SurveyTest";
import Subscription from "../containers/SystemSettings/Tenants/Subscription";
import CreateSupplier from "../containers/Registry/Supplier/components/createSupplier";
import UpdateSupplier from "../containers/Registry/Supplier/components/updateSupplier";
import Classifications from "../containers/AssetManagement/Classifications";
import Libraries from "../containers/Settings/Libraries";
import AllActivities from "../containers/Activities";
import AuditCampaign from "../containers/ExternalAudit/AuditCampaign";
import AuditSurvey from "../containers/ExternalAudit/AuditSurvey";
import TenantUsers from "../containers/Settings/Users";
import TenantAddUser from "../containers/Settings/Users/AddNew";
import Organizations from "../containers/Registry/Organizations";
import SelfAssessments from "../containers/SelfAssessment/Surveys";
import Options from "../containers/Settings/Options";
import AUAssessmentSurvey from "../containers/Activities/EditStep/AUSteps/AUAssessmentSurvey";
import TenanUpdateUser from "../containers/Settings/Users/Update";
import SystemUpdateUser from "../containers/SystemSettings/Users/Update";
import StakeholderAddUser from "../containers/Registry/Supplier/Users/AddNew";
import StakeholderUpdateUser from "../containers/Registry/Supplier/Users/Update";
import SurveyTestFlat from "../containers/SurveyTest/surveyTestFlat";
import GeneralInfoVulnerability from "../containers/Activities/create/components/generalInfoVulnerability";
import NewsMonitor from "../containers/ExternalAudit/NewsMonitor";
import AddBusinessUnit from "../containers/Registry/BusinessUnits/AddNew";
import UpdateBusinessUnit from "../containers/Registry/BusinessUnits/Update";
import UpdateOrganization from "../containers/Registry/Organizations/Update";
import TenantGeneralInformation from "../containers/Activities/EditStep/TenantSetupSteps/tenantGeneralInformation";
import TenantBusinessSectors from "../containers/Activities/EditStep/TenantSetupSteps/tenantBusinessSectors";
import TenantLocations from "../containers/Activities/EditStep/TenantSetupSteps/tenantLocations";
import TenantBusinessUnits from "../containers/Activities/EditStep/TenantSetupSteps/tenantBusinessUnits";
import TenantAssetTypesSMTP from "../containers/Activities/EditStep/TenantSetupSteps/tenantAssetTypesSMTP";
import AUCampaignSendSurvey from "../containers/Activities/EditStep/AU2Steps/AUCampaignSendSurvey";
import AUCampaignListActivities from "../containers/Activities/EditStep/AU2Steps/AUCampaignListActivities";

export const WrapContainer = () => {
  const location = useLocation();
  return (
    <div>
      {location.pathname !== "/404" ? (
        <React.Fragment>

          <Layout />

          <ContainerWrap>
            <Routes>
              <Route path='/Account/Login' element={<Navigate to='/' />} />
              <Route path='/Account/LoginWith2fa*' element={<Navigate to='/' />} />
              <Route path='*' element={<Navigate to='/404' />} />

              <Route path='/' element={<Home />} />
              
              <Route path='/Activities' element={<AllActivities />} />
              <Route path='/Activities/ListCreate' element={<ListCreateActivity/>} />
              <Route path='/Activities/Create/:id' element={<CreateActivity/>} >
                <Route path='GeneralInfo' element={<ActivityGeneralInfo/>} />
                <Route path='AssetVulnerabilityView' element={<AssetVulnerabilityView/>} />
                <Route path='GeneralInfoVulnerability' element={<GeneralInfoVulnerability />} />
              </Route>
              
              <Route path='/Activities/EditStep/:id' element={<EditStep/>} >
                <Route path='TenantGeneralInformation' element={<TenantGeneralInformation />} />
                <Route path='TenantBusinessSectors' element={<TenantBusinessSectors />} />
                <Route path='TenantBusinessUnits' element={<TenantBusinessUnits />} />
                <Route path='TenantLocations' element={<TenantLocations />} />
                <Route path='TenantAssetTypesSMTP' element={<TenantAssetTypesSMTP />} />

                <Route path='AssetVulnerabilityList' element={<AssetVulnerabilityList/>} />
                <Route path='AssetVulnerabilityCheckList' element={<AssetVulnerabilityCheckList/>} />
                <Route path='AssetVulnerabilityReport' element={<AssetVulnerabilityReport/>} />

                <Route path='SelfAssessmentSelectLibraries' element={<SelfAssessmentSelectLibraries/>} />
                <Route path='SelfAssessmentSelectSurvey' element={<SelfAssessmentSelectSurvey/>} />
                <Route path='SelfAssessmentSurvey' element={<SelfAssessmentSurvey/>} />
                <Route path='SelfAssessmentReport' element={<SelfAssessmentReport/>} />
              
                <Route path='AUCampaignSelectLibraries' element={<AUCampaignSelectLibraries/>} />
                <Route path='AUCampaignSelectSurvey' element={<AUCampaignSelectSurvey/>} />
                <Route path='AUCampaignSelectAsset' element={<AUCampaignSelectAsset/>} />
                <Route path='AUCampaignAssignSurvey' element={<AUCampaignAssignSurvey/>} />
                <Route path='AUCampaignReport' element={<AUCampaignReport/>} />
                <Route path='AUAssessmentSurvey' element={<AUAssessmentSurvey/>} />
              
                <Route path='AUCampaignAssignSurveyV2' element={<AUCampaignAssignSurveyV2/>} />
                <Route path='AUCampaignSendSurvey' element={<AUCampaignSendSurvey/>} />
                <Route path='AUCampaignListActivities' element={<AUCampaignListActivities/>} />
              </Route>

              <Route path='/AssetManagement/EnterpriseArchitecture' element={<EnterpriseArchitecture />} />
              <Route path='/AssetManagement/AssetInventory' element={<AssetInventory />} />
              <Route path='/AssetManagement/AssetTypes' element={<AssetTypes />} />
              <Route path='/AssetManagement/AssetDashboard' element={<AssetDashboard />} />
              <Route path='/AssetManagement/Classifications' element={<Classifications />} />

              <Route path='/VulnerabilityManagement/Vulnerabilities' element={<Vulnerabilities />} />
              <Route path='/VulnerabilityManagement/RemediationPlans' element={<RemediationPlans />} />
              <Route path='/VulnerabilityManagement/RemediationPlans/CheckList/:id' element={<CheckList/>} />
              <Route path='/VulnerabilityManagement/VulnerabilityStatuses' element={<VulnerabilityStatuses />} />
              <Route path='/VulnerabilityManagement/VulnerabilitiesScoring' element={<VulnerabilityScoring />} />
              <Route path='/VulnerabilityManagement/Domains' element={<Domains />} />
              <Route path='/VulnerabilityManagement/VulnerabilityDashboard' element={<VulnerabilityDashboard />} />

              <Route path='/Registry/Stackholders' element={<Suppliers/>} />
              <Route path='/Registry/Stackholders/Create' element={<CreateSupplier/>} />
              <Route path='/Registry/Stackholders/Update/:id' element={<UpdateSupplier/>} />
              <Route path='/Registry/Stackholders/Update/:id/AddUser' element={<StakeholderAddUser />} />
              <Route path='/Registry/Stackholders/Update/:id/UpdateUser/:userId' element={<StakeholderUpdateUser />} />              
              <Route path='/Registry/Customers' element={<Customers/>} />
              <Route path='/Registry/BusinessUnits' element={<BusinessUnits/>} />
              <Route path='/Registry/BusinessUnits/Create' element={<AddBusinessUnit/>} />
              <Route path='/Registry/BusinessUnits/Update/:id' element={<UpdateBusinessUnit/>} />
              <Route path='/Registry/Organization' element={<Organizations/>} />
              <Route path='/Registry/Organization/Update' element={<UpdateOrganization/>} />

              <Route path='/Settings/Services' element={<Services/>} />
              <Route path='/Settings/TenantProfile' element={<TenantSettings/>} />
              <Route path='/Settings/Libraries' element={<Libraries/>} />
              <Route path='/Settings/TenantUsers' element={<TenantUsers/>} />
              <Route path='/Settings/TenantUsers/Add' element={<TenantAddUser/>} />
              <Route path='/Settings/TenantUsers/Update/:id' element={<TenanUpdateUser/>} />
              <Route path='/Settings/Options' element={<Options/>} />

              <Route path='/SystemSettings/Users' element={<SystemUserList/>} />
              <Route path='/SystemSettings/Users/Add' element={<SystemAddUser/>} />
              <Route path='/SystemSettings/Users/Update/:id' element={<SystemUpdateUser/>} />
              <Route path='/SystemSettings/AuditLogs' element={<AuditLogs/>} />
              <Route path='/SystemSettings/Logs' element={<Logs/>} />
              <Route path='/SystemSettings/Tenants' element={<SystemTenantList/>} />
              <Route path='/SystemSettings/Tenants/Add' element={<SystemAddTenant/>} />
              <Route path='/SystemSettings/Tenants/Update/:id' element={<SystemUpdateTenant/>} />
              <Route path='/SystemSettings/Tenants/Subscription/:id' element={<Subscription/>} />

              <Route path='/Account/Profile' element={<AccountProfile />} />
              <Route path='/Account/LockScreen' element={<AccountLockScreen/>} />
              <Route path='/Account/Settings' element={<AccountSettings/>} />
            
              <Route path='/ExternalAuditManagement/xAUDashboard' element={<ExternalAuditDashboard/>} />
              <Route path='/ExternalAuditManagement/AuditCampaign' element={<AuditCampaign/>} />
              <Route path='/ExternalAuditManagement/AuditSurvey' element={<AuditSurvey/>} />
              <Route path='/ExternalAuditManagement/xAUNewsMonitor' element={<NewsMonitor/>} />
              <Route path='/SelfAssessment/SelfAssessmentDashboard' element={<SelfAssessmentDashboard/>} />
              <Route path='/SelfAssessment/SelfAssessmentSurveys' element={<SelfAssessments/>} />

              <Route path='/SurveyTest/:id' element={<SurveyTest/>} />
              <Route path='/SurveyTestFlat' element={<SurveyTestFlat/>} />

            </Routes>
          </ContainerWrap>
        </React.Fragment>
      ) : (
        <Routes>
          <Route path='/404' element={<NotFound />} />
        </Routes>
      )}
    </div>
  );
};

// region STYLES

const ContainerWrap = styled.div`
  padding-top: 80px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    ${paddingLeft}: 250px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 150px;
  }
`;

// endregion
