import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useState } from "react";

const ShowOnStartupCheckbox = () => {

    const [value, setValue] = useState(localStorage.getItem("showChatbotOnStartup") === "true");
    
    const onChange = (e, data) => {
        setValue(data);
        localStorage.setItem("showChatbotOnStartup", data);
    };

    return(
        <Box sx={{
            paddingLeft: 2
        }}>
            <FormControlLabel
                control={<Checkbox sx={{padding: 0, paddingRight: 1}} onChange={onChange} checked={value} />}
                label={<Typography color='text.primary'>Show on startup</Typography>}
            />
        </Box>
    );
};

export default ShowOnStartupCheckbox;