import styled from "@emotion/styled";
import { Box, Step, StepButton, StepLabel, Stepper, Typography } from "@mui/material";
import CircularProgressWithLabel from '@/shared/components/CircularProgressWithLabel';

const ActivityStepper = ({
    steps,
    disabledSteps,
    stepProgresses,
    currentStep,
    handleClickStep
}) => {

    const activeIconStyle = {
        "& > span > svg": {
            width: '1.3em',
            height: '1.3em'
        },
        "& > span > svg > text": {
            fontSize: 9
        }
    }

    return(
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{width: '80%'}}>
                <Stepper 
                    nonLinear 
                    activeStep={currentStep} 
                    style={{
                        marginBottom: 30, 
                        justifyContent: (steps.length === 1 ? 'center' : 'flex-start') 
                    }}>
                    {steps.map((step, index) => (
                        <Step 
                            key={index} 
                            completed={step.workflowActivityStep.comlpeted}
                            disabled={disabledSteps[index]}
                        >
                            <StepButton 
                                color="inherit" 
                                onClick={() => handleClickStep(index)}>
                                <StepLabel
                                    sx={index === currentStep ? activeIconStyle : {}}
                                >
                                            { step.isRequired ? 
                                                <StepTitleContent>
                                                        <span>{step.workflowActivityStep.label}</span>
                                                            <CircularProgressWithLabel 
                                                                value={stepProgresses.find(p => p.workflowActivityStepCompletedId === step.workflowActivityStepCompletedId).percentageCompleted * 100} />
                                                </StepTitleContent>
                                            :
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    textAlign: 'start'
                                                }}>
                                                    <span>{step.workflowActivityStep.label}</span>
                                                    { !step.isRequired && !step.isOnlyView &&
                                                        <Typography variant="caption">Optional</Typography>
                                                    }
                                                </Box>
                                            }
                                </StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </div>
    );
}

const StepTitleContent = styled('div')`
display: flex;
align-items: center;

& > span {
    margin-right: 8px;
}
`;

  export default ActivityStepper;