import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getRoles, addUser } from "@/reducers/supplier/stakeholderUsers/addUser/stakeholderAddUserAction";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { FormDateField } from "@/shared/components/form/material-controls/DateField";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import EmailAndUserName from "@/shared/components/EmailAndUserName";
import AddStakeholderUserForm from "./addStakeholderUserForm";

const StakeholderAddUser = () => {
    const { id } = useParams();
    const {t} = useTranslation("common");

    const dispatch = useDispatch();
    const { 
        isAddingUser, addedUser, addUserError
    } = useSelector(({ stakeholderAddUser }) => stakeholderAddUser);

    const navigate = useNavigate();
    const { handleSubmit, watch, getValues, reset, control, setValue } = useForm();

    useEffect(() => {
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(addUserError){
            enqueueSnackbar(addUserError, {variant: "error"});
        }
    }, [addUserError]);

    useEffect(() => {
        if(addedUser){
          enqueueSnackbar(`User created successfuly.`, {variant: "success"});
          navigate(-1);
        }
    }, [addedUser]);

    const onSubmit = (data) => {
        data.stakeholderId = id;
        dispatch(addUser(data));
    }

    const onClickCancel = () => {
        navigate(-1);
    }

    return(
        <Container>
            <CardPage>
                <CardPageBody>         
                    <CardPageTitleWrap>
                        <CardPageTitle>Add User</CardPageTitle>
                    </CardPageTitleWrap>

                    <AddStakeholderUserForm 
                        watch={watch}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                    />

                </CardPageBody>
            </CardPage>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isAddingUser}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default StakeholderAddUser;