import { Col, Container, Row } from "react-bootstrap";
import { CardPage, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageGridWrap} from '@/shared/components/MaterialCardPage';
import TabPanel, { tabIndexProps } from "@/shared/components/MaterialTabPanel";
import { Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ESGBanksTab from "./components/ESGBanksTab";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ESGBigEnergyTab from "./components/ESGBigEnergyTab";
import { getESGBanksStories, getCloudServiceSentiment, getESGBigEnergySentiment, getESGBanksTrends, getCloudServiceStories, getCloudServiceTrends, getESGBanksSentiment, getESGBigEnergyStories, getESGBigEnergyTrends, cleanup } from "@/reducers/newsMonitor/newsMonitorAction";
import { enqueueSnackbar } from "notistack";
import CloudServiceTab from "./components/CloudService";
import CloudIcon from '@mui/icons-material/Cloud';

const NewsMonitor = () => {

    const dispatch = useDispatch();
  
    const { 
        esgBanksStories, isLoadingESGBanksStories, getESGBanksStoriesError,
        esgBanksTrends, isLoadingESGBanksTrends, getESGBanksTrendsError,
        esgBanksSentiment, isLoadingESGBanksSentiment, getESGBanksSentimentError,
        esgBigEnergyStories, isLoadingESGBigEnergyStories, getESGBigEnergyStoriesError,
        esgBigEnergyTrends, isLoadingESGBigEnergyTrends, getESGBigEnergyTrendsError,
        esgBigEnergySentiment, isLoadingESGBigEnergySentiment, getESGBigEnergySentimentError,
        cloudServiceStories, isLoadingCloudServiceStories, getCloudServiceStoriesError,
        cloudServiceTrends, isLoadingCloudServiceTrends, getCloudServiceTrendsError,
        cloudServiceSentiment, isLoadingCloudServiceSentiment, getCloudServiceSentimentError,
    } = useSelector(({ newsMonitor }) => newsMonitor);

    const [tab, setTab] = useState(0);
    const [esgBanksSelectedEntity, setEsgBanksSelectedEntity] = useState("");
    const [esgBigEnergySelectedEntity, setEsgBigEnergySelectedEntity] = useState("");
    const [cloudServiceSelectedEntity, setCloudServiceSelectedEntity] = useState("");

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);

    useEffect(() => {
        if(getESGBanksTrendsError){
          enqueueSnackbar(getESGBanksTrendsError, {variant: "error"});
        }
    }, [getESGBanksTrendsError]);

    useEffect(() => {
      if(getESGBanksStoriesError){
        enqueueSnackbar(getESGBanksStoriesError, {variant: "error"});
      }
    }, [getESGBanksStoriesError]);

      useEffect(() => {
        if(getESGBanksSentimentError){
          enqueueSnackbar(getESGBanksSentimentError, {variant: "error"});
        }
    }, [getESGBanksSentimentError]);

      useEffect(() => {
          if(getESGBigEnergyStoriesError){
            enqueueSnackbar(getESGBigEnergyStoriesError, {variant: "error"});
          }
      }, [getESGBigEnergyStoriesError]);

      useEffect(() => {
        if(getESGBigEnergyTrendsError){
          enqueueSnackbar(getESGBigEnergyTrendsError, {variant: "error"});
        }
    }, [getESGBigEnergyTrendsError]);

    useEffect(() => {
      if(getESGBigEnergySentimentError){
        enqueueSnackbar(getESGBigEnergySentimentError, {variant: "error"});
      }
    }, [getESGBigEnergySentimentError]);

    useEffect(() => {
      if(getCloudServiceStoriesError){
        enqueueSnackbar(getCloudServiceStoriesError, {variant: "error"});
      }
    }, [getCloudServiceStoriesError]);

    useEffect(() => {
      if(getCloudServiceTrendsError){
        enqueueSnackbar(getCloudServiceTrendsError, {variant: "error"});
      }
    }, [getCloudServiceTrendsError]);

    useEffect(() => {
      if(getCloudServiceSentimentError){
        enqueueSnackbar(getCloudServiceSentimentError, {variant: "error"});
      }
    }, [getCloudServiceSentimentError]);

    useEffect(() => {
        if(tab === 0 && !esgBanksTrends){
          dispatch(getESGBanksTrends());
          dispatch(getESGBanksStories());
        }
        else if(tab === 1 && !esgBigEnergyTrends){
          dispatch(getESGBigEnergyTrends());
          dispatch(getESGBigEnergyStories());
        }
        else if(tab === 2 && !cloudServiceTrends){
          dispatch(getCloudServiceTrends());
          dispatch(getCloudServiceStories());
        }
    }, [tab]);
    
    useEffect(() => {
      if(esgBanksTrends){
        const entity = esgBanksTrends.trends[0].value;
        setEsgBanksSelectedEntity(entity);
      }
    }, [esgBanksTrends]);

    useEffect(() => {
      if(esgBanksSelectedEntity){
        dispatch(getESGBanksSentiment(esgBanksSelectedEntity));
      }
    }, [esgBanksSelectedEntity]);


    useEffect(() => {
      if(esgBigEnergyTrends){
        const entity = esgBigEnergyTrends.trends[0].value;
        setEsgBigEnergySelectedEntity(entity);
      }
    }, [esgBigEnergyTrends]);

    useEffect(() => {
      if(esgBigEnergySelectedEntity){
        dispatch(getESGBigEnergySentiment(esgBigEnergySelectedEntity));
      }
    }, [esgBigEnergySelectedEntity]);


    useEffect(() => {
      if(cloudServiceTrends){
        const entity = cloudServiceTrends.trends[0].value;
        setCloudServiceSelectedEntity(entity);
      }
    }, [cloudServiceTrends]);

    useEffect(() => {
      if(cloudServiceSelectedEntity){
        dispatch(getCloudServiceSentiment(cloudServiceSelectedEntity));
      }
    }, [cloudServiceSelectedEntity]);

    return (
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageTitleWrap>
                        <CardPageTitle>News Monitor</CardPageTitle>
                    </CardPageTitleWrap>

                    <Tabs
                        value={tab}
                        variant="fullWidth"
                        onChange={handleTabChange}
                        aria-label="icon position tabs example"
                    >
                        <Tab 
                            icon={<AccountBalanceIcon />} 
                            iconPosition="start" 
                            label="ESG - Banks"
                            {...tabIndexProps(0)}
                        />
                        <Tab 
                            icon={<ElectricBoltIcon />} 
                            iconPosition="start" 
                            label="ESG - Big Energy"
                            {...tabIndexProps(1)}
                        />
                        <Tab 
                            icon={<CloudIcon />} 
                            iconPosition="start" 
                            label="Cloud Service"
                            {...tabIndexProps(2)}
                        />
                    </Tabs>
                    
                    <TabPanel value={tab} index={0}>
                        <ESGBanksTab 
                          isLoadingStories={isLoadingESGBanksStories}
                          stories={esgBanksStories}
                          isLoadingTrends={isLoadingESGBanksTrends}
                          trendsData={esgBanksTrends}
                          isLoadingSentiment={isLoadingESGBanksSentiment}
                          sentimentData={esgBanksSentiment}
                          sentimentEntity={esgBanksSelectedEntity}
                          onSelectEntity={setEsgBanksSelectedEntity}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <ESGBigEnergyTab 
                          isLoadingStories={isLoadingESGBigEnergyStories}
                          stories={esgBigEnergyStories}
                          isLoadingTrends={isLoadingESGBigEnergyTrends}
                          trendsData={esgBigEnergyTrends}
                          isLoadingSentiment={isLoadingESGBigEnergySentiment}
                          sentimentData={esgBigEnergySentiment}
                          sentimentEntity={esgBigEnergySelectedEntity}
                          onSelectEntity={setEsgBigEnergySelectedEntity}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <CloudServiceTab 
                          isLoadingStories={isLoadingCloudServiceStories}
                          stories={cloudServiceStories}
                          isLoadingTrends={isLoadingCloudServiceTrends}
                          trendsData={cloudServiceTrends}
                          isLoadingSentiment={isLoadingCloudServiceSentiment}
                          sentimentData={cloudServiceSentiment}
                          sentimentEntity={cloudServiceSelectedEntity}
                          onSelectEntity={setCloudServiceSelectedEntity}
                        />
                    </TabPanel>
                </CardPageBody>
            </CardPage>
        </Container>
    );
};

export default NewsMonitor;