import { Backdrop, Box, Button, CircularProgress, Paper, Tab, Tabs, Typography} from "@mui/material"; 
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { enqueueSnackbar } from "notistack";
import { cleanup, updateSupplier, getLogo, deleteLogo, getSupplier, getAsset, getStakeholderTypes } from "@/reducers/supplier/updateSupplier/updateSupplierAction";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import TabPanel, { tabIndexProps } from "@/shared/components/MaterialTabPanel";
import InfoIcon from '@mui/icons-material/Info';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import SupplierGeneralInfo from "./supplierGeneralInfo";
import LocationsList from "../../../Activities/EditStep/SASteps/components/customSurveyComponents/supplierLocations/LocationsList";
import LogoChangeModal from "./logoChangeModal";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import UsersTab from "../Users/usersTab";
import BusinessSectorsList from "./businessSectorsList";

const UpdateSupplier = () => {

    const { id } = useParams();
    const dispatch = useDispatch();

    const { 
        supplier, isLoadingSupplier, getSupplierError,
        asset, isLoadingAsset, getAssetError,
        isUpdatingSupplier, updatedSupplier, updateSupplierError,
        // stakeholderTypes, isLoadingStakeholderTypes, getStakeholderTypesError
        logo, isLoadingLogo, getLogoError,
        isDeletingLogo, deletedLogo, deleteLogoError
    } = useSelector(({ updateSupplier }) => updateSupplier);
    
    const [tab, setTab] = useState(3);
    const [showLogoModal, setShowLogoModal] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    useEffect(() => {
        dispatch(getSupplier(id));
        dispatch(getLogo(id));
        // dispatch(getStakeholderTypes());

        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(supplier){
            dispatch(getAsset(supplier.assetId));
        }
    }, [supplier]);

    useEffect(() => {
        if(getSupplierError){
            enqueueSnackbar(getSupplierError, {variant: "error"});
        }
    }, [getSupplierError]);

    useEffect(() => {
        if(deletedLogo){
        enqueueSnackbar("Logo deleted successfully", {variant: "success"});
        }
    }, [deletedLogo]);

    useEffect(() => {
        if(deleteLogoError){
            enqueueSnackbar(deleteLogoError, {variant: "error"});
        }
    }, [deleteLogoError]);

    useEffect(() => {
        if(getAssetError){
            enqueueSnackbar(getAssetError, {variant: "error"});
        }
    }, [getAssetError]);

    useEffect(() => {
        if(updateSupplierError){
            enqueueSnackbar(updateSupplierError, {variant: "error"});
        }
    }, [updateSupplierError]);
    
    const onUpdateSupplier = (data) => { 
        data.stakeholderId = id;
        dispatch(updateSupplier(data));
    }

    useEffect(() => {
        if(updatedSupplier){
            enqueueSnackbar("Stakeholder updated successfully", {variant: "success"});
        }
    }, [updatedSupplier]);

    const onUpdateLogo = () => {
        dispatch(getLogo(id));
    };
    
    const onClickDeleteLogo = () => {
        setOpenConfirmDelete(true);
    };

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);
        dispatch(deleteLogo(id));
    }

    const handleCloseDelete = () => {
        setOpenConfirmDelete(false);
    }

    const onHideLogoModal = () => {
        setShowLogoModal(false);
    };

    const onClickEditLogo = () => {
        setShowLogoModal(true);
    };
    
    const onChangedBusinessSectors = () => {
        dispatch(getAsset(supplier.assetId));
    };

    return(
      <Container>
        <CardPage>
          <CardPageBody>
                <CardPageHeader>
                    <CardPageTitleWrap>
                        <CardPageTitle>Update Stakeholder</CardPageTitle>
                    </CardPageTitleWrap>
                </CardPageHeader>

                <Tabs 
                    variant="fullWidth"
                    value={tab} 
                    onChange={handleTabChange}
                >
                    <Tab 
                        label="General Information"
                        icon={<InfoIcon />} 
                        iconPosition="start" 
                        {...tabIndexProps(0)}
                    />
                    <Tab 
                        label="Business Sectors"
                        icon={<ViewModuleIcon />} 
                        iconPosition="start" 
                        {...tabIndexProps(1)}
                    />
                    <Tab 
                        label="Locations"
                        icon={<BusinessIcon />} 
                        iconPosition="start" 
                        {...tabIndexProps(2)}
                    />
                    <Tab 
                        label="Users"
                        icon={<GroupIcon />} 
                        iconPosition="start" 
                        {...tabIndexProps(3)}
                    />
                </Tabs>
                { isLoadingAsset || isLoadingSupplier ? <CircularProgressWrapper /> :
                    <>
                        <TabPanel value={tab} index={0} sx={{py: 2}}>
                            <SupplierGeneralInfo 
                                onUpdateSupplier={onUpdateSupplier}
                                supplier={supplier}
                                asset={asset}
                                logo={logo}
                                isLoadingLogo={isLoadingLogo}
                                onClickEditLogo={onClickEditLogo}
                                onClickDeleteLogo={onClickDeleteLogo}
                            />
                        </TabPanel>
                        <TabPanel value={tab} index={1} sx={{py: 2}}>
                            <BusinessSectorsList
                                asset={asset}
                                onChangedBusinessSectors={onChangedBusinessSectors}
                            />
                        </TabPanel>
                        <TabPanel value={tab} index={2} sx={{py: 2}}>
                            <LocationsList
                                assetId={supplier.assetId}
                            />
                        </TabPanel>
                        <TabPanel value={tab} index={3} sx={{py: 2}}>
                            <UsersTab stakeholderId={id} />
                        </TabPanel>
                    </>
                }
          </CardPageBody>
        </CardPage>

        { showLogoModal &&
            <LogoChangeModal
            onHideModal={onHideLogoModal}
            onUpdateLogo={onUpdateLogo} 
            open={showLogoModal}
            logo={logo}
            stakeholderId={id}
            />
        }
            
        { openConfirmDelete && 
            <ConfirmationDialog 
                open={openConfirmDelete}
                onConfirm={handleConfirmDelete}
                onClose={handleCloseDelete}
                title="Delete Logo"
                body={`Are you sure you want to delete your logo image?`}
                color='error'
                confirmButtonText='Delete'
            />
        }

        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} 
            open={isUpdatingSupplier || isDeletingLogo}>
            <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    )
}

export default UpdateSupplier;