import { Box, Divider, Icon, Tab, Tabs, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import RiskRadar from "./riskRadar";
import ReportCalculationHistory from "./reportCalculationHistory";
import TabPanel, { tabIndexProps } from "@/shared/components/MaterialTabPanel";
import { useState } from "react";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RestoreIcon from '@mui/icons-material/Restore';
import RiskCalculationScore from "./riskCalculationScore";

const ReportAndKPI = ({
    asset
}) => {

    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    const lastCalculationSurveys = [];
    const groupByCodeArray = [];

    if(asset.metadata?.surveyCalculations?.length)
    {
        const groupByCode = Object.groupBy(asset.metadata.surveyCalculations, ({ surveyCode }) => surveyCode);
        const keys = Object.keys(groupByCode);

        keys.forEach(k => groupByCodeArray.push(groupByCode[k]));
        groupByCodeArray.forEach(g => lastCalculationSurveys.push(g[g.length - 1]));
    }

    return(
        <Box>
            <Tabs
              value={tab}
              variant="fullWidth"
              onChange={handleTabChange}
              aria-label="icon position tabs example"
            >
              <Tab 
                icon={<QueryStatsIcon />} 
                iconPosition="start" 
                label="Reports"
                {...tabIndexProps(0)}
              />
               <Tab 
                icon={<RestoreIcon />} 
                iconPosition="start" 
                label="History"
                {...tabIndexProps(1)}
              />
            </Tabs>
          
            <TabPanel value={tab} index={0}>
                <Row>
                    <Divider sx={{mb: 1}} textAlign="left">Risk</Divider>
                    <Col md={6}>
                        { lastCalculationSurveys.map(s => 
                            <RiskCalculationScore calculation={s} />
                        )}
                    </Col>
                    <Col md={6}>
                        { lastCalculationSurveys.length >= 3 && 
                            <RiskRadar calculations={lastCalculationSurveys}/>
                        }
                    </Col>

                    <Divider sx={{my: 2}} textAlign="left">ESG</Divider>
                </Row>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <ReportCalculationHistory 
                    calculations={groupByCodeArray}
                />
            </TabPanel>
        </Box> 
    );
};

export default ReportAndKPI;