import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ADD_BUSINESS_SECTOR');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const addBusinessSectorRequest = createAction('ADD_BUSINESS_SECTOR_POST_REQUEST');
export const addBusinessSectorSuccess = createAction('ADD_BUSINESS_SECTOR_POST_SUCCESS');
export const addBusinessSectorError = createAction('ADD_BUSINESS_SECTOR_POST_ERROR');

export const addBusinessSector = (request) => async (dispatch) => {
  try {
    dispatch(addBusinessSectorRequest());
    const resp = await instance.post(`/v1/Asset/AddMetadata`, request);

    dispatch(addBusinessSectorSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addBusinessSectorError(e.response.data.description));
    else
      dispatch(addBusinessSectorError(e.message));
  }
};
