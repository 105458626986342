import { Backdrop, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import StakeholderCountry from "../stakeholderInformation/stakeholderCountry";
import { addLocation, cleanup } from "@/reducers/supplier/locations/addLocation/addLocationAction";
import { FormCheckboxField } from '@/shared/components/form/material-controls/CheckboxField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { locationTypes } from "@/utils/locationTypes";

const CreateLocationModal = ({ assetId, countries, open, onHideModal, onLocationCreated }) => {
    const dispatch = useDispatch();

    const { 
      isAddingLocation, addLocationError, addedLocation,
    } = useSelector(({ addLocation }) => addLocation);
    
    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
          reset();
          onHideModal();
    }
  
    useEffect(() => {
      if(addLocationError){
        enqueueSnackbar(addLocationError, {variant: "error"});
      }
    }, [addLocationError]);
  
    const onSubmit = (data) => { 

      const finalData = {
        assetCreate: {
          name: data.name,
          description: data.description,
          assetTypeId: "63ea1fb41600303865474038"
        },
        assetMetadata: {
          source: "locationInformation",
          metadata: {
            address: data.address,
            city: data.city,
            country: data.country,
            nationalRegion: data.nationalRegion,
            district: data.district,
            zipCode: data.zipCode,
            location: data.location,
            siteCode: data.siteCode,
            dictionaryCountryId: data.dictionaryCountryId,
            countryCode: data.countryCode,
            regionCode: data.regionCode,
            subRegionCode: data.subRegionCode, 
            intermediateRegionCode: data.intermediateRegionCode,
            locationTypeId: data.locationTypeId,
            isHeadquarters: data.isHeadquarters
          }
        },
        assetRelationships: [{
          sourceId: assetId,
          linkName: "Container",
          linkValue: "Location"
        }]
      };

      dispatch(addLocation(finalData));
    }

    useEffect(() => {
      if(addedLocation){
        enqueueSnackbar("Location created successfully", {variant: "success"});
        handleClose();
        onLocationCreated();
      }
    }, [addedLocation]);

    return (
      <Dialog
          fullWidth
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Add Location</DialogTitle>
          <DialogContent>
                <Row>
                  <Col md={9}>
                    <FormTextField
                      name='name'
                      label='Name'
                      control={control}
                      rules={{ required: 'This field is required' }}
                    />
                  </Col>
                  <Col md={3}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: '20px'
                    }}>
                      <FormCheckboxField
                        name='isHeadquarters'
                        label='Headquarters'
                        control={control}
                        defaultValue={true}
                      />
                    </Box>
                  </Col>
                  <Col md={12}>
                    <FormSelectField 
                        name="locationTypeId" 
                        label="Location Type" 
                        control={control}
                        options={locationTypes}
                        keyValue={{id: "id", label: "name"}}
                    />
                  </Col>
                  <Col md={12}>
                    <FormTextField
                        name='description'
                        label='Description'
                        control={control}
                    />
                  </Col>
                  <Col md={12}>
                    <FormTextField
                        name='address'
                        label='Address'
                        control={control}
                    />
                  </Col>
                  <Col md={6}>
                    <FormTextField
                        name='city'
                        label='City'
                        control={control}
                    />
                  </Col>
                  <Col md={6}>
                    <FormTextField
                        name='country'
                        label='Country'
                        control={control}
                    />
                  </Col>
                  <Col md={6}>
                    <FormTextField
                        name='nationalRegion'
                        label='National Region'
                        control={control}
                    />
                  </Col>
                  <Col md={6}>
                    <FormTextField
                        name='district'
                        label='District'
                        control={control}
                    />
                  </Col>
                  <Col md={6}>
                    <FormTextField
                        name='zipCode'
                        label='Zip Code'
                        control={control}
                    />
                  </Col>
                  <Col md={6}>
                    <FormTextField
                        name='siteCode'
                        label='Site Code'
                        control={control}
                        />
                  </Col>
                  <Col md={12}>
                    <FormTextField
                        name='location'
                        label='Location'
                        control={control}
                    />
                  </Col>
                  <StakeholderCountry 
                      control={control}
                      countries={countries}
                      watch={watch}
                      setValue={setValue}
                  />
                </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Create</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isAddingLocation}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default CreateLocationModal;