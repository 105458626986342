import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { Col, Row } from "react-bootstrap";
import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from "@mui/material";
import AddStakeholderUserModal from '../../../../Registry/Supplier/Users/AddNew/addStakeholderUserModal';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

const AssignUsers = ({
  rolesWithUsers,
  control,
  asset,
  roles,
  supplier,
  onUserAdded
}) => {

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const onClickAddUser = () => {
    setShowAddUserModal(true);
  };

  const onHideAddUserModal = () => {
    setShowAddUserModal(false);
  };

  return(
    <>
      <Card variant='outline' sx={{border: '1px solid'}}>
        <CardContent>
          <Typography>Assign Users</Typography>
          <Row>
              {roles.map((item, index) => {
                const grouppedUsers = rolesWithUsers.find(r => r.roleId === item);
                
                return ( 
                  <Col md={4} key={index}>
                      <FormSelectField
                          name={`usersRoles.${item}`}
                          label={`User for role ${grouppedUsers.roleName}`}
                          rules={{ required: 'This field is required' }} 
                          control={control}
                          options={grouppedUsers.users}
                          keyValue={{label: "fullName", id: "id"}}
                          defaultValue={asset.userRoles ? asset.userRoles[item] : null}
                          noOptionsText="Non ci sono utenti per questo stakeholder"
                      />
                  </Col>
              )})
            }

            <Col md={4} style={{ display: 'flex', alignItems: 'flex-end'}}>
              <Button variant="contained" onClick={onClickAddUser} startIcon={<AddIcon />}>Add User</Button>
            </Col>
          </Row>
        </CardContent>
      </Card>

      { showAddUserModal &&
        <AddStakeholderUserModal
          stakeholderId={supplier.stakeholderId}
          onHideModal={onHideAddUserModal} 
          open={showAddUserModal}
          onUserAdded={onUserAdded}
        />
      }
    </>
  );
};

export default AssignUsers;