import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateBusinessUnitRequest,
  updateBusinessUnitSuccess,
  updateBusinessUnitError,
  getBusinessUnitRequest,
  getBusinessUnitSuccess,
  getBusinessUnitError,
  getBusinessUnitsRequest,
  getBusinessUnitsSuccess,
  getBusinessUnitsError
} from './updateBusinessUnitAction';

const defaultState = {
  businessUnit: null,
  isLoadingBusinessUnit: true,
  getBusinessUnitError: null,
  isUpdatingBusinessUnit: false,
  updateBusinessUnitError: null,
  updatedBusinessUnit: false,
  businessUnits: [],
  isLoadingBusinessUnits: true,
  getBusinessUnitsError: null
};

export default handleActions(
  {
    [getBusinessUnitRequest](state) {
      return {
        ...state,
        businessUnit: null,
        isLoadingBusinessUnit: true,
        getBusinessUnitError: null,
      };
    },
    [getBusinessUnitSuccess](state, { payload }) {
      return {
        ...state,
        businessUnit: payload,
        isLoadingBusinessUnit: false,
        getBusinessUnitError: null,
      };
    },
    [getBusinessUnitError](state, { payload }) {
      return {
        ...state,
        businessUnit: null,
        isLoadingBusinessUnit: false,
        getBusinessUnitError: payload,
      };
    },
    [updateBusinessUnitRequest](state) {
      return {
        ...state,
        isUpdatingBusinessUnit: true,
        updateBusinessUnit: null,
        updatedBusinessUnit: false
      };
    },
    [updateBusinessUnitSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingBusinessUnit: false,
        updateBusinessUnitError: null,
        updatedBusinessUnit: true
      };
    },
    [updateBusinessUnitError](state, { payload }) {
      return {
        ...state,
        isUpdatingBusinessUnit: false,
        updateBusinessUnitError: payload,
        updatedBusinessUnit: false
      };
    },
    [getBusinessUnitsRequest](state) {
      return {
        ...state,
        isLoadingBusinessUnits: true,
        getBusinessUnitsError: null,
      };
    },
    [getBusinessUnitsSuccess](state, { payload }) {
      return {
        ...state,
        businessUnits: payload,
        isLoadingBusinessUnits: false,
        getBusinessUnitsError: null,
      };
    },
    [getBusinessUnitsError](state, { payload }) {
      return {
        ...state,
        isLoadingBusinessUnits: false,
        getBusinessUnitsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);