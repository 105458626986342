import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { FormSelectField } from "@/shared/components/form/material-controls/SelectField";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { addBusinessUnit, cleanup, getBusinessUnits } from "@/reducers/businessUnits/addBusinessUnit/addBusinessUnitAction";

const AddBusinessUnit = () => {
    const { t } = useTranslation("common");

    const dispatch = useDispatch();
    const { 
      isAddingBusinessUnit, addBusinessUnitError, addedBusinessUnit,
      businessUnits, isLoadingBusinessUnits, getBusinessUnitsError
    } = useSelector(({ addBusinessUnit }) => addBusinessUnit);

    const navigate = useNavigate();
    const { handleSubmit, watch, getValues, reset, control, setValue } = useForm();

    useEffect(() => {
      dispatch(getBusinessUnits());

      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
    
    useEffect(() => {
        if(addBusinessUnitError){
            enqueueSnackbar(addBusinessUnitError, {variant: "error"});
        }
    }, [addBusinessUnitError]);

    useEffect(() => {
      if(getBusinessUnitsError){
      enqueueSnackbar(getBusinessUnitsError, {variant: "error"});
      }
  }, [getBusinessUnitsError]);

    useEffect(() => {
        if(addedBusinessUnit){
          enqueueSnackbar(`Business Unit created successfuly.`, {variant: "success"});
          navigate(-1);
        }
    }, [addedBusinessUnit]);

    const onSubmit = (data) => {
        dispatch(addBusinessUnit(data));
    }

    const onClickCancel = () => {
        navigate(-1);
    }

    return(
        <Container>
            <CardPage>
                <CardPageBody>         
                    <CardPageTitleWrap>
                        <CardPageTitle>Add Business Unit</CardPageTitle>
                    </CardPageTitleWrap>
                    <Row>
                        <Col md={12}>
                            <FormTextField 
                                name="name" 
                                label="Name" 
                                control={control} 
                                rules={{ required: 'This field is required' }}
                            />
                        </Col>
                        <Col md={12}>
                            <FormTextField 
                                name="description" 
                                label="Description" 
                                control={control} 
                            />
                        </Col>
                        <Col md={12}>
                            <FormSelectField 
                                name="businessUnitParentId" 
                                label="Parent Business Unit" 
                                control={control}
                                options={businessUnits}
                                keyValue={{id: "businessUnitId", label: "name"}}
                            />
                        </Col>
                    </Row>
                    
                    <Row style={{marginTop: 20}}>
                        <Col md={12}>
                            <Grid
                                container
                                justifyContent="flex-end"
                                spacing={2}
                            >
                                <Grid item>
                                    <Button
                                        onClick={onClickCancel}
                                        variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleSubmit(onSubmit)}
                                        variant="contained"
                                    >
                                        Create
                                    </Button>
                                </Grid>
                                
                            </Grid>
                        </Col>
                    </Row>
                </CardPageBody>
            </CardPage>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isAddingBusinessUnit}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default AddBusinessUnit;