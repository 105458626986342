import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_UPDATE_BUSINESS_SECTOR');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateBusinessSectorRequest = createAction('UPDATE_BUSINESS_SECTOR_POST_REQUEST');
export const updateBusinessSectorSuccess = createAction('UPDATE_BUSINESS_SECTOR_POST_SUCCESS');
export const updateBusinessSectorError = createAction('UPDATE_BUSINESS_SECTOR_POST_ERROR');

export const updateBusinessSector = (request) => async (dispatch) => {
  try {
    dispatch(updateBusinessSectorRequest());
    const resp = await instance.post(`/v1/Asset/AddMetadata`, request);

    dispatch(updateBusinessSectorSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateBusinessSectorError(e.response.data.description));
    else
      dispatch(updateBusinessSectorError(e.message));
  }
};
