import { Box, Chip, ListItem, Paper } from "@mui/material";
import { useState } from "react";

const SelectChipArray = ({
    options, 
    onChange, 
    defaultValue,
    keyValue={id:"id", label:"name"}
}) => {

    const [selected, setSelected] = useState(defaultValue);

    const handleClick = (option) => {
        if(option[keyValue.id] !== selected[keyValue.id]){
            setSelected(option);
            onChange(option);
        }
    };

    return(
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
            }}
            component="ul"
        >
            {options.map((option) => {

                const isSelected = option[keyValue.id] === selected[keyValue.id];

                return (
                <ListItem sx={{ width: 'auto' }}
                    key={option[keyValue.id]}
                >
                    <Chip
                        label={option[keyValue.label]}
                        onClick={() => handleClick(option)}
                        color={isSelected ? "primary" : "default"}
                        variant={isSelected ? "filled" : "outlined"}
                    />
                </ListItem>
                );
            })}
        </Box>
    );
}

export default SelectChipArray;