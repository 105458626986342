import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addBusinessSector, cleanup } from "@/reducers/supplier/businessSectors/addBusinessSector/stakeholderAddBusinessSectorAction";
import BusinessSectorSelection from "./businessSectorSelection";

const CreateBusinessSectorModal = ({
  asset,
  naics, 
  open, 
  onHideModal, 
  onBusinessSectorCreated 
}) => {
    const dispatch = useDispatch();

    const { 
      isAddingBusinessSector, addBusinessSectorError, addedBusinessSector,
    } = useSelector(({ stakeholderAddBusinessSector }) => stakeholderAddBusinessSector);
    
    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
          reset();
          onHideModal();
    }
  
    useEffect(() => {
      if(addBusinessSectorError){
        enqueueSnackbar(addBusinessSectorError, {variant: "error"});
      }
    }, [addBusinessSectorError]);
  
    const onSubmit = (data) => { 
      const selectedNaics = naics.find(n => n.dictionaryNaicsId === data.dictionaryNaicsId);
      var currentBusinessSectors = asset.metadata?.businesssectorInformation?.businessSectors ?
        [...asset.metadata?.businesssectorInformation?.businessSectors, selectedNaics] : [selectedNaics];

      const finalData = {
        assetId: asset.id,
        source: "businessSectorInformation",
        metadata: {
          businessSectors: currentBusinessSectors
        }
      }; 

      dispatch(addBusinessSector(finalData));
    }

    useEffect(() => {
      if(addedBusinessSector){
        enqueueSnackbar("Business Sector added successfully", {variant: "success"});
        handleClose();
        onBusinessSectorCreated();
      }
    }, [addedBusinessSector]);

    return (
      <Dialog
          fullWidth
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Add Business Sector</DialogTitle>
          <DialogContent>
            <BusinessSectorSelection 
              control={control}
              naics={naics}
            />
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Add</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isAddingBusinessSector}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default CreateBusinessSectorModal;