import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  completeRequest,
  completeSuccess,
  completeError,
  getAssetRequest,
  getAssetSuccess,
  getAssetError,
} from './stakeholderBusinessSectorsSurveyAction';

const defaultState = {
  isCompleting: false,
  completedResult: null,
  completeError: null,
  asset: null,
  isLoadingAsset: true,
  getAssetError: null
};

export default handleActions(
  {
    [completeRequest](state) {
      return {
        ...state,
        isCompleting: true,
        completedResult: null,
        completeError: null
      };
    },
    [completeSuccess](state, { payload }) {
      return {
        ...state,
        isCompleting: false,
        completedResult: payload,
        completeError: null
      };
    },
    [completeError](state, { payload }) {
      return {
        ...state,
        isCompleting: false,
        completedResult: null,
        completeError: payload
      };
    },
    [getAssetRequest](state) {
      return {
        ...state,
        asset: null,
        isLoadingAsset: true,
        getAssetError: null,
      };
    },
    [getAssetSuccess](state, { payload }) {
      return {
        ...state,
        asset: payload,
        isLoadingAsset: false,
        getAssetError: null,
      };
    },
    [getAssetError](state, { payload }) {
      return {
        ...state,
        asset: null,
        isLoadingAsset: false,
        getAssetError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);