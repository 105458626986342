import { Badge, Box, Chip, Divider, IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import LanIcon from '@mui/icons-material/Lan';
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import OutputIcon from '@mui/icons-material/Output';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ActivityChildren = ({
    activity
}) => {
    const navigate = useNavigate();

    const { user } = useSelector(({ userInfo }) => userInfo);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickEdit = (child) => {
        navigate("/Activities/EditStep/" + child.workflowActivityId);
    }

    return(
        <>
            <Badge badgeContent={activity.workflowActivityChildren.length} overlap="circular" color="primary">
                <IconButton 
                        id={`children-button${activity.workflowActivityId}`}
                        aria-controls={open ? `children${activity.workflowActivityId}` : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickMenu}
                        aria-label="children"
                    >
                        <LanIcon />
                </IconButton>
            </Badge>

            <Menu
                id={`children${activity.workflowActivityId}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            >
                {activity.workflowActivityChildren.map((child, index) => {
                    const editorNames = [];
                    if(child.editors && child.editors.length){
                        child.editors.forEach((e) => {
                            if(e.id !== user.id){
                                editorNames.push(e.firstName + " " + e.lastName);
                            }
                        });
                    }

                    return(
                        <> 
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end" aria-label="enter" onClick={() => handleClickEdit(child)}>
                                        <OutputIcon />
                                    </IconButton>
                                }
                            >   
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                }}>
                                    <Typography>{child.activityType.label}</Typography>
                                    <Chip 
                                        label={child.workflowActivityStatus.workflowGenericStatus.label} 
                                        size="small" 
                                        sx={{
                                            backgroundColor: child.workflowActivityStatus.workflowGenericStatus.color, 
                                            fontSize: '12px',
                                            height: '20px',
                                            marginRight: 1
                                        }} 
                                    />
                                    { editorNames.length > 0 &&
                                        <Typography width='200px' marginTop='4px' fontSize='14px' noWrap color="text.secondary">[{editorNames.join(", ")}]</Typography>
                                    }
                                    <Typography width='200px' noWrap color='text.secondary' fontSize='14px'>
                                        {child.description}
                                    </Typography>
                                </Box>
                            </ListItem>
                            {index !== activity.workflowActivityChildren.length - 1 && <Divider component='div' />}
                        </>
                    )
                }
                    
                )}
            </Menu>
        </>
    );
};

export default ActivityChildren;