import React, { useState } from "react";
import styled from "styled-components";
import { paddingLeft } from "@/utils/directions";
import Topbar from "./topbar/Topbar";
import Sidebar from "./components/sidebar/Sidebar";
import { withRouter } from "../utils/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "../reducers/utils";
import Chatbot from "./components/chatbot";
import ChatbotOpenButton from "./components/chatbot/chatbotOpenButton";

const Layout = () => {
  const [collapse, setCollapse] = useState(true);
  const [show, setShow] = useState(false);
  const sidebar = { show: show, collapse: collapse };
  const darkTheme = useSelector(({changeTheme})=>(changeTheme.theme));
  const dispatch = useDispatch();

  const showChatbot = process.env.REACT_APP_SHOW_BOT === "true";
  const isShowingChatbot = useSelector(({ chatbot }) => chatbot.show);

  const sidebarVisibility = () => {
    setCollapse(!collapse);
  };

  const mobileSidebarVisibility = () => {
    setShow(!show);
  };

  const changeToDark = () => {
    dispatch(changeTheme(darkTheme));
  };

  return (
    <LayoutContainer collapse={sidebar.collapse}>
      <Topbar
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        changeSidebarVisibility={sidebarVisibility}
        changeToDark={changeToDark}
        darkTheme={darkTheme}
      />
      <Sidebar
        sidebar={sidebar}
        changeMobileSidebarVisibility={mobileSidebarVisibility}
      />
      { showChatbot && <Chatbot /> }
      { showChatbot && !isShowingChatbot && <ChatbotOpenButton /> }
    </LayoutContainer>
  );
};

export default withRouter(Layout);

// region STYLES

const LayoutContainer = styled.div`
  & + div {
    ${(props) =>
      props.collapse &&
      `
      ${paddingLeft(props)}: 0;
    `};

    @media screen and (min-width: 576px) {
      ${(props) =>
        props.collapse &&
        `
        ${paddingLeft(props)}: 60px;
      `}
    }
  }
`;

// endregion
