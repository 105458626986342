import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { complete, getAsset, cleanup } from "@/reducers/selfAssessment/stakeholderBusinessSectorsSurvey/stakeholderBusinessSectorsSurveyAction";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import BusinessSectorsList from "../../../../../../Registry/Supplier/components/businessSectorsList";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';

const StakeholderBusinessSectorsSurvey = ({
    activitySurvey,
    surveyInfo,
    activitySurveyId,
    isSupervisor,
    workflowActivityStepCompletedId,
    onComplete
}) => {

    const dispatch = useDispatch();

    const { 
        isCompleting, completedResult, completeError,
        asset, isLoadingAsset, getAssetError
    } = useSelector(({ stakeholderBusinessSectorsSurvey }) => stakeholderBusinessSectorsSurvey);

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    useEffect(() => {
      dispatch(getAsset(activitySurvey.assetActivitySurveys[0].id));

        return () => {
            dispatch(cleanup());
        }
    }, []);

    useEffect(() => {
      if(getAssetError){
        enqueueSnackbar(getAssetError, {variant: "error"});
      }
  }, [getAssetError]);

    useEffect(() => {
        if(completeError){
          enqueueSnackbar(completeError, {variant: "error"});
        }
    }, [completeError]);

    useEffect(() => {
        if(completedResult){
            enqueueSnackbar("Survey saved successfully", {variant: "success"});
            onComplete(surveyInfo.surveyId, completedResult);
        }
    }, [completedResult]);

    const handleOnCloseConfirm = () => {
        setOpenConfirmModal(false);
    };

    const handleConfirmComplete = () => {
        const finalData = {
            id: activitySurveyId,
            workflowActivityStepCompletedId,
            surveyId: surveyInfo.surveyId
        };

        if(asset.metadata?.businesssectorInformation?.businessSectors){
            finalData.businessSectors = asset.metadata.businesssectorInformation.businessSectors.map(b => {
                return {
                    dictionaryNaicsId: b.dictionaryNaicsId,
                    code: b.code
                };
            });
        }

        dispatch(complete(finalData));
        
        setOpenConfirmModal(false);
    }

    const onSubmit = () => {
        setOpenConfirmModal(true);
    }

    const onChangedBusinessSectors = () => {
      dispatch(getAsset(activitySurvey.assetActivitySurveys[0].id));
  };

    return (
        <Box sx={{ width: '100%', paddingTop: '20px' }}>
          { isLoadingAsset ? <CircularProgressWrapper /> :
            <BusinessSectorsList 
              asset={asset}
              onChangedBusinessSectors={onChangedBusinessSectors}
            />
          }

            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '20px'
            }}>
                <Button variant="contained" size="large" onClick={onSubmit}>Save</Button>
            </Box>

            {openConfirmModal &&
                <ConfirmationDialog 
                    open={openConfirmModal}
                    onConfirm={handleConfirmComplete}
                    onClose={handleOnCloseConfirm}
                    title="Complete survey"
                    body={`Sei sicuro di voler procedere? La conferma chiude il task dell’attività e verrà inviata una mail al responsabile della Campagna.`}
                    color='primary'
                    confirmButtonText='Complete'
                />
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isCompleting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
};

export default StakeholderBusinessSectorsSurvey;