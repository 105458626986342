import { Box, Chip, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';

const ConversationHeader = ({
    onClickAwake,
    status,
    isAwaking,
    onClickClose,
    onClickReset,
}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 1
        }}>
            {/* <Typography sx={{
                marginLeft: 1,
                fontSize: '24px'
            }} 
            color='text.primary'>R2 Copilot</Typography> */}
            <Box sx={{
                marginLeft: 'auto'
            }}>
                <Chip 
                    label="Awake" 
                    onClick={onClickAwake} 
                    size="small"
                    color="primary"
                    disabled={status === "on" || isAwaking}
                />
                <IconButton onClick={onClickReset}>
                    <ReplayIcon />
                </IconButton>
                <IconButton onClick={onClickClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
    );
}

export default ConversationHeader;