import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { Backdrop, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { useForm } from "react-hook-form";
import { IsValidEmail } from "@/utils/formValidations";
import { updateTenantAsset } from "@/reducers/currentTenant/currentTenantAction";
import BusinessSectorsList from "../../../Registry/Supplier/components/businessSectorsList";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { cleanup, getAsset } from "@/reducers/organizations/updateOrganization/updateOrganizationAction";
import TenantSocialForm from "./components/tenantSocialForm";

const TenantBusinessSectors = () => {
  
  const { 
    activity, 
    workflowActivityStepCompletedId, 
    isEditor,
    handleRefreshProgress,
    activityType,
    handleClickNext
} = useOutletContext();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const dispatch = useDispatch();

  const {
    asset, isLoadingAsset, getAssetError
  } = useSelector(({ updateOrganization }) => updateOrganization);

  const { 
      currentTenant, isLoadingCurrentTenant, getCurrentTenantError,
      isUpdatingTenantAsset, updatedTenantAsset, updateTenantAssetError
  } = useSelector(({ currentTenant }) => currentTenant);

  useEffect(() => {
    if(currentTenant) {
        dispatch(getAsset(currentTenant.assetId));
    }

    return () => {
        dispatch(cleanup());
    }
  }, [currentTenant]);

  useEffect(() => {
    if(asset){
        setIsFirstLoad(false);
    }
  }, [asset]);

  useEffect(() => {
    if(getAssetError){
        enqueueSnackbar(getAssetError, {variant: "error"});
    }
  }, [getAssetError]);

  useEffect(() => {
    if(getCurrentTenantError){
        enqueueSnackbar(getCurrentTenantError, {variant: "error"});
    }
  }, [getCurrentTenantError]);

  useEffect(() => {
    if(updateTenantAssetError){
        enqueueSnackbar(updateTenantAssetError, {variant: "error"});
    }
  }, [updateTenantAssetError]);

  useEffect(() => {
    if(updatedTenantAsset){
      enqueueSnackbar(`Tenant information updated successfuly.`, {variant: "success"});
    }
  }, [updatedTenantAsset]);

  const onChangedBusinessSectors = () => {
    dispatch(getAsset(currentTenant.assetId));
  };

  const onSubmit = (data) => {
    const finalData = {
      assetId: asset.id,
      source: "tenantSocialInformation",
      metadata: {
        ...data
      }
    }; 

    dispatch(updateTenantAsset(finalData));
  }

  return (
      <>
        { isLoadingAsset || isLoadingCurrentTenant ? <CircularProgressWrapper /> :
          <>
            <BusinessSectorsList 
              asset={asset}
              onChangedBusinessSectors={onChangedBusinessSectors}
            />
          </>
        }

        { (!isLoadingAsset || !isFirstLoad) &&
          ( 
            <TenantSocialForm 
            asset={asset}
            onSubmit={onSubmit} 
            />
          ) 
        }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingTenantAsset}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </>
  );
};

export default TenantBusinessSectors;