import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const toggleChatbot = createAction('CHATBOT_TOGGLE');

export const reset = createAction('CHATBOT_RESET');

export const cleanupAction = createAction('CLEANUP_CHATBOT');

export const cleanup = ()  => async (dispatch) => {
    dispatch(cleanupAction());
}
  
export const getWelcomeMessageRequest = createAction('CHATBOT_GET_WELCOME_MESSAGE_REQUEST');
export const getWelcomeMessageSuccess = createAction('CHATBOT_GET_WELCOME_MESSAGE_SUCCESS');
export const getWelcomeMessageError = createAction('CHATBOT_GET_WELCOME_MESSAGE_ERROR');

export const getWelcomeMessage = () => async (dispatch) => {
    try {
        dispatch(getWelcomeMessageRequest());
        const resp = await instance.get(`/v1/Copilot/GetWelcomeMessage`);
        
        dispatch(getWelcomeMessageSuccess(resp.data));

    } catch (e) {
        if(e.response && e.response.data && e.response.data.description)
        dispatch(getWelcomeMessageError(e.response.data.description));
        else
        dispatch(getWelcomeMessageError(e.message));
    }
};

export const sendMessageRequest = createAction('CHATBOT_SEND_MESSAGE_REQUEST');
export const sendMessageSuccess = createAction('CHATBOT_SEND_MESSAGE_SUCCESS');
export const sendMessageError = createAction('CHATBOT_SEND_MESSAGE_ERROR');

export const sendMessage = (data) => async (dispatch) => {
  try {
    dispatch(sendMessageRequest(data.messages[data.messages.length - 1]));

    const resp = await instance.post(`/v1/Copilot/ChatToCopilot`, data);

    dispatch(sendMessageSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(sendMessageError(e.response.data.description));
    else
      dispatch(sendMessageError(e.message));
  }
};

export const awakeRequest = createAction('CHATBOT_AWAKE_REQUEST');
export const awakeSuccess = createAction('CHATBOT_AWAKE_SUCCESS');
export const awakeError = createAction('CHATBOT_AWAKE_ERROR');

export const awake = (data) => async (dispatch) => {
  try {
    dispatch(awakeRequest());
    const resp = await instance.post(`/v1/Copilot/CheckIfAwake`, data);

    dispatch(awakeSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(awakeError(e.response.data.description));
    else
      dispatch(awakeError(e.message));
  }
};

export const finishWritingMessage = createAction('CHATBOT_FINISH_WRITING');
export const finishWriting = (message) => async (dispatch) => {
  dispatch(finishWritingMessage(message));
};

export const analyzeSentimentRequest = createAction('CHATBOT_ANALIZE_SENTIMENT_REQUEST');
export const analyzeSentimentSuccess = createAction('CHATBOT_ANALIZE_SENTIMENT_SUCCESS');
export const analyzeSentimentError = createAction('CHATBOT_ANALIZE_SENTIMENT_ERROR');

export const analyzeSentiment = (message) => async (dispatch) => {
  try {
    const data = {
      value: message
    };

    dispatch(analyzeSentimentRequest(message));

    const resp = await instance.post(`/v1/Copilot/AnalyzeSentiment`, data);

    dispatch(analyzeSentimentSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(analyzeSentimentError(e.response.data.description));
    else
      dispatch(analyzeSentimentError(e.message));
  }
};