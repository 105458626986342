import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_STAKEHOLDER_LOCATION_SURVEY');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const completeRequest = createAction('STAKEHOLDER_LOCATION_SURVEY_COMPLETE_REQUEST');
export const completeSuccess = createAction('STAKEHOLDER_LOCATION_SURVEY_COMPLETE_SUCCESS');
export const completeError = createAction('STAKEHOLDER_LOCATION_SURVEY_COMPLETE_ERROR');

export const complete = (data) => async (dispatch) => {
  try {
    dispatch(completeRequest());
    const resp = await instance.post(`/v1/ActivitySurvey/UpdateStakeholderLocations`, data);
    
    dispatch(completeSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(completeError(e.response.data.description));
    else
      dispatch(completeError(e.message));
  }
};