import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getBusinessUnitsRequest,
  getBusinessUnitsSuccess,
  getBusinessUnitsError,
  deleteBusinessUnitRequest,
  deleteBusinessUnitSuccess,
  deleteBusinessUnitError
} from './businessUnitsAction';

const defaultState = {
  businessUnits: [],
  isLoadingBusinessUnits: true,
  getBusinessUnitsError: null,
  isDeletingBusinessUnit: false,
  deleteBusinessUnitError: null,
  deletedBusinessUnit: false,
};

export default handleActions(
  {
    [getBusinessUnitsRequest](state) {
      return {
        ...state,
        businessUnits: [],
        isLoadingBusinessUnits: true,
        getBusinessUnitsError: null,
      };
    },
    [getBusinessUnitsSuccess](state, { payload }) {
      return {
        ...state,
        businessUnits: payload,
        isLoadingBusinessUnits: false,
        getBusinessUnitsError: null,
      };
    },
    [getBusinessUnitsError](state, { payload }) {
      return {
        ...state,
        businessUnits: [],
        isLoadingBusinessUnits: false,
        getBusinessUnitsError: payload,
      };
    },
    [deleteBusinessUnitRequest](state) {
      return {
        ...state,
        isDeletingBusinessUnit: true,
        deletedBusinessUnit: false,
        deleteBusinessUnitError: null,
      };
    },
    [deleteBusinessUnitSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingBusinessUnit: false,
        deletedBusinessUnit: true,
        deleteBusinessUnitError: null,
      };
    },
    [deleteBusinessUnitError](state, { payload }) {
      return {
        ...state,
        isDeletingBusinessUnit: false,
        deletedBusinessUnit: false,
        deleteBusinessUnitError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);