import EmailAndUserName from "@/shared/components/EmailAndUserName";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { FormDateField } from "@/shared/components/form/material-controls/DateField";
import { Col, Row } from "react-bootstrap";

const AddStakeholderUserForm = ({ control, watch, setValue, getValues }) => {
  return(
    <>
      <EmailAndUserName 
          control={control}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
      />
      <Row>
          <Col md={6}>
              <FormTextField 
                  name="firstName" 
                  label="First Name" 
                  control={control} 
                  rules={{ required: 'This field is required' }}
              />
          </Col>
          <Col md={6}>
              <FormTextField 
                  name="lastName" 
                  label="Last Name" 
                  control={control} 
                  rules={{ required: 'This field is required' }}
              />
          </Col>
      </Row>
      <Row>
          <Col md={6}>
              <FormTextField 
                  name="birthPlace" 
                  label="Birth Place" 
                  control={control} 
              />
          </Col>
          <Col md={6}>
              <FormDateField 
                  name="birthDate" 
                  label="Birthday" 
                  control={control} 
              />
          </Col>
      </Row>
      <Row>
          <Col md={6}>
              <FormTextField 
                  name="fiscalCode" 
                  label="Fiscal Code" 
                  control={control} 
              />
          </Col>
          <Col md={6}>
              <FormTextField 
                  name="phoneNumber" 
                  label="Phone Number"
                  control={control}
                  type="number"
              />
          </Col>
      </Row>
    </>
  );
};

export default AddStakeholderUserForm;