import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_HOME');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

//ESG BANKS
export const getESGBanksStoriesRequest = createAction('NEWS_MONITOR_GET_ESG_BANKS_STORIES_REQUEST');
export const getESGBanksStoriesSuccess = createAction('NEWS_MONITOR_GET_ESG_BANKS_STORIES_SUCCESS');
export const getESGBanksStoriesError = createAction('NEWS_MONITOR_GET_ESG_BANKS_STORIES_ERROR');

export const getESGBanksStories = () => async (dispatch) => {
  try {
    dispatch(getESGBanksStoriesRequest());
    const resp = await instance.get(`/v1/Aylien/GetESGBanksStories`);
    
    dispatch(getESGBanksStoriesSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getESGBanksStoriesError(e.response.data.description));
    else
      dispatch(getESGBanksStoriesError(e.message));
  }
};

export const getESGBanksTrendsRequest = createAction('NEWS_MONITOR_GET_ESG_BANKS_TRENDS_REQUEST');
export const getESGBanksTrendsSuccess = createAction('NEWS_MONITOR_GET_ESG_BANKS_TRENDS_SUCCESS');
export const getESGBanksTrendsError = createAction('NEWS_MONITOR_GET_ESG_BANKS_TRENDS_ERROR');

export const getESGBanksTrends = () => async (dispatch) => {
  try {
    dispatch(getESGBanksTrendsRequest());
    const resp = await instance.get(`/v1/Aylien/GetESGBanksTrends`);
    
    dispatch(getESGBanksTrendsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getESGBanksTrendsError(e.response.data.description));
    else
      dispatch(getESGBanksTrendsError(e.message));
  }
};

export const getESGBanksSentimentRequest = createAction('NEWS_MONITOR_GET_ESG_BANKS_SENTIMENT_REQUEST');
export const getESGBanksSentimentSuccess = createAction('NEWS_MONITOR_GET_ESG_BANKS_SENTIMENT_SUCCESS');
export const getESGBanksSentimentError = createAction('NEWS_MONITOR_GET_ESG_BANKS_SENTIMENT_ERROR');

export const getESGBanksSentiment = (entity) => async (dispatch) => {
  try {
    dispatch(getESGBanksSentimentRequest());
    const resp = await instance.get(`/v1/Aylien/GetESGBanksSentiment?entity=${entity}`);
    
    dispatch(getESGBanksSentimentSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getESGBanksSentimentError(e.response.data.description));
    else
      dispatch(getESGBanksSentimentError(e.message));
  }
};


//ESG BIG ENERGY
export const getESGBigEnergyStoriesRequest = createAction('NEWS_MONITOR_GET_ESG_BIG_ENERGY_STORIES_REQUEST');
export const getESGBigEnergyStoriesSuccess = createAction('NEWS_MONITOR_GET_ESG_BIG_ENERGY_STORIES_SUCCESS');
export const getESGBigEnergyStoriesError = createAction('NEWS_MONITOR_GET_ESG_BIG_ENERGY_STORIES_ERROR');

export const getESGBigEnergyStories = () => async (dispatch) => {
  try {
    dispatch(getESGBigEnergyStoriesRequest());
    const resp = await instance.get(`/v1/Aylien/GetESGBigEnergyStories`);
    
    dispatch(getESGBigEnergyStoriesSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getESGBigEnergyStoriesError(e.response.data.description));
    else
      dispatch(getESGBigEnergyStoriesError(e.message));
  }
};

export const getESGBigEnergyTrendsRequest = createAction('NEWS_MONITOR_GET_ESG_BIG_ENERGY_TRENDS_REQUEST');
export const getESGBigEnergyTrendsSuccess = createAction('NEWS_MONITOR_GET_ESG_BIG_ENERGY_TRENDS_SUCCESS');
export const getESGBigEnergyTrendsError = createAction('NEWS_MONITOR_GET_ESG_BIG_ENERGY_TRENDS_ERROR');

export const getESGBigEnergyTrends = () => async (dispatch) => {
  try {
    dispatch(getESGBigEnergyTrendsRequest());
    const resp = await instance.get(`/v1/Aylien/GetESGBigEnergyTrends`);
    
    dispatch(getESGBigEnergyTrendsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getESGBigEnergyTrendsError(e.response.data.description));
    else
      dispatch(getESGBigEnergyTrendsError(e.message));
  }
};

export const getESGBigEnergySentimentRequest = createAction('NEWS_MONITOR_GET_ESG_BIG_ENERGY_SENTIMENT_REQUEST');
export const getESGBigEnergySentimentSuccess = createAction('NEWS_MONITOR_GET_ESG_BIG_ENERGY_SENTIMENT_SUCCESS');
export const getESGBigEnergySentimentError = createAction('NEWS_MONITOR_GET_ESG_BIG_ENERGY_SENTIMENT_ERROR');

export const getESGBigEnergySentiment = (entity) => async (dispatch) => {
  try {
    dispatch(getESGBigEnergySentimentRequest());
    const resp = await instance.get(`/v1/Aylien/GetESGBigEnergySentiment?entity=${entity}`);
    
    dispatch(getESGBigEnergySentimentSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getESGBigEnergySentimentError(e.response.data.description));
    else
      dispatch(getESGBigEnergySentimentError(e.message));
  }
};

//CLOUD SERVICE
export const getCloudServiceStoriesRequest = createAction('NEWS_MONITOR_GET_ESG_CLOUD_SERVICE_STORIES_REQUEST');
export const getCloudServiceStoriesSuccess = createAction('NEWS_MONITOR_GET_ESG_CLOUD_SERVICE_STORIES_SUCCESS');
export const getCloudServiceStoriesError = createAction('NEWS_MONITOR_GET_ESG_CLOUD_SERVICE_STORIES_ERROR');

export const getCloudServiceStories = () => async (dispatch) => {
  try {
    dispatch(getCloudServiceStoriesRequest());
    const resp = await instance.get(`/v1/Aylien/GetCloudServiceStories`);
    
    dispatch(getCloudServiceStoriesSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCloudServiceStoriesError(e.response.data.description));
    else
      dispatch(getCloudServiceStoriesError(e.message));
  }
};

export const getCloudServiceTrendsRequest = createAction('NEWS_MONITOR_GET_ESG_CLOUD_SERVICE_TRENDS_REQUEST');
export const getCloudServiceTrendsSuccess = createAction('NEWS_MONITOR_GET_ESG_CLOUD_SERVICE_TRENDS_SUCCESS');
export const getCloudServiceTrendsError = createAction('NEWS_MONITOR_GET_ESG_CLOUD_SERVICE_TRENDS_ERROR');

export const getCloudServiceTrends = () => async (dispatch) => {
  try {
    dispatch(getCloudServiceTrendsRequest());
    const resp = await instance.get(`/v1/Aylien/GetCloudServiceTrends`);
    
    dispatch(getCloudServiceTrendsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCloudServiceTrendsError(e.response.data.description));
    else
      dispatch(getCloudServiceTrendsError(e.message));
  }
};

export const getCloudServiceSentimentRequest = createAction('NEWS_MONITOR_GET_ESG_CLOUD_SERVICE_SENTIMENT_REQUEST');
export const getCloudServiceSentimentSuccess = createAction('NEWS_MONITOR_GET_ESG_CLOUD_SERVICE_SENTIMENT_SUCCESS');
export const getCloudServiceSentimentError = createAction('NEWS_MONITOR_GET_ESG_CLOUD_SERVICE_SENTIMENT_ERROR');

export const getCloudServiceSentiment = (entity) => async (dispatch) => {
  try {
    dispatch(getCloudServiceSentimentRequest());
    const resp = await instance.get(`/v1/Aylien/GetCloudServiceSentiment?entity=${entity}`);
    
    dispatch(getCloudServiceSentimentSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCloudServiceSentimentError(e.response.data.description));
    else
      dispatch(getCloudServiceSentimentError(e.message));
  }
};