import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getAssets, sendSurveys, getRolesAndUsers, getWorkflowRoles, getAuditCampaign, getSurveys, cleanup } from "@/reducers/auditCampaign/sendSurveys/AUSendSurveysAction";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import AssetCard from "../AUSteps/components/assetCard";
import { useForm } from "react-hook-form";
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SplitButton from "@/shared/components/SplitButton";
import ScheduleSendModal from "./components/scheduleSendModal";

const AUCampaignSendSurvey = () => {
  const { 
    activity, 
    workflowActivityStepCompletedId,
    isEditor,
    handleRefreshProgress,
    handleClickNext
  } = useOutletContext();
  
  const dispatch = useDispatch();

  const { handleSubmit, reset, control, setValue, getValues } = useForm();

  const templates = useMemo(() => [
    {
      label: "Template 1",
      id: "template1",
    },
    {
      label: "Template 2",
      id: "template2",
    },
    {
      label: "Template 3",
      id: "template3",
    },
  ], []);

  const { 
      auditCampaign, isLoadingAuditCampaign, getAuditCampaignError,
      surveys, isLoadingSurveys, getSurveysError,
      roles, isLoadingRoles, getRolesError,
      assets, isLoadingAssets, getAssetsError,
      rolesAndUsers, isLoadingRolesAndUsers, getRolesAndUsersError,
      isSendingSurveys, sentSurveys, sendSurveysError
  } = useSelector(({ auSendSurveys }) => auSendSurveys);

  const [showScheduleModal, setShowScheduleModal] = useState(false);

  useEffect(() => {
      dispatch(getAuditCampaign(activity.workflowActivityId));
      dispatch(getWorkflowRoles(5));
      dispatch(getAssets());

      return () => {
          dispatch(cleanup());
      }
  }, [dispatch]);

  useEffect(() => {
      if(auditCampaign){
          dispatch(getSurveys(auditCampaign.surveyIds));
      }
  }, [auditCampaign]);

  useEffect(() => {
      if(auditCampaign && roles && roles.length){

          const userIds = [];
          auditCampaign.campaignAssets.forEach(c => {
              if(c.userRoles){
                  const users = Object.values(c.userRoles);

                  users.forEach(u => {
                      if(!userIds.includes(u)){
                          userIds.push(u);
                      }
                  });
              }
          });
          
          dispatch(getRolesAndUsers(userIds, roles));
      }
  }, [auditCampaign, roles]);

  useEffect(() => {
      if(getAssetsError){
        enqueueSnackbar(getAssetsError, {variant: "error"});
      }
  }, [getAssetsError]);

  useEffect(() => {
      if(getRolesAndUsersError){
        enqueueSnackbar(getRolesAndUsersError, {variant: "error"});
      }
  }, [getRolesAndUsersError]);

  useEffect(() => {
    if(sendSurveysError){
      enqueueSnackbar(sendSurveysError, {variant: "error"});
    }
}, [sendSurveysError]);

  useEffect(() => {
      if(getAuditCampaignError){
        enqueueSnackbar(getAuditCampaignError, {variant: "error"});
      }
  }, [getAuditCampaignError]);

  useEffect(() => {
      if(getSurveysError){
        enqueueSnackbar(getSurveysError, {variant: "error"});
      }
  }, [getSurveysError]);

  useEffect(() => {
    if(getRolesError){
      enqueueSnackbar(getRolesError, {variant: "error"});
    }
  }, [getRolesError]);

  const onClickSend = (index) => {
    if(index === 0){
      onClickSchedule();
    }
    else{
      setShowScheduleModal(true);
    }
  };

  const onHideScheduleModal = () => {
    setShowScheduleModal(false);
  };

  const onClickSchedule = () => {
    if(showScheduleModal){
      setShowScheduleModal(false);
    }

    const data = {
      activityCampaignId: auditCampaign.id,
      workflowActivityStepCompletedId,
      workflowActivityId: activity.workflowActivityId
    };

    dispatch(sendSurveys(data));
  }

  useEffect(() => {
    if(sentSurveys){
      enqueueSnackbar("Surveys sent successfully", {variant: "success"});
      handleRefreshProgress();
      handleClickNext();
    }
  }, [sentSurveys]);

  return(
    <>
      <Row>
        <Col md={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormSelectField
              name="template"
              label="Email template"
              rules={{ required: 'This field is required' }} 
              control={control}
              options={templates}
              keyValue={{label: "label", id: "id"}}
              defaultValue={templates[0].id}
              sx={{width: '200px'}}
              disableClearable
            />
          </Box>
        </Col>

          {isLoadingAssets || isLoadingAuditCampaign || isLoadingSurveys || isLoadingRoles || isLoadingRolesAndUsers ? <CircularProgressWrapper /> :  
          !auditCampaign.campaignAssets || !auditCampaign.campaignAssets.length ? <div style={{height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Typography>There are no assets to show.</Typography></div> :
          
          <>
              {auditCampaign.campaignAssets.map((a, index) => {
                  return(
                      <Col md={4} style={{marginBottom: 30}}>
                          <AssetCard 
                              asset={a}
                              surveys={surveys}
                              supplier={assets.find(s => s.assetId === a.assetId)}
                              rolesAndUsers={rolesAndUsers}
                          />
                      </Col>
                  )
              })}
          </>
          }

          <Col md={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SplitButton 
                options={[
                  {
                    label: "Send"
                  },
                  {
                    label: "Schedule",
                    startIcon: <ScheduleSendIcon />
                  }
                ]}
                onClick={onClickSend}
              />
            </Box>
          </Col>

          { showScheduleModal &&
            <ScheduleSendModal 
              showModal={showScheduleModal}
              onHideModal={onHideScheduleModal}
              onClickSchedule={onClickSchedule}
            />
          }
      </Row>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={isSendingSurveys}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AUCampaignSendSurvey;