import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_AU_SEND_SURVEYS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getAuditCampaignRequest = createAction('AU_SEND_SURVEYS_GET_CAMPAIGN_REQUEST');
export const getAuditCampaignSuccess = createAction('AU_SEND_SURVEYS_GET_CAMPAIGN_SUCCESS');
export const getAuditCampaignError = createAction('AU_SEND_SURVEYS_GET_CAMPAIGN_ERROR');

export const getAuditCampaign = (id) => async (dispatch) => {
  try {
    dispatch(getAuditCampaignRequest());
    const resp = await instance.get(`/v1/ActivityCampaign/GetByActivityId/${id}`);
    
    dispatch(getAuditCampaignSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAuditCampaignError(e.response.data.description));
    else
      dispatch(getAuditCampaignError(e.message));
  }
};

export const getSurveysRequest = createAction('AU_SEND_SURVEYS_GET_SURVEYS_REQUEST');
export const getSurveysSuccess = createAction('AU_SEND_SURVEYS_GET_SURVEYS_SUCCESS');
export const getSurveysError = createAction('AU_SEND_SURVEYS_GET_SURVEYS_ERROR');

export const getSurveys = (data) => async (dispatch) => {
  try {
    dispatch(getSurveysRequest());
    const resp = await instance.post(`/v1/Survey/GetSurveysByIds`, data);

    dispatch(getSurveysSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSurveysError(e.response.data.description));
    else
      dispatch(getSurveysError(e.message));
  }
};

export const getWorkflowRolesRequest = createAction('AU_SEND_SURVEYS_GET_WORKFLOW_ROLES_REQUEST');
export const getWorkflowRolesSuccess = createAction('AU_SEND_SURVEYS_GET_WORKFLOW_ROLES_SUCCESS');
export const getWorkflowRolesError = createAction('AU_SEND_SURVEYS_GET_WORKFLOW_ROLES_ERROR');

export const getWorkflowRoles = (id) => async (dispatch) => {
  try {
    dispatch(getWorkflowRolesRequest());
    const resp = await instance.get(`/v1/Workflow/GetWorkflowRoles?ActivityTypeId=${id}`);
    
    if(resp.data.isSuccessed)
      dispatch(getWorkflowRolesSuccess(resp.data.data));
    else
      dispatch(getWorkflowRolesError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getWorkflowRolesError(e.response.data.description));
    else
      dispatch(getWorkflowRolesError(e.message));
  }
};

export const getAssetsRequest = createAction('AU_SEND_SURVEYS_GET_LIST_REQUEST');
export const getAssetsSuccess = createAction('AU_SEND_SURVEYS_GET_LIST_SUCCESS');
export const getAssetsError = createAction('AU_SEND_SURVEYS_GET_LIST_ERROR');

export const getAssets = () => async (dispatch) => {
  try {
    dispatch(getAssetsRequest());
    const resp = await instance.get(`/v1/Stakeholder/GetTenantStakeholders`);
    
    dispatch(getAssetsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAssetsError(e.response.data.description));
    else
      dispatch(getAssetsError(e.message));
  }
}

export const getRolesAndUsersRequest = createAction('AU_SEND_SURVEYS_GET_USERS_AND_ROLES_REQUEST');
export const getRolesAndUsersSuccess = createAction('AU_SEND_SURVEYS_GET_USERS_AND_ROLES_SUCCESS');
export const getRolesAndUsersError = createAction('AU_SEND_SURVEYS_GET_USERS_AND_ROLES_ERROR');

export const getRolesAndUsers = (users, roles) => async (dispatch) => {
  try {
    dispatch(getRolesAndUsersRequest());
    
    const resp = await instance.post(`/v1/UserAndRole/GetUserAndRoleDataByIds`, { 
      roleIds: roles,
      userIds: users
    });

    dispatch(getRolesAndUsersSuccess(resp.data));
    
  } catch (e) {
    console.log(e);
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRolesAndUsersError(e.response.data.description));
    else
      dispatch(getRolesAndUsersError(e.message));
  }
};

export const sendSurveysRequest = createAction('AU_SEND_SURVEYS_POST_REQUEST');
export const sendSurveysSuccess = createAction('AU_SEND_SURVEYS_POST_SUCCESS');
export const sendSurveysError = createAction('AU_SEND_SURVEYS_POST_ERROR');

export const sendSurveys = (data) => async (dispatch) => {
  try {
    dispatch(sendSurveysRequest());
    const resp = await instance.post(`/v1/ActivityCampaign/SendSurveys`, data);
    
    dispatch(sendSurveysSuccess(resp.data.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(sendSurveysError(e.response.data.description));
    else
      dispatch(sendSurveysError(e.message));
  }
};