import { Box, Drawer } from "@mui/material";
import { useEffect, useRef } from "react";
import Conversation from "./conversation";
import Welcome from "./welcome";
import ConversationInputMessage from "./conversationInputMessage";
import ShowOnStartupCheckbox from "./showOnStartupCheckbox";
import ConversationHeader from "./conversationHeader";

const ChatbotDrawer = ({
    open,
    messages,
    isLoadingWelcomeMessage,
    welcomeMessage,
    onSendMessage,
    isSendingMessage,
    onClickAwake,
    status,
    isAwaking,
    onClickClose,
    onClickReset,
}) => {

    const messageContainerRef = useRef(null);

    useEffect(() => {
        if(messageContainerRef.current && messages && messages.length){
            messageContainerRef.current.scrollTo({ left: 0, top:  messageContainerRef.current.scrollHeight, behavior: "smooth" });
        }
    }, [messages, messageContainerRef]);

    
    return(
        <Drawer
            anchor='right'
            open={open}
            onClose={onClickClose}
            PaperProps={{
                sx:{
                    width: '600px',
                    maxWidth: '100%',
                }
            }}
        >
            <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    p: 2
                }}>
                <ConversationHeader 
                    onClickAwake={onClickAwake} 
                    status={status}
                    isAwaking={isAwaking}
                    onClickClose={onClickClose}
                    onClickReset={onClickReset}
                />
                <Box 
                    ref={messageContainerRef} 
                    sx={{
                        height: 'fill-available',
                        overflow: 'auto',
                        paddingBottom: 1,
                        marginBottom: 1
                    }}>
                    { messages && messages.length ?
                        <Conversation 
                            messages={messages} 
                            isSendingMessage={isSendingMessage}
                        />
                        :
                        <Welcome  
                            isLoadingWelcomeMessage={isLoadingWelcomeMessage}
                            welcomeMessage={welcomeMessage}
                        />
                    }
                </Box>

                <ConversationInputMessage onSendMessage={onSendMessage} />
                <ShowOnStartupCheckbox />
            </Box>
        </Drawer>
    );
};

export default ChatbotDrawer;