import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { Col, Container, Row } from "react-bootstrap";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import CreateBusinessSectorModal from "./createBusinessSectorModal";
import { getNaics, deleteBusinessSector, cleanup } from "@/reducers/supplier/businessSectors/stakeholderBusinessSectorsAction";
import EditBusinessSectorModal from "./editBusinessSectorModal";

const BusinessSectorsList = ({
    asset,
    onChangedBusinessSectors
}) => {
    
    const dispatch = useDispatch();
    const {
        isDeletingBusinessSector, deletedBusinessSector, deleteBusinessSectorError,
        naics, isLoadingNaics, getNaicsError
    } = useSelector(({ stakeholderBusinessSectors }) => stakeholderBusinessSectors);

    const [showBusinessSectorCreateModal, setShowBusinessSectorCreateModal] = useState(false);

    const [showBusinessSectorEditModal, setShowBusinessSectorEditModal] = useState(false);
    const [selectedBusinessSector, setSelectedBusinessSector] = useState(null);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [businessSectorToDelete, setBusinessSectorToDelete] = useState(null);

    useEffect(() => {
        dispatch(getNaics());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getNaicsError){
            enqueueSnackbar(getNaicsError, {variant: "error"});
        }
    }, [getNaicsError]);

    useEffect(() => {
        if(deleteBusinessSectorError){
            enqueueSnackbar(deleteBusinessSectorError, {variant: "error"});
        }
    }, [deleteBusinessSectorError]);

    const handleClickEditBusinessSector = (businessSector) => {
        setSelectedBusinessSector(businessSector);
        setShowBusinessSectorEditModal(true);
    }

    const handleClickDeleteBusinessSector = (businessSector) => {
        setBusinessSectorToDelete(businessSector);
        setOpenConfirmDelete(true);
    }

    const onClickCreateCreateModal = () => {
        setShowBusinessSectorCreateModal(true);
    };

    const onHideBusinessSectorCreateModal = () => {
        setShowBusinessSectorCreateModal(false);
    };

    const onHideBusinessSectorEditModal = () => {
        setShowBusinessSectorEditModal(false);
        setSelectedBusinessSector(null);
    };

    const onBusinessSectorCreated = () => {
        setShowBusinessSectorCreateModal(false);
        onChangedBusinessSectors();
    }
    
    const onBusinessSectorUpdated = () => {
        setShowBusinessSectorEditModal(false);
        setSelectedBusinessSector(null);
        onChangedBusinessSectors();
    }

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);

        var currentBusinessSectors = JSON.parse(JSON.stringify(asset.metadata?.businesssectorInformation?.businessSectors));
        const selectedNaics = currentBusinessSectors.find(n => n.dictionaryNaicsId === businessSectorToDelete.dictionaryNaicsId);
        const selectedIndex = currentBusinessSectors.indexOf(selectedNaics);
        currentBusinessSectors.splice(selectedIndex, 1);
        const finalData = {
          assetId: asset.id,
          source: "businessSectorInformation",
          metadata: {
            businessSectors: currentBusinessSectors
          }
        }; 

        dispatch(deleteBusinessSector(finalData));
        setBusinessSectorToDelete(null);
    }

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setBusinessSectorToDelete(null);
    };

    useEffect(() => {
        if(deletedBusinessSector){
            enqueueSnackbar("Business Sector deleted successfully", {variant: "success"});
            onChangedBusinessSectors();
        }
    }, [deletedBusinessSector]);

    const columns = [
        { 
            field: 'name', 
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
        },
        { 
            field: 'sicDescription', 
            headerName: 'Description', 
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={() => handleClickEditBusinessSector(params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleClickDeleteBusinessSector(params.row)}
                    />
                ];
            },
          },
      ];

  return(
    <Row>
        <Col md={12}>
            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginBottom: 2
            }}>
                <Button variant="contained" color="primary"  onClick={onClickCreateCreateModal} endIcon={<AddIcon/>}>Add Business Sector</Button>
            </Box>

            <CardPageGridWrap>
                <DataGrid 
                    autoHeight
                    density="compact"
                    disableRowSelectionOnClick
                    getRowId={(row) => row.dictionaryNaicsId}
                    rows={asset.metadata?.businesssectorInformation?.businessSectors ?? []}
                    columns={columns} 
                    slots={{ toolbar: GridToolbar }} 
                    slotProps={{
                        toolbar: {
                        showQuickFilter: true
                        },
                    }}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                        py: 1,
                        },
                    }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                />
            </CardPageGridWrap>

            { showBusinessSectorCreateModal &&
                <CreateBusinessSectorModal
                    asset={asset}
                    naics={naics}
                    onHideModal={onHideBusinessSectorCreateModal}
                    open={showBusinessSectorCreateModal}
                    onBusinessSectorCreated={onBusinessSectorCreated}
                />
            }

            { showBusinessSectorEditModal &&
                <EditBusinessSectorModal
                    asset={asset}
                    naics={naics}
                    onHideModal={onHideBusinessSectorEditModal}
                    open={showBusinessSectorEditModal}
                    onBusinessSectorUpdated={onBusinessSectorUpdated}
                    businessSector={selectedBusinessSector}
                />
            }

            { openConfirmDelete &&
                <ConfirmationDialog 
                    open={openConfirmDelete}
                    onConfirm={handleConfirmDelete}
                    onClose={handleOnCloseDelete}
                    title="Delete Business Sector"
                    body={`Are you sure you want to delete ${businessSectorToDelete.name}?`}
                    color='error'
                    confirmButtonText='Delete'
                />
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isDeletingBusinessSector}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Col>
    </Row>
  );
};

export default BusinessSectorsList;