import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateBusinessSectorRequest,
  updateBusinessSectorSuccess,
  updateBusinessSectorError
} from './stakeholderUpdateBusinessSectorAction';

const defaultState = {
  isUpdatingBusinessSector: false,
  updateBusinessSectorError: null,
  updatedBusinessSector: false
};

export default handleActions(
  {
    [updateBusinessSectorRequest](state) {
      return {
        ...state,
        isUpdatingBusinessSector: true,
        updateBusinessSectorError: null,
        updatedBusinessSector: false
      };
    },
    [updateBusinessSectorSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingBusinessSector: false,
        updateBusinessSectorError: null,
        updatedBusinessSector: true
      };
    },
    [updateBusinessSectorError](state, { payload }) {
      return {
        ...state,
        isUpdatingBusinessSector: false,
        updateBusinessSectorError: payload,
        updatedBusinessSector: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);