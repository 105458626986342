import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import ConversationWrapper from './conversationWrapper';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { cleanup, getWelcomeMessage, sendMessage, awake, toggleChatbot, reset } from '@/reducers/chatbot/chatbotAction';
import ChatbotDrawer from './chatbotDrawer';

const Chatbot = () => {

    const dispatch = useDispatch();

    const { 
        show, requestId, status, 
        isAwaking, awakeError,
        messages, isSendingMessage, sendMessageError,
        isLoadingWelcomeMessage, welcomeMessage, getWelcomeMessageError,
    } = useSelector(({ chatbot }) => chatbot);

    useEffect(() => {
        dispatch(getWelcomeMessage());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getWelcomeMessageError){
          enqueueSnackbar(getWelcomeMessageError, {variant: "error"});
        }
    }, [getWelcomeMessageError]);

    useEffect(() => {
        if(sendMessageError){
          enqueueSnackbar(sendMessageError, {variant: "error"});
        }
    }, [sendMessageError]);

    useEffect(() => {
        if(awakeError){
          enqueueSnackbar(awakeError, {variant: "error"});
        }
    }, [awakeError]);

    const onSendMessage = (message, responseLength) => {
        const messagesText = messages.map(m => m.text);
        messagesText.push(message);

        const data = {
            messages: messagesText,
            responseLength
        };

        dispatch(sendMessage(data));
    }

    const onClickAwake = () => {
        const data = {

        };
        dispatch(awake(data));
    };

    const onClickClose = () => {
        dispatch(toggleChatbot());
    }

    const onClickReset = () => {
        dispatch(reset());
    }

    return(
        <ChatbotDrawer 
            open={show}
            messages={messages} 
            isLoadingWelcomeMessage={isLoadingWelcomeMessage}
            welcomeMessage={welcomeMessage}
            onSendMessage={onSendMessage}
            isSendingMessage={isSendingMessage}
            onClickAwake={onClickAwake}
            status={status}
            isAwaking={isAwaking}
            onClickClose={onClickClose}
            onClickReset={onClickReset}
        />
        // show &&
        // <ConversationWrapper 
        //     messages={messages} 
        //     isLoadingWelcomeMessage={isLoadingWelcomeMessage}
        //     welcomeMessage={welcomeMessage}
        //     onSendMessage={onSendMessage}
        //     isSendingMessage={isSendingMessage}
        //     onClickAwake={onClickAwake}
        //     status={status}
        //     isAwaking={isAwaking}
        //     onClickClose={onClickClose}
        //     onClickReset={onClickReset}
        // />
    )
}

export default Chatbot;