import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { addBusinessUnit, cleanup } from "@/reducers/tenantSetup/businessUnits/addBusinessUnit/addBusinessUnitAction";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { FormSelectField } from "@/shared/components/form/material-controls/SelectField";

const CreateBusinessUnitModal = ({
  businessUnits,
  open, 
  onHideModal, 
  onBusinessUnitCreated,
  workflowActivityId,
  workflowActivityStepCompletedId
}) => {
    const dispatch = useDispatch();

    const { 
      isAddingBusinessUnit, addBusinessUnitError, addedBusinessUnit
    } = useSelector(({ tenantSetupAddBusinessUnit }) => tenantSetupAddBusinessUnit);
    
    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
          reset();
          onHideModal();
    }
  
    useEffect(() => {
      if(addBusinessUnitError){
        enqueueSnackbar(addBusinessUnitError, {variant: "error"});
      }
    }, [addBusinessUnitError]);
  
    const onSubmit = (data) => { 
      data.workflowActivityId = workflowActivityId;
      data.workflowActivityStepCompletedId = workflowActivityStepCompletedId;
      dispatch(addBusinessUnit(data));
    }

    useEffect(() => {
      if(addedBusinessUnit){
        enqueueSnackbar("Business Unit added successfully", {variant: "success"});
        handleClose();
        onBusinessUnitCreated();
      }
    }, [addedBusinessUnit]);

    return (
      <Dialog
          fullWidth
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Add Business Unit</DialogTitle>
          <DialogContent>
            <Row>
                <Col md={12}>
                    <FormTextField 
                        name="name" 
                        label="Name" 
                        control={control} 
                        rules={{ required: 'This field is required' }}
                    />
                </Col>
                <Col md={12}>
                    <FormTextField 
                        name="description" 
                        label="Description" 
                        control={control} 
                    />
                </Col>
                <Col md={12}>
                    <FormSelectField 
                        name="businessUnitParentId" 
                        label="Parent Business Unit" 
                        control={control}
                        options={businessUnits}
                        keyValue={{id: "businessUnitId", label: "name"}}
                    />
                </Col>
            </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Add</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isAddingBusinessUnit}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default CreateBusinessUnitModal;