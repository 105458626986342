import { Backdrop, Button, CircularProgress } from "@mui/material"; 
import { cleanup, getBusinessUnits, deleteBusinessUnit } from "@/reducers/businessUnits/businessUnitsAction";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { enqueueSnackbar } from "notistack";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';

const BusinessUnits = () => {

    const dispatch = useDispatch();

    const { 
        businessUnits, isLoadingBusinessUnits, getBusinessUnitsError,
        isDeletingBusinessUnit, deletedBusinessUnit, deleteBusinessUnitError,
    } = useSelector(({ businessUnits }) => businessUnits);

    const [selectedRows, setSelectedRows] = useState([]);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [businessUnitToDelete, setBusinessUnitToDelete] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getBusinessUnits());
   
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
      if(getBusinessUnitsError){
          enqueueSnackbar(getBusinessUnitsError, {variant: "error"});
      }
    }, [getBusinessUnitsError]);

    useEffect(() => {
      if(deleteBusinessUnitError){
          enqueueSnackbar(deleteBusinessUnitError, {variant: "error"});
      }
    }, [deleteBusinessUnitError]);

    const handleClickDeleteBusinessUnit = (businessUnit) => {
      setBusinessUnitToDelete(businessUnit);
      setOpenConfirmDelete(true);
    }

    const handleConfirmDelete = () => {
      setOpenConfirmDelete(false);
      dispatch(deleteBusinessUnit(businessUnitToDelete.businessUnitId));
      setBusinessUnitToDelete(null);
    } 

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setBusinessUnitToDelete(null);
    };

    useEffect(() => {
      if(deletedBusinessUnit){
          enqueueSnackbar("Business Unit deleted successfully", {variant: "success"});
          dispatch(getBusinessUnits());
      }
    }, [deletedBusinessUnit]);

    const handleClickAddBusinessUnit = () => {
      navigate("Create");
    };

    const handleClickEditBusinessUnit = (businessUnit) => {
      navigate("Update/" + businessUnit.businessUnitId);
    };

    const columns = [
      { 
          field: 'name',
          headerName: 'Name',
          minWidth: 250,
          flex: 0.5,
      },
      { 
          field: 'description',
          headerName: 'Description', 
          minWidth: 350,
          flex: 1,
      },
      {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => {
              if(params.row.isSystem){
                  return [];
              }

              return [
                  <GridActionsCellItem
                      icon={<EditIcon />}
                      label="Edit"
                     onClick={() => handleClickEditBusinessUnit(params.row)}
                  />,
                  <GridActionsCellItem
                      icon={<DeleteIcon />}
                      label="Delete"
                      onClick={() => handleClickDeleteBusinessUnit(params.row)}
                  />
              ];
          },
        },
    ];

    return(
      <Container>
        <CardPage>
          <CardPageBody>
              <CardPageHeader>
                <CardPageTitleWrap>
                  <CardPageTitle>Business Units</CardPageTitle>
                  <CardPageSubhead>
                    Here you can view your Business Units.
                  </CardPageSubhead>
                </CardPageTitleWrap>
                <CardPageButtonWrap>
                  <Button variant="contained" color="primary" endIcon={<AddIcon/>} onClick={handleClickAddBusinessUnit} >Add Business Unit</Button>
                </CardPageButtonWrap>
              </CardPageHeader>
              <CardPageGridWrap>
              <DataGrid 
                  autoHeight
                  checkboxSelection 
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedRows(newRowSelectionModel);
                  }}
                  rowSelectionModel={selectedRows}
                  loading={isLoadingBusinessUnits} 
                  rows={businessUnits}
                  getRowId={(row) => row.businessUnitId}
                  columns={columns} 
                  slots={{ toolbar: GridToolbar }} 
                  slotProps={{
                      toolbar: {
                      showQuickFilter: true
                      },
                  }}
                  sx={{
                      [`& .${gridClasses.cell}`]: {
                      py: 1,
                      },
                  }}
                  initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 25, 50]}
              />
              </CardPageGridWrap>
          </CardPageBody>
        </CardPage>

        { openConfirmDelete &&
          <ConfirmationDialog 
              open={openConfirmDelete}
              onConfirm={handleConfirmDelete}
              onClose={handleOnCloseDelete}
              title="Delete Business Unit"
              body={`Are you sure you want to delete ${businessUnitToDelete.name}?`}
              color='error'
              confirmButtonText='Delete'
          />
          }

        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} 
          open={isDeletingBusinessUnit}>
            <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    )
}

export default BusinessUnits;