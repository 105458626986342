import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Card, CardContent, Typography } from '@mui/material';
import { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';

const MostCommonEntities = ({
    isLoading,
    data,
    onSelectEntity
}) => {
    const {theme} = useSelector(({changeTheme}) => changeTheme);

    const options = useMemo(() => {
        if(data){
            const trends = JSON.parse(JSON.stringify(data.trends)).reverse();

            const positives = [];
            const negatives = [];
            const neutrals = [];

            const positivesPercent = [];
            const negativesPercent = [];
            const neutralsPercent = [];

            trends.forEach((t) => {
                positives.push(t.sentiment.positive);
                negatives.push(t.sentiment.negative);
                neutrals.push(t.sentiment.neutral);

                positivesPercent.push(t.sentiment.positive / t.count);
                negativesPercent.push(t.sentiment.negative / t.count);
                neutralsPercent.push(t.sentiment.neutral / t.count);
            });

            return {
                title: {
                    text: "Most Common Entities"
                },
                legend: {},
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: {
                  type: 'value',
                  show: false
                },
                yAxis: {
                  type: 'category',
                  data: trends.map(t => t.value),
                  axisLabel: {
                    formatter: (name, dataIndex) => {
                        return name + " (" + trends[dataIndex].count + ")";
                    }
                  }
                },
                series: [
                  {
                    name: 'Positive',
                    type: 'bar',
                    stack: 'total',
                    label: {
                      show: true,
                      formatter: (params) => positives[params.dataIndex]
                    },
                    emphasis: {
                      focus: 'series'
                    },
                    data: positivesPercent,
                    itemStyle: { color: "#00C3A3"}
                  },
                  {
                    name: 'Neutral',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        formatter: (params) => neutrals[params.dataIndex]
                      },
                    emphasis: {
                      focus: 'series'
                    },
                    data: neutralsPercent,
                    itemStyle: { color: "#B1B1B1"}
                  },
                  {
                    name: 'Negative',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        formatter: (params) => negatives[params.dataIndex]
                      },
                    emphasis: {
                      focus: 'series'
                    },
                    data: negativesPercent,
                    itemStyle: { color: "#DD142E"}
                  }
                ]
              };
        }
    }, [data]);

    const onClick = (params) => {
        onSelectEntity(params.name);
    };

    return(
        
        <Card sx={{
            padding: 2
        }}>
            {isLoading || !data ? <CircularProgressWrapper height={400} /> :
                <ReactECharts
                    theme={theme}
                    style={{height: 400}}
                    notMerge={true}
                    option={options}
                    onEvents={{
                        click: onClick
                    }}
                />
            }
        </Card>
    );
};

export default MostCommonEntities;