import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateBusinessSector, cleanup } from "@/reducers/supplier/businessSectors/updateBusinessSector/stakeholderUpdateBusinessSectorAction";
import BusinessSectorSelection from "./businessSectorSelection";

const EditBusinessSectorModal = ({
  asset,
  naics, 
  businessSector,
  open, 
  onHideModal, 
  onBusinessSectorUpdated 
}) => {
    const dispatch = useDispatch();

    const { 
      isUpdatingBusinessSector, updateBusinessSectorError, updatedBusinessSector,
    } = useSelector(({ stakeholderUpdateBusinessSector }) => stakeholderUpdateBusinessSector);
    
    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
          reset();
          onHideModal();
    }
  
    useEffect(() => {
      if(updateBusinessSectorError){
        enqueueSnackbar(updateBusinessSectorError, {variant: "error"});
      }
    }, [updateBusinessSectorError]);
  
    const onSubmit = (data) => { 
      const selectedNaics = naics.find(n => n.dictionaryNaicsId === data.dictionaryNaicsId);
      var currentBusinessSectors = JSON.parse(JSON.stringify(asset.metadata?.businesssectorInformation?.businessSectors));

      const prevBusinessSector = currentBusinessSectors.find(b => b.dictionaryNaicsId === businessSector.dictionaryNaicsId);
      const index = currentBusinessSectors.indexOf(prevBusinessSector);
      currentBusinessSectors[index] = selectedNaics;

      const finalData = {
        assetId: asset.id,
        source: "businessSectorInformation",
        metadata: {
          businessSectors: currentBusinessSectors
        }
      }; 

      dispatch(updateBusinessSector(finalData));
    }

    useEffect(() => {
      if(updatedBusinessSector){
        enqueueSnackbar("Business Sector updated successfully", {variant: "success"});
        handleClose();
        onBusinessSectorUpdated();
      }
    }, [updatedBusinessSector]);

    return (
      <Dialog
          fullWidth
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Edit Business Sector</DialogTitle>
          <DialogContent>
            <BusinessSectorSelection 
              control={control}
              naics={naics}
              businessSector={businessSector}
            />
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Update</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingBusinessSector}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default EditBusinessSectorModal;