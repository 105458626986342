import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addBusinessSectorRequest,
  addBusinessSectorSuccess,
  addBusinessSectorError
} from './stakeholderAddBusinessSectorAction';

const defaultState = {
  isAddingBusinessSector: false,
  addBusinessSectorError: null,
  addedBusinessSector: false
};

export default handleActions(
  {
    [addBusinessSectorRequest](state) {
      return {
        ...state,
        isAddingBusinessSector: true,
        addBusinessSectorError: null,
        addedBusinessSector: false
      };
    },
    [addBusinessSectorSuccess](state, { payload }) {
      return {
        ...state,
        isAddingBusinessSector: false,
        addBusinessSectorError: null,
        addedBusinessSector: true
      };
    },
    [addBusinessSectorError](state, { payload }) {
      return {
        ...state,
        isAddingBusinessSector: false,
        addBusinessSectorError: payload,
        addedBusinessSector: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);