import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { Backdrop, Box, Button, CircularProgress, Divider, Grid, Typography, gridClasses } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { cleanup, getBusinessUnits, deleteBusinessUnit } from "@/reducers/businessUnits/businessUnitsAction";
import CreateBusinessUnitModal from "./components/createBusinessUnitModal";
import EditBusinessUnitModal from "./components/editBusinessUnitModal";

const TenantBusinessUnits = () => {
  
  const { 
    activity, 
    workflowActivityStepCompletedId, 
    isEditor,
    handleRefreshProgress,
    activityType,
    handleClickNext
} = useOutletContext();

  const dispatch = useDispatch();

  const { 
    businessUnits, isLoadingBusinessUnits, getBusinessUnitsError,
    isDeletingBusinessUnit, deletedBusinessUnit, deleteBusinessUnitError,
} = useSelector(({ businessUnits }) => businessUnits);

const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
const [businessUnitToDelete, setBusinessUnitToDelete] = useState(null);

const [showBusinessUnitCreateModal, setShowBusinessUnitCreateModal] = useState(false);

const [showBusinessUnitEditModal, setShowBusinessUnitEditModal] = useState(false);
const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);

const navigate = useNavigate();

useEffect(() => {
    dispatch(getBusinessUnits());

    return () => {
      dispatch(cleanup());
    }
}, [dispatch]);

useEffect(() => {
  if(getBusinessUnitsError){
      enqueueSnackbar(getBusinessUnitsError, {variant: "error"});
  }
}, [getBusinessUnitsError]);

useEffect(() => {
  if(deleteBusinessUnitError){
      enqueueSnackbar(deleteBusinessUnitError, {variant: "error"});
  }
}, [deleteBusinessUnitError]);

const handleClickDeleteBusinessUnit = (businessUnit) => {
  setBusinessUnitToDelete(businessUnit);
  setOpenConfirmDelete(true);
}

const handleConfirmDelete = () => {
  setOpenConfirmDelete(false);
  dispatch(deleteBusinessUnit(businessUnitToDelete.businessUnitId));
  setBusinessUnitToDelete(null);
} 

const handleOnCloseDelete = () => {
    setOpenConfirmDelete(false);
    setBusinessUnitToDelete(null);
};

useEffect(() => {
  if(deletedBusinessUnit){
      enqueueSnackbar("Business Unit deleted successfully", {variant: "success"});
      dispatch(getBusinessUnits());
  }
}, [deletedBusinessUnit]);

const handleClickEditBusinessUnit = (businessUnit) => {
  setSelectedBusinessUnit(businessUnit);
  setShowBusinessUnitEditModal(true);
}

const handleClickAddBusinessUnit = () => {
  setShowBusinessUnitCreateModal(true);
};

const onHideBusinessUnitCreateModal = () => {
  setShowBusinessUnitCreateModal(false);
};

const onHideBusinessUnitEditModal = () => {
  setShowBusinessUnitEditModal(false);
  setSelectedBusinessUnit(null);
};

const onBusinessUnitCreated = () => {
  setShowBusinessUnitCreateModal(false);
  handleRefreshProgress();
  dispatch(getBusinessUnits());
}

const onBusinessUnitUpdated = () => {
  setShowBusinessUnitEditModal(false);
  setSelectedBusinessUnit(null);
  dispatch(getBusinessUnits());
}

const columns = [
  { 
      field: 'name',
      headerName: 'Name',
      minWidth: 250,
      flex: 0.5,
  },
  { 
      field: 'description',
      headerName: 'Description', 
      minWidth: 350,
      flex: 1,
  },
  {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => {
          if(params.row.isSystem){
              return [];
          }

          return [
              <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                 onClick={() => handleClickEditBusinessUnit(params.row)}
              />,
              <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={() => handleClickDeleteBusinessUnit(params.row)}
              />
          ];
      },
    },
];

  return (
      <>
        <Row>
          <Col md={12}>
            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginBottom: 2
            }}>
                <Button variant="contained" color="primary"  onClick={handleClickAddBusinessUnit} endIcon={<AddIcon/>}>Add Business Unit</Button>
            </Box>

            <CardPageGridWrap>
              <DataGrid 
                autoHeight
                disableRowSelectionOnClick
                loading={isLoadingBusinessUnits} 
                rows={businessUnits}
                getRowId={(row) => row.businessUnitId}
                columns={columns} 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{
                    toolbar: {
                    showQuickFilter: true
                    },
                }}
                sx={{
                  [`& .${gridClasses.cell}`]: {
                    py: 1,
                  },
                }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
              />
            </CardPageGridWrap>

          </Col>
        </Row>

        { showBusinessUnitCreateModal &&
            <CreateBusinessUnitModal
              businessUnits={businessUnits}
              onHideModal={onHideBusinessUnitCreateModal}
              open={showBusinessUnitCreateModal}
              onBusinessUnitCreated={onBusinessUnitCreated}
              workflowActivityId={activity.workflowActivityId}
              workflowActivityStepCompletedId={workflowActivityStepCompletedId}
            />
        }

        { showBusinessUnitEditModal &&
            <EditBusinessUnitModal
              businessUnits={businessUnits}
              onHideModal={onHideBusinessUnitEditModal}
              open={showBusinessUnitEditModal}
              onBusinessUnitUpdated={onBusinessUnitUpdated}
              businessUnit={selectedBusinessUnit}
            />
        }

        { openConfirmDelete &&
          <ConfirmationDialog 
              open={openConfirmDelete}
              onConfirm={handleConfirmDelete}
              onClose={handleOnCloseDelete}
              title="Delete Business Unit"
              body={`Are you sure you want to delete ${businessUnitToDelete.name}?`}
              color='error'
              confirmButtonText='Delete'
          />
          }

        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} 
          open={isDeletingBusinessUnit}>
            <CircularProgress color="inherit" />
        </Backdrop>
      </>
  );
};

export default TenantBusinessUnits;