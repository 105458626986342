import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_UPDATE_BUSINESS_UNIT');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getBusinessUnitRequest = createAction('UPDATE_BUSINESS_UNIT_GET_REQUEST');
export const getBusinessUnitSuccess = createAction('UPDATE_BUSINESS_UNIT_GET_SUCCESS');
export const getBusinessUnitError = createAction('UPDATE_BUSINESS_UNIT_GET_ERROR');

export const getBusinessUnit = (id) => async (dispatch) => {
  try {
    dispatch(getBusinessUnitRequest());
    const resp = await instance.get(`/v1/BusinessUnit/GetBusinessUnit/${id}`);

    dispatch(getBusinessUnitSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getBusinessUnitError(e.response.data.description));
    else
      dispatch(getBusinessUnitError(e.message));
  }
};

export const updateBusinessUnitRequest = createAction('UPDATE_BUSINESS_UNIT_REQUEST');
export const updateBusinessUnitSuccess = createAction('UPDATE_BUSINESS_UNIT_SUCCESS');
export const updateBusinessUnitError = createAction('UPDATE_BUSINESS_UNIT_ERROR');

export const updateBusinessUnit = (request) => async (dispatch) => {
  try {
    dispatch(updateBusinessUnitRequest());
    const resp = await instance.put(`/v1/BusinessUnit/Put`, request);

    dispatch(updateBusinessUnitSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateBusinessUnitError(e.response.data.description));
    else
      dispatch(updateBusinessUnitError(e.message));
  }
};

export const getBusinessUnitsRequest = createAction('UPDATE_BUSINESS_UNIT_GET_STAKEHOLDER_TYPES_REQUEST');
export const getBusinessUnitsSuccess = createAction('UPDATE_BUSINESS_UNIT_GET_STAKEHOLDER_TYPES_SUCCESS');
export const getBusinessUnitsError = createAction('UPDATE_BUSINESS_UNIT_GET_STAKEHOLDER_TYPES_ERROR');

export const getBusinessUnits = () => async (dispatch) => {
  try {
    dispatch(getBusinessUnitsRequest());
    const resp = await instance.get(`/v1/BusinessUnit/GetListByFilter`);

    dispatch(getBusinessUnitsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getBusinessUnitsError(e.response.data.description));
    else
      dispatch(getBusinessUnitsError(e.message));
  }
};