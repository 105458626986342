import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { FormSelectField } from "@/shared/components/form/material-controls/SelectField";
import { updateBusinessUnit, cleanup } from "@/reducers/businessUnits/updateBusinessUnit/updateBusinessUnitAction";

const EditBusinessUnitModal = ({
  businessUnit,
  businessUnits,
  open, 
  onHideModal, 
  onBusinessUnitUpdated 
}) => {
    const dispatch = useDispatch();

    const { 
      isUpdatingBusinessUnit, updatedBusinessUnit, updateBusinessUnitError
    } = useSelector(({ updateBusinessUnit }) => updateBusinessUnit);
    
    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
          reset();
          onHideModal();
    }
  
    useEffect(() => {
      if(updateBusinessUnitError){
        enqueueSnackbar(updateBusinessUnitError, {variant: "error"});
      }
    }, [updateBusinessUnitError]);
  
    const onSubmit = (data) => { 
      data.businessUnitId = businessUnit.businessUnitId;
      dispatch(updateBusinessUnit(data));
    }

    useEffect(() => {
      if(updatedBusinessUnit){
        enqueueSnackbar("Business Unit updated successfully", {variant: "success"});
        handleClose();
        onBusinessUnitUpdated();
      }
    }, [updatedBusinessUnit]);

    return (
      <Dialog
          fullWidth
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Edit Business Unit</DialogTitle>
          <DialogContent>
            <Row>
                <Col md={12}>
                    <FormTextField 
                        name="name" 
                        label="Name" 
                        control={control} 
                        rules={{ required: 'This field is required' }}
                        defaultValue={businessUnit?.name}
                    />
                </Col>
                <Col md={12}>
                    <FormTextField 
                        name="description" 
                        label="Description" 
                        control={control} 
                        defaultValue={businessUnit?.description}
                    />
                </Col>
                <Col md={12}>
                    <FormSelectField 
                        name="businessUnitParentId" 
                        label="Parent Business Unit" 
                        control={control}
                        options={businessUnits}
                        keyValue={{id: "businessUnitId", label: "name"}}
                        defaultValue={businessUnit?.businessUnitParentId}
                    />
                </Col>
            </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Update</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingBusinessUnit}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default EditBusinessUnitModal;