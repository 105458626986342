import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  completeRequest,
  completeSuccess,
  completeError
} from './stakeholderInformationSurveyAction';

const defaultState = {
  isCompleting: false,
  completedResult: null,
  completeError: null
};

export default handleActions(
  {
    [completeRequest](state) {
      return {
        ...state,
        isCompleting: true,
        completedResult: null,
        completeError: null
      };
    },
    [completeSuccess](state, { payload }) {
      return {
        ...state,
        isCompleting: false,
        completedResult: payload,
        completeError: null
      };
    },
    [completeError](state, { payload }) {
      return {
        ...state,
        isCompleting: false,
        completedResult: null,
        completeError: payload
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);