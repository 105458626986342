import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorIcon from '@mui/icons-material/Error';
import TextWritter from "../../../shared/components/TextWritter";
import { useDispatch } from "react-redux";
import { finishWriting } from "../../../reducers/chatbot/chatbotAction";

const Message = ({
    message,
    index,
    isMine,
    hasAvatar,
    isLoading
}) => {
    
    const messageProperties = isMine ? 
        { marginRight: '10px', justifyContent: 'right' } : 
        { marginLeft: hasAvatar ? '10px' : '60px', justifyContent: 'left' };

    const borderRadiusProperties = isMine ?
        { borderRadius: '20px 20px 20px 20px'} :
        { borderRadius: '20px 20px 20px 20px'};

    const dispatch = useDispatch();

    const onFinish = () => {
        dispatch(finishWriting(index));
    };

    return (
        <Box
            sx={{
                marginBottom: 1,
                display: 'flex',
                width: '100%',
                ...messageProperties
            }}
        >
            <Box 
                sx={{
                    bgcolor: isMine ? 'chatbot.userMessageBackground.main' : 'chatbot.botMessageBackground.main',
                    padding: '10px',
                    ...borderRadiusProperties,
                    maxWidth: '90%',
                    textAlign: isMine ? 'right' : 'left'
                }}>
                { isLoading ? 
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100px'
                    }}>
                        <CircularProgress
                            color="primary"
                            variant="indeterminate"
                        />
                    </Box>
                :
                    <>
                        <Box sx={{
                            display: 'flex'
                        }}>
                            { message.alertIcon && 
                            <>
                                <>
                                    { message.alertIcon === "info" ?
                                        <InfoIcon color="info" sx={{fontSize: '40px'}} />
                                    :
                                        message.alertIcon === "warning" ? 
                                        <WarningAmberIcon color="warning" sx={{fontSize: '40px'}} />
                                    :
                                        <ErrorIcon color="error" sx={{fontSize: '40px'}} />
                                    }
                                </>

                                <Divider 
                                    orientation="vertical" 
                                    component="div" 
                                    flexItem
                                    sx={{ marginInline: 1 }} 
                                />
                            </>
                            }

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                { isMine || message.finished ? 
                                    <Typography 
                                        color='text.primary'
                                        component="pre"
                                        sx={{
                                            fontSize: '18px',
                                            textAlign: 'left',
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word"
                                        }}>
                                            {message.text}
                                    </Typography>
                                :
                                    <TextWritter
                                        onFinish={onFinish}
                                        text={message.text}
                                        color='text.primary'
                                        component="pre"
                                        sx={{
                                            fontSize: '18px',
                                            textAlign: 'left',
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word"
                                        }}>
                                    </TextWritter>
                                }
                                <Typography 
                                    color='text.secondary'
                                    sx={{
                                        fontSize: '12px'
                                    }}
                                >
                                    {dayjs(message.sendDate).format("DD/MM/YYYY HH:mm:ss")}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                }
            </Box>
        </Box>
    );
}

export default Message;