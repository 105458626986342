import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getClassifications } from "@/reducers/organizations/organizationAction";
import { Col, Container, Row } from "react-bootstrap";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import MaterialAssetInfoModal from "../../AssetManagement/AssetInventory/components/materialAssetInfoModal";
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";

const Organizations = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { 
      classifications, isLoadingClassifications, getClassificationsError
    } = useSelector(({ organizations }) => organizations);

    const { 
      currentTenant, isLoadingCurrentTenant, getCurrentTenantError
    } = useSelector(({ currentTenant }) => currentTenant);
    
    const [showInfoModal, setShowInfoModal] = useState({show: false, assetId: null});
    const [organizations, setOrganizations] = useState([]);

    useEffect(() => {
        dispatch(getClassifications());

        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
      if(currentTenant){
        var tenant = {...currentTenant};
        tenant.id = tenant.tenantId;
        setOrganizations([tenant]);
      }
    }, [currentTenant]);

    useEffect(() => {
      if(getCurrentTenantError){
          enqueueSnackbar(getCurrentTenantError, {variant: "error"});
      }
  }, [getCurrentTenantError]);

    useEffect(() => {
        if(getClassificationsError){
            enqueueSnackbar(getClassificationsError, {variant: "error"});
        }
    }, [getClassificationsError]);

    const onHideInfoModal = () => {
      setShowInfoModal({show: false, assetId: null});
    };
  
    const openInfoModal = (selectedOrganization) => {
      setShowInfoModal({show: true, assetId: selectedOrganization.assetId});
    };

    const handleClickEdit = (selectedOrganization) => {
      navigate("Update");
    };

    const columns = [
        { 
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'emailAddress', 
            headerName: 'Email', 
            minWidth: 300,
            flex: 1
        },
        { 
            field: 'billingName', 
            headerName: 'Billing Name', 
            minWidth: 200,
            flex: 0.5
        },        
        { 
            field: 'billingAddress', 
            headerName: 'Billing Address', 
            minWidth: 300,
            flex: 1
        },
        { 
            field: 'billingCity', 
            headerName: 'Billing City', 
            minWidth: 200,
            flex: 0.5
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<SearchIcon />}
                    label="View"
                    showInMenu
                    onClick={() => openInfoModal(params.row)}
                />,
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  showInMenu
                  onClick={() => handleClickEdit(params.row)}
                />
            ],
          },
      ];

    return(
        <Container>
            <CardPage>
                <CardPageBody>   
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Organizations</CardPageTitle>
                            <CardPageSubhead>
                            Here you can view all the Organizations.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                    </CardPageHeader>

                    <CardPageGridWrap>
                        <DataGrid 
                          autoHeight
                          density="compact"
                          disableRowSelectionOnClick
                          loading={isLoadingCurrentTenant} 
                          rows={organizations}
                          columns={columns} 
                          slots={{ toolbar: GridToolbar }}
                          sx={{
                              [`& .${gridClasses.cell}`]: {
                              py: 1,
                              },
                          }}
                          initialState={{
                              pagination: { paginationModel: { pageSize: 30 } },
                          }}
                          pageSizeOptions={[5, 15, 30, 50, 100]}
                          rowCount={1}
                        />
                    </CardPageGridWrap>

                  { showInfoModal.show &&
                    <MaterialAssetInfoModal 
                    onHideModal={onHideInfoModal} 
                    showModal={showInfoModal.show}
                    assetId={showInfoModal.assetId}
                    classifications={classifications}
                    />
                  }

                  <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={isLoadingClassifications || isLoadingCurrentTenant}
                      >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default Organizations;