import { Provider, useSelector } from "react-redux";
import store from "./config/store";
import "bootstrap/dist/css/bootstrap.min.css";
import NotificationStyles from "./styles/notificationStyles";
import CalendarStyles from "./styles/calendarStyles";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { PropTypes } from "prop-types";
import Routers from "./routers/routers";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { Button } from "@mui/material";
import 'material-icons/iconfont/material-icons.css';
import { registerEchartsThemes } from "./styles/echartsThemes";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserProfile, getAvatar, getUserOptions, cleanup as cleanupUserInfo } from "./reducers/account/userInfo/userInfoAction"
import { getCurrentTenant, getLogo, cleanup as cleanupTenantInfo } from "./reducers/currentTenant/currentTenantAction";
import GlobalStylesMaterial from "./styles/globalStyles";
import { getModeDependantColors } from "./styles/materialStyles";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_it from "./translations/it/common.json";
import common_en from "./translations/en/common.json";
import { supportedLanguages } from "./utils/supportedLanguages";

const ThemeComponent = ({ children }) => {
  const { direction, shadow, border } = {
    direction: "ltr",
    shadow: "on",
    border: "off",
  };

  const { mode } = useSelector(({ changeTheme }) => ({
    mode: changeTheme.theme,
  }));

  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);

  const dispatch = useDispatch();

  useEffect(() => {
    if(isLoggedIn){
      dispatch(getUserProfile());
      dispatch(getAvatar());
      dispatch(getUserOptions());

      dispatch(getCurrentTenant());
      dispatch(getLogo());
    }
    else{
      dispatch(cleanupUserInfo());
      dispatch(cleanupTenantInfo());
    }
  }, [isLoggedIn]);

  const modeDependantColors = getModeDependantColors(mode);

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        // main: 'rgba(85, 171, 250, 1)',
        main: '#196edd',
        
      },
      ...modeDependantColors
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    }
  });

  registerEchartsThemes();

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} action={(snackbarId) => (
        <Button sx={{ color: 'text.primary' }} onClick={() => closeSnackbar(snackbarId)}>
          Dismiss
        </Button>
      )}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider
            theme={{
              mode,
              direction,
              shadow,
              border,
            }}
          >
            {/* <GlobalStyles /> */}
            <GlobalStylesMaterial />
            <NotificationStyles />
            <CalendarStyles />
            {children}
          </ThemeProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const ConnectedThemeComponent = ThemeComponent;

function App() {
  var currentLanguage = localStorage.getItem("language");
  if(!currentLanguage){
    var newLanguage = navigator.language.split('-')[0];

    if(!supportedLanguages.includes(newLanguage)){
      newLanguage = supportedLanguages[0];
    }

    currentLanguage = newLanguage;
    localStorage.setItem("language", currentLanguage);
  }
  
  i18next.init({
    interpolation: { escapeValue: false },
    lng: currentLanguage,
    resources: {
        en: {
            common: common_en
        },
        it: {
            common: common_it
        }
    },
  });

  return (
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <BrowserRouter basename='/'>
          <ConnectedThemeComponent>
            <Routers />
          </ConnectedThemeComponent>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  );
}

export default App;
