import DoughnutChart from "@/shared/components/charts/DoughnutChart";
import { ratingScores } from "@/utils/ratingScores";

const CampaignRatingScoreChart = ({ data }) => {
  const grouppedSurveys = Object.groupBy(data.assetCalculationDto, ({ surveyName }) => surveyName);
  const groupKeys = Object.keys(grouppedSurveys);

  const chartSeries ={
    series: []
  };

  groupKeys.forEach((k) => {
    var sum = 0;
    grouppedSurveys[k].forEach(g => {
      sum += g.value;
    });

    sum /= grouppedSurveys[k].length;

    const ratingScoreValue = Math.round(sum);
    var ratingScore = ratingScores.find(r => r.value === ratingScoreValue);

    chartSeries.series.push({
      total: grouppedSurveys[k].length,
      groupName: k,
      color: ratingScore.color
    });
  });

  return(
    <DoughnutChart 
      chartSeries={chartSeries}
      height={500} 
      legend={{
          orient: 'vertical',
          left: 'left'
      }}
      title={{
          text: "Campaign Rating Score",
          left: 'center'
      }}
    />
  );
};

export default CampaignRatingScoreChart;