import { createAction } from 'redux-actions';
import { instance } from "@/config/interceptor";

export const cleanupAction = createAction('CLEANUP_TENANT_SETUP');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateTenantRequest = createAction('TENANT_SETUP_UPDATE_REQUEST');
export const updateTenantSuccess = createAction('TENANT_SETUP_UPDATE_SUCCESS');
export const updateTenantError = createAction('TENANT_SETUP_UPDATE_ERROR');

export const updateTenant = (data) => async (dispatch) => {
  try {
    dispatch(updateTenantRequest());
    const resp = await instance.put(`/v1/TenantSetup/UpdateGeneralInformation`, data);
    
    dispatch(updateTenantSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateTenantError(e.response.data.description));
    else
      dispatch(updateTenantError(e.message));
  }
};