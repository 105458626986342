import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSurveys, updateSurveyProgress, cleanup } from "@/reducers/selfAssessment/surveyList/surveyListAction";
import { useNavigate, useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { CircularProgressWrapper, FlexCenteredText } from '@/shared/components/CircularProgressWrapper';
import { Divider, Typography } from "@mui/material";
import SurveyInfoCard from "./components/surveyInfoCard";
import RenderSurvey from "./components/survey/renderSurvey";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import CustomSurveyComponent from "./components/customSurveyComponent";

const SelfAssessmentSurvey = () => {
  
  const { 
    activity, 
    workflowActivityStepCompletedId,
    isEditor,
    handleRefreshProgress,
  } = useOutletContext();

  const dispatch = useDispatch();
  const { 
    surveys, isLoadingSurveys, getSurveysError
  } = useSelector(({ surveyList }) => surveyList);

  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [selectedSurveyConfirm, setSelectedSurveyConfirm] = useState(null);

  const [openConfirmChange, setOpenConfirmChange] = useState(false);

  useEffect(() => {
      dispatch(getSurveys(activity.workflowActivityId));

      return () => {
          dispatch(cleanup());
      }
  }, [dispatch]);

  useEffect(() => {
      if(getSurveysError){
        enqueueSnackbar(getSurveysError, {variant: "error"});
      }
  }, [getSurveysError]);

  const onClickSurvey = (survey) => {
    if(selectedSurvey && selectedSurvey.percentCompleted < 1){
      setSelectedSurveyConfirm(survey);
      setOpenConfirmChange(true);
    }
    else{
      setSelectedSurvey(survey);
    }
  }

  const onSurveyCompleted = (surveyId, saveResult) => {
    setSelectedSurvey(null);
    handleRefreshProgress();

    dispatch(updateSurveyProgress(surveyId, saveResult));
  }

  const onSurveySaved = (surveyId, saveResult) => {
    handleRefreshProgress();
    dispatch(updateSurveyProgress(surveyId, saveResult));
  }

  const handleConfirmChange = () => {
    setOpenConfirmChange(false);
    setSelectedSurvey(selectedSurveyConfirm);
  }

  const handleOnCloseChange = () => {
    setOpenConfirmChange(false);
  };

  return (
      <>
        <Row>
            {isLoadingSurveys ? <CircularProgressWrapper height={200} /> :  
              !surveys || (surveys.resultSurveys && !surveys.resultSurveys.length) ? <FlexCenteredText>There are no surveys to show.</FlexCenteredText> :
              surveys.resultSurveys.map((s, index) => {
                    return(
                      <Col md={4} style={{marginBottom: 30}}>
                        <SurveyInfoCard 
                            activityType={activity.activityType}
                            survey={s}
                            onClickSurvey={() => onClickSurvey(s)}
                            showCalculation={activity.isSupervisor  && !!s.calculation}
                        />
                      </Col>
                    )
                })
            }
        </Row>
        <Divider component='div'/>
        <Row>
          <Col md={12}>
            { !selectedSurvey ? <FlexCenteredText>Select a survey to see the questions.</FlexCenteredText> :
              selectedSurvey.isCustom ? 
                <CustomSurveyComponent 
                  activitySurvey={surveys}
                  surveyInfo={selectedSurvey}
                  activitySurveyId={surveys.id}
                  isSupervisor={activity.isSupervisor}
                  workflowActivityStepCompletedId={workflowActivityStepCompletedId}
                  onComplete={onSurveyCompleted}
                /> 
                : 
                <RenderSurvey 
                  surveyInfo={selectedSurvey}
                  activitySurveyId={surveys.id}
                  isSupervisor={activity.isSupervisor}
                  isEditor={isEditor}
                  onSurveyCompleted={onSurveyCompleted}
                  onSurveySaved={onSurveySaved}
                  workflowActivityStepCompletedId={workflowActivityStepCompletedId}
                />
            }
          </Col>
        </Row>
        
        {openConfirmChange &&
            <ConfirmationDialog 
                open={openConfirmChange}
                onConfirm={handleConfirmChange}
                onClose={handleOnCloseChange}
                title="Change Survey"
                body={`Are you sure you want to change survey? All unsaved changes will be lost.`}
                color='error'
                confirmButtonText='Confirm'
            />
        }
      </>
  );
};

export default SelfAssessmentSurvey;