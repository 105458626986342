import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addBusinessUnitRequest,
  addBusinessUnitSuccess,
  addBusinessUnitError,
  getBusinessUnitsRequest,
  getBusinessUnitsSuccess,
  getBusinessUnitsError
} from './addBusinessUnitAction';

const defaultState = {
  isAddingBusinessUnit: false,
  addBusinessUnitError: null,
  addedBusinessUnit: false,
  businessUnits: [],
  isLoadingBusinessUnits: true,
  getBusinessUnitsError: null
};

export default handleActions(
  {
    [addBusinessUnitRequest](state) {
      return {
        ...state,
        isAddingBusinessUnit: true,
        addBusinessUnitError: null,
        addedBusinessUnit: false
      };
    },
    [addBusinessUnitSuccess](state, { payload }) {
      return {
        ...state,
        isAddingBusinessUnit: false,
        addBusinessUnitError: null,
        addedBusinessUnit: true
      };
    },
    [addBusinessUnitError](state, { payload }) {
      return {
        ...state,
        isAddingBusinessUnit: false,
        addBusinessUnitError: payload,
        addedBusinessUnit: false
      };
    },
    [getBusinessUnitsRequest](state) {
      return {
        ...state,
        isLoadingBusinessUnits: true,
        getBusinessUnitsError: null,
      };
    },
    [getBusinessUnitsSuccess](state, { payload }) {
      return {
        ...state,
        businessUnits: payload,
        isLoadingBusinessUnits: false,
        getBusinessUnitsError: null,
      };
    },
    [getBusinessUnitsError](state, { payload }) {
      return {
        ...state,
        isLoadingBusinessUnits: false,
        getBusinessUnitsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);