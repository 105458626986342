import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addBusinessUnitRequest,
  addBusinessUnitSuccess,
  addBusinessUnitError
} from './addBusinessUnitAction';

const defaultState = {
  isAddingBusinessUnit: false,
  addBusinessUnitError: null,
  addedBusinessUnit: false
};

export default handleActions(
  {
    [addBusinessUnitRequest](state) {
      return {
        ...state,
        isAddingBusinessUnit: true,
        addBusinessUnitError: null,
        addedBusinessUnit: false
      };
    },
    [addBusinessUnitSuccess](state, { payload }) {
      return {
        ...state,
        isAddingBusinessUnit: false,
        addBusinessUnitError: null,
        addedBusinessUnit: true
      };
    },
    [addBusinessUnitError](state, { payload }) {
      return {
        ...state,
        isAddingBusinessUnit: false,
        addBusinessUnitError: payload,
        addedBusinessUnit: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);