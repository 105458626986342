import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getESGBanksStoriesRequest,
  getESGBanksStoriesSuccess,
  getESGBanksStoriesError,
  getESGBanksTrendsRequest,
  getESGBanksTrendsSuccess,
  getESGBanksTrendsError,
  getESGBanksSentimentRequest,
  getESGBanksSentimentSuccess,
  getESGBanksSentimentError,
  getESGBigEnergyStoriesRequest,
  getESGBigEnergyStoriesSuccess,
  getESGBigEnergyStoriesError,
  getESGBigEnergyTrendsRequest,
  getESGBigEnergyTrendsSuccess,
  getESGBigEnergyTrendsError,
  getESGBigEnergySentimentRequest,
  getESGBigEnergySentimentSuccess,
  getESGBigEnergySentimentError,
  getCloudServiceStoriesRequest,
  getCloudServiceStoriesSuccess,
  getCloudServiceStoriesError,
  getCloudServiceTrendsRequest,
  getCloudServiceTrendsSuccess,
  getCloudServiceTrendsError,
  getCloudServiceSentimentRequest,
  getCloudServiceSentimentSuccess,
  getCloudServiceSentimentError,
} from './newsMonitorAction';

const defaultState = {
  esgBanksStories: null,
  isLoadingESGBanksStories: true,
  getESGBanksStoriesError: null,
  esgBanksTrends: null,
  isLoadingESGBanksTrends: true,
  getESGBanksTrendsError: null,
  esgBanksSentiment: null, 
  isLoadingESGBanksSentiment: true, 
  getESGBanksSentimentError: null,
  esgBigEnergyStories: null,
  isLoadingESGBigEnergyStories: true,
  getESGBigEnergyStoriesError: null,
  esgBigEnergyTrends: null,
  isLoadingESGBigEnergyTrends: true,
  getESGBigEnergyTrendsError: null,
  esgBigEnergySentiment: null, 
  isLoadingESGBigEnergySentiment: true, 
  getESGBigEnergySentimentError: null,
  cloudServiceStories: null,
  isLoadingCloudServiceStories: true,
  getCloudServiceStoriesError: null,
  cloudServiceTrends: null,
  isLoadingCloudServiceTrends: true,
  getCloudServiceTrendsError: null,
  cloudServiceSentiment: null, 
  isLoadingCloudServiceSentiment: true, 
  getCloudServiceSentimentError: null,
};

export default handleActions(
  {
    //ESG BANKS
    [getESGBanksStoriesRequest](state) {
      return {
        ...state,
        isLoadingESGBanksStories: true,
        getESGBanksStoriesError: null,
      };
    },
    [getESGBanksStoriesSuccess](state, { payload }) {
      return {
        ...state,
        esgBanksStories: payload,
        isLoadingESGBanksStories: false,
        getESGBanksStoriesError: null,
      };
    },
    [getESGBanksStoriesError](state, { payload }) {
      return {
        ...state,
        isLoadingESGBanksStories: false,
        getESGBanksStoriesError: payload,
      };
    },
    [getESGBanksTrendsRequest](state) {
      return {
        ...state,
        isLoadingESGBanksTrends: true,
        getESGBanksTrendsError: null,
      };
    },
    [getESGBanksTrendsSuccess](state, { payload }) {
      return {
        ...state,
        esgBanksTrends: payload,
        isLoadingESGBanksTrends: false,
        getESGBanksTrendsError: null,
      };
    },
    [getESGBanksTrendsError](state, { payload }) {
      return {
        ...state,
        isLoadingESGBanksTrends: false,
        getESGBanksTrendsError: payload,
      };
    },
    [getESGBanksSentimentRequest](state) {
      return {
        ...state,
        isLoadingESGBanksSentiment: true,
        getESGBanksSentimentError: null,
      };
    },
    [getESGBanksSentimentSuccess](state, { payload }) {
      return {
        ...state,
        esgBanksSentiment: payload,
        isLoadingESGBanksSentiment: false,
        getESGBanksSentimentError: null,
      };
    },
    [getESGBanksSentimentError](state, { payload }) {
      return {
        ...state,
        isLoadingESGBanksSentiment: true,
        getESGBanksSentimentError: payload,
      };
    },
    //ESG BIG ENERGY
    [getESGBigEnergyStoriesRequest](state) {
      return {
        ...state,
        isLoadingESGBigEnergyStories: true,
        getESGBigEnergyStoriesError: null,
      };
    },
    [getESGBigEnergyStoriesSuccess](state, { payload }) {
      return {
        ...state,
        esgBigEnergyStories: payload,
        isLoadingESGBigEnergyStories: false,
        getESGBigEnergyStoriesError: null,
      };
    },
    [getESGBigEnergyStoriesError](state, { payload }) {
      return {
        ...state,
        isLoadingESGBigEnergyStories: false,
        getESGBigEnergyStoriesError: payload,
      };
    },
    [getESGBigEnergyTrendsRequest](state) {
      return {
        ...state,
        isLoadingESGBigEnergyTrends: true,
        getESGBigEnergyTrendsError: null,
      };
    },
    [getESGBigEnergyTrendsSuccess](state, { payload }) {
      return {
        ...state,
        esgBigEnergyTrends: payload,
        isLoadingESGBigEnergyTrends: false,
        getESGBigEnergyTrendsError: null,
      };
    },
    [getESGBigEnergyTrendsError](state, { payload }) {
      return {
        ...state,
        isLoadingESGBigEnergyTrends: false,
        getESGBigEnergyTrendsError: payload,
      };
    },
    [getESGBigEnergySentimentRequest](state) {
      return {
        ...state,
        isLoadingESGBigEnergySentiment: true,
        getESGBigEnergySentimentError: null,
      };
    },
    [getESGBigEnergySentimentSuccess](state, { payload }) {
      return {
        ...state,
        esgBigEnergySentiment: payload,
        isLoadingESGBigEnergySentiment: false,
        getESGBigEnergySentimentError: null,
      };
    },
    [getESGBigEnergySentimentError](state, { payload }) {
      return {
        ...state,
        isLoadingESGBigEnergySentiment: true,
        getESGBigEnergySentimentError: payload,
      };
    },
    //CLOUD SERVICE
    [getCloudServiceStoriesRequest](state) {
      return {
        ...state,
        isLoadingCloudServiceStories: true,
        getCloudServiceStoriesError: null,
      };
    },
    [getCloudServiceStoriesSuccess](state, { payload }) {
      return {
        ...state,
        cloudServiceStories: payload,
        isLoadingCloudServiceStories: false,
        getCloudServiceStoriesError: null,
      };
    },
    [getCloudServiceStoriesError](state, { payload }) {
      return {
        ...state,
        isLoadingCloudServiceStories: false,
        getCloudServiceStoriesError: payload,
      };
    },
    [getCloudServiceTrendsRequest](state) {
      return {
        ...state,
        isLoadingCloudServiceTrends: true,
        getCloudServiceTrendsError: null,
      };
    },
    [getCloudServiceTrendsSuccess](state, { payload }) {
      return {
        ...state,
        cloudServiceTrends: payload,
        isLoadingCloudServiceTrends: false,
        getCloudServiceTrendsError: null,
      };
    },
    [getCloudServiceTrendsError](state, { payload }) {
      return {
        ...state,
        isLoadingCloudServiceTrends: false,
        getCloudServiceTrendsError: payload,
      };
    },
    [getCloudServiceSentimentRequest](state) {
      return {
        ...state,
        isLoadingCloudServiceSentiment: true,
        getCloudServiceSentimentError: null,
      };
    },
    [getCloudServiceSentimentSuccess](state, { payload }) {
      return {
        ...state,
        cloudServiceSentiment: payload,
        isLoadingCloudServiceSentiment: false,
        getCloudServiceSentimentError: null,
      };
    },
    [getCloudServiceSentimentError](state, { payload }) {
      return {
        ...state,
        isLoadingCloudServiceSentiment: true,
        getCloudServiceSentimentError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);