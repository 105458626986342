import { Col, Row } from "react-bootstrap";
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';

const BusinessSectorSelection = ({ control, businessSector, naics}) => {
  
    const mapNaics = naics?.map(n => {
      return { dictionaryNaicsId: n.dictionaryNaicsId, name: n.name + " (" + n.sicDescription + ")" };
    }) ?? [];

    return(
        <Row>
          <Col md={12}>
            <FormSelectField
                name="dictionaryNaicsId"
                label="Business Sector"
                control={control}
                defaultValue={businessSector?.dictionaryNaicsId}
                options={mapNaics}
                keyValue={{ id:"dictionaryNaicsId", label:"name" }}
                rules={{ required: 'This field is required' }}
            />
          </Col>
        </Row>
    );
};

export default BusinessSectorSelection;