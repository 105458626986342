import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Step, StepButton, StepContent, Backdrop, Stepper, Typography, styled, Divider, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { getActivity, getStepProgress, getActivitySteps, getActivityTasks, nextStatus, cleanup } from "@/reducers/activity/editStep/editStepAction";
import { enqueueSnackbar } from "notistack";
import ActivityInfoPanel from "./components/activityInfoPanel";
import NotesIcon from '@mui/icons-material/Notes';
import ActivityStepper from "./components/activityStepper";
import ActivityActionsPanel from "./components/activityActionsPanel";
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import HelpIcon from '@mui/icons-material/Help';
import HelpPanel from "./components/helpPanel";
import { useTranslation } from "react-i18next";
import { current } from "@reduxjs/toolkit";

const EditStep = () => {
    const { id } = useParams();
    const location = useLocation();
    const componentPathIndex = location.pathname.indexOf(id) + id.length + 1;
    const componentPath = location.pathname.slice(componentPathIndex);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation("common");

    const { 
        activity, isLoadingActivity, getActivityError,
        steps, isLoadingSteps, getStepsError,
        stepProgresses,
        tasks, isLoadingTasks, getTasksError,
        isNextStatus, isNextStatusFinished, nextStatusError
     } = useSelector(({ editStep }) => editStep);

    const [currentStep, setCurrentStep] = useState(0);
    const [helpOpen, setHelpOpen] = useState(false);

    useEffect(() => {
        refreshActivity();

        return () => {
            dispatch(cleanup());
        }
    }, [id]);
    
    const refreshActivity = () => {
        dispatch(getActivity(id));
        dispatch(getActivitySteps(id));
        dispatch(getActivityTasks(id));
    }

    const [disabledSteps, setDisabledSteps] = useState([]);

    useEffect(() => {
        if(steps && steps.length && stepProgresses && stepProgresses.length){

            const tempDisabledSteps = [];
            steps.forEach((step, index) => {
                tempDisabledSteps[index] = false;

                if(step.dependOn){
                    const dependStep = steps.find(s => s.workflowActivityStepId === step.dependOn);
                    
                    if(dependStep){
                        const dependStepProgress = stepProgresses.find(p => p.workflowActivityStepCompletedId === dependStep.workflowActivityStepCompletedId);
                        
                        if(!dependStepProgress || dependStepProgress.percentageCompleted < 1){
                            tempDisabledSteps[index] = true;
                        }
                    }
                }
            });

            setDisabledSteps(tempDisabledSteps);
        }
    }, [stepProgresses, steps]);

    useEffect(() => {
        if(getActivityError){
          enqueueSnackbar(getActivityError, {variant: "error"});
        }
    }, [getActivityError]);

    useEffect(() => {
        if(getTasksError){
          enqueueSnackbar(getTasksError, {variant: "error"});
        }
    }, [getTasksError]);

    useEffect(() => {
        if(nextStatusError){
          enqueueSnackbar(nextStatusError, {variant: "error"});
        }
    }, [nextStatusError]);

    useEffect(() => {
        if(getStepsError){
          enqueueSnackbar(getStepsError, {variant: "error"});
        }
    }, [getStepsError]);

    useEffect(() => {
        if(steps){
            if(componentPath){
                const componentStep = steps.find(s => s.workflowActivityStep.componentPath === componentPath);
                if(componentStep){
                    setCurrentStep(steps.indexOf(componentStep));
                }
                else if(steps.length){
                    setCurrentStep(0);
                    navigate(steps[0].workflowActivityStep.componentPath);
                }
            }
            else if(steps.length){
                setCurrentStep(0);
                navigate(steps[0].workflowActivityStep.componentPath);
            }
        }
    }, [steps]);

    const handleClickStep = (step) => {
        setCurrentStep(step);
        navigate(steps[step].workflowActivityStep.componentPath);
    }

    const handleClickNext = () => {
        handleClickStep(currentStep + 1);
    }

    const handleClickBack = (step) => {
        handleClickStep(currentStep - 1);
    }

    const handleNextStatus = (taskStepId) => {
        const data = { 
            workflowActivityId: id, 
            taskStepId
        };
        dispatch(nextStatus(data));
    }

    const handleRefreshProgress = () => {
        if(steps[currentStep].isRequired){
            dispatch(getStepProgress(steps[currentStep].workflowActivityStepCompletedId));
            dispatch(getActivityTasks(id));
        }
    }

    useEffect(() => {
        if(isNextStatusFinished){
          enqueueSnackbar(`${activity.activityType.label} advanced to next status successfuly.`, {variant: "success"});
          refreshActivity();
        }
    }, [isNextStatusFinished]);

    const toggleHelpPanel = () => {
        setHelpOpen(!helpOpen);
    }

    return (
        <Container style={{paddingBottom: 20}}>
            <CardPage>
                <CardPageBody>
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    {t("app.activity.steps.title")} 
                                    <Divider sx={{marginInline: 1}} orientation="vertical" variant="middle" component='div' flexItem />
                                    <Button 
                                        sx={{ "& .MuiButton-startIcon": { marginRight: "4px" }}} 
                                        color="warning"
                                        onClick={toggleHelpPanel}
                                        startIcon={<HelpIcon />}>
                                            {t("app.activity.steps.help")}
                                    </Button>
                                </Box>
                            </CardPageTitle>
                        </CardPageTitleWrap>
                    </CardPageHeader>

                    <HelpPanel 
                        open={helpOpen} 
                        onClickClose={toggleHelpPanel}
                    />

                    <Row>
                        <Col md={12}>
                            <ActivityInfoPanel 
                                activity={activity} 
                            />
                            <ActivityActionsPanel 
                                tasks={tasks} 
                                isLoadingTasks={isLoadingTasks}
                                handleNextStatus={handleNextStatus} 
                            />

                            <Card variant="outline" style={{marginTop: 10}}>
                                {isLoadingActivity || !activity || isLoadingSteps || !steps || (steps.length && !steps[currentStep]) ? <div style={{height: '400px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> :  
                                    steps && !steps.length ? <div style={{height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Typography>There are no steps to show.</Typography></div> :
                                    <React.Fragment>
                                        <CardContent>

                                            <ActivityStepper 
                                                steps={steps}
                                                currentStep={currentStep}
                                                disabledSteps={disabledSteps}
                                                stepProgresses={stepProgresses}
                                                handleClickStep={handleClickStep}
                                            />
                                            
                                            <Typography style={{marginBottom: 20}}><NotesIcon fontSize="small"/> {steps[currentStep].workflowActivityStep.description}</Typography>
                                            <Outlet context={{ 
                                                activity: activity, 
                                                workflowActivityStepCompletedId: steps[currentStep].workflowActivityStepCompletedId, 
                                                isEditor: steps[currentStep].isEditor, 
                                                handleRefreshProgress,
                                                activityType: activity.activityType,
                                                handleClickNext,
                                                handleClickBack,
                                            }}/>

                                        </CardContent>

                                        { steps.length > 1 &&
                                            <>
                                                <Divider component='div'/>
                                                
                                                <CardActions style={{padding: 16}}>
                                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                                        <Button 
                                                            variant="outlined"
                                                            disabled={currentStep === 0 || disabledSteps[currentStep - 1]} 
                                                            onClick={handleClickBack}
                                                        >
                                                            {t("app.activity.steps.back")}
                                                        </Button>

                                                        <Button 
                                                            variant="outlined"
                                                            disabled={currentStep === steps.length - 1 || disabledSteps[currentStep + 1]} 
                                                            onClick={handleClickNext}
                                                        >
                                                            {t("app.activity.steps.next")}
                                                        </Button>
                                                    </div>
                                                </CardActions>
                                            </>
                                        }
                                    </React.Fragment>
                                }
                            </Card>
                        </Col>
                    </Row>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isNextStatus}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default EditStep;