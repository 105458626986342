import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAssetReportRequest,
  getAssetReportSuccess,
  getAssetReportError,
} from './AUAssetReportsAction';

const defaultState = {
  assetReports: {}
};

export default handleActions(
  {
    [getAssetReportRequest](state, { payload }) {
      const oldReports = JSON.parse(JSON.stringify(state.assetReports));

      oldReports[payload.assetId] = {
        isLoading: true,
        error: null,
        data: null
      };
      
      return {
        assetReports: oldReports
      };
    },
    [getAssetReportSuccess](state, { payload }) {
      const oldReports = JSON.parse(JSON.stringify(state.assetReports));
      oldReports[payload.assetId] = {
        isLoading: false,
        error: null,
        data: payload.data
      }

      return {
        assetReports: oldReports
      };
    },
    [getAssetReportError](state, { payload }) {
      const oldReports = JSON.parse(JSON.stringify(state.assetReports));

      oldReports[payload.assetId] = {
        isLoading: false,
        error: payload.error,
        data: null
      }

      return {
        assetReports: oldReports
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);