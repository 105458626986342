import styled from "styled-components";
import OutputIcon from '@mui/icons-material/Output';
import dayjs from "dayjs";
import { Box, Button, Card, CardContent, Chip, Divider, List, ListItem, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ReducedActivityItem = ({
    activity
}) => {

    const navigate = useNavigate();

    const handleClickEdit = (activity) => {
        navigate("/Activities/EditStep/" + activity.workflowActivityId);
    }

    const truncate = (input) => {
        if (input && input.length > 50) {
           return input.substring(0, 50) + '...';
        }
        return input;
    };

    var dateString = "";
    if(activity.startDate)
        dateString += dayjs(activity.startDate).format("DD MMM YY");

    if(activity.endDate)
        dateString += " - " + dayjs(activity.endDate).format("DD MMM YY");

    return (
        <CardContentWrapper style={{paddingBlock: 5}}>
            <ColorLine style={{backgroundColor: activity.activityType.applicationModule.color}} />
            <ListItem alignItems="flex-start" disablePadding>
                <Card sx={{width: '100%', padding: 1}}>
                    <Row>
                        <Col md={12}>
                            <TitleContainer>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <div>
                                        <Typography style={{fontSize: 16}}>{activity.activityType.applicationModule.label}: </Typography>
                                    </div>
                                    <Box sx={{
                                        display: "flex",
                                        marginLeft: 2
                                    }}>
                                        <div style={{fontSize: 14, display: "flex", alignItems: 'center'}}>
                                            <Typography fontSize={16} fontWeight="bold">{truncate(activity.description)}</Typography>
                                        </div>
                                    </Box>
                                    
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Chip 
                                            label={activity.workflowActivityStatus.workflowGenericStatus.label} 
                                            size="small" 
                                            sx={{
                                                backgroundColor: activity.workflowActivityStatus.workflowGenericStatus.color, 
                                                fontSize: '12px',
                                                height: '20px',
                                                marginRight: 1
                                            }} 
                                    />
                                    
                                    <Chip 
                                        label={activity.workflowActivityPriority.name} 
                                        size="small" 
                                        sx={{
                                            backgroundColor: activity.workflowActivityPriority.color, 
                                            fontSize: '12px',
                                            height: '20px'
                                        }}  
                                    />
                                    <Divider style={{marginInline: 10}} orientation="vertical" flexItem component='div' />
                                    <Typography 
                                        color='text.secondary' 
                                        sx={{
                                            fontSize: 14
                                        }}>
                                            {dateString}
                                    </Typography>
                                    <Button 
                                        style={{marginLeft: 10}}
                                        variant="contained"
                                        endIcon={<OutputIcon />}
                                        onClick={() => handleClickEdit(activity)}
                                    >
                                        Enter
                                    </Button>
                                </Box>                                            
                            </TitleContainer>
                        </Col>
                    </Row>
                </Card>
            </ListItem>
        </CardContentWrapper>
    );
};


const TitleContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`;

const CardContentWrapper = styled.div`
display: flex;
width: 100%;
`;

const ColorLine = styled.div`
padding-inline: 2px;
height: auto;
`;

export default ReducedActivityItem;