import { FormControl, Box, InputAdornment, InputLabel, OutlinedInput, TextField, IconButton, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SelectChipArray from "@/shared/components/SelectChipArray";

const ConversationInputMessage = ({
    onSendMessage
}) => {
    const responseLengthOptions = [
        { id: 0, name: "Short" },
        { id: 1, name: "Medium" },
        { id: 2, name: "Long" }
    ]

    const tokenPayload = useSelector(({ auth }) => auth.tokenPayload);

    const [message, setMessage] = useState("");
    // const [systemMessage, setSystemMessage] = useState("");

    const [responseLength, setResponseLength] = useState(1);

    const onChangeResponseLength = (newResponseLength) => {
        setResponseLength(newResponseLength.id);
    };

    const onClickSend = () => {
        if(message)
            onSendMessage(message, responseLength);

        setMessage("");
    }

    return (
        <Box sx={{
            marginBottom: 1,
            marginTop: 'auto'
        }}>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="inputMessage">Message</InputLabel>
                <OutlinedInput
                    id="inputMessage"
                    name="inputMessage"
                    label="Message"
                    multiline
                    rows={4}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="send"
                            edge="end"
                            onClick={onClickSend}
                        >
                        <SendIcon />
                        </IconButton>
                    </InputAdornment>
                    }                
                />
            </FormControl>

            {/* { tokenPayload && 
            <FormControl sx={{marginTop: 1}} fullWidth variant="outlined">
                <InputLabel htmlFor="systemInputMessage">System Message</InputLabel>
                <OutlinedInput
                    id="systemInputMessage"
                    name="systemInputMessage"
                    label="System Message"
                    multiline
                    rows={2}
                    value={systemMessage}
                    onChange={e => setSystemMessage(e.target.value)}
                />
            </FormControl>
            } */}

            <Box sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Typography>Response Length: </Typography>
                <SelectChipArray 
                    options={responseLengthOptions}
                    defaultValue={responseLengthOptions[1]}
                    onChange={onChangeResponseLength}
                />
            </Box>
        </Box>
    );
}

export default ConversationInputMessage;