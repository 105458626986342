import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';

const TenantSocialForm = ({
  asset,
  onSubmit
}) => {
  const { handleSubmit, reset, control, setValue, getValues } = useForm();

  return (
    <>
      <Row>
        <Col md={12}>
          <Divider component='div' style={{ marginTop: 20 }} />
          <Typography variant="subtitle1" style={{ marginBlock: 20 }}>Social Information</Typography>

          <Grid container spacing='20px'>
            <Grid item xs={12}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}>
                  <LanguageIcon sx={{ marginRight: 1, fontSize: 30 }} />
                  <FormTextField 
                      name="webUrl" 
                      label="Web Url" 
                      control={control} 
                      defaultValue={asset?.metadata?.tenantsocialInformation?.webUrl}
                      style={{marginTop: 0}}
                  />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}>
                  <LinkedInIcon sx={{ marginRight: 1, fontSize: 30 }} />
                  <FormTextField 
                      name="linkedinProfileUrl" 
                      label="Linkedin Profile Url" 
                      control={control} 
                      defaultValue={asset?.metadata?.tenantsocialInformation?.linkedinProfileUrl}
                      style={{marginTop: 0}}
                  />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}>
                  <GitHubIcon sx={{ marginRight: 1, fontSize: 30 }} />
                  <FormTextField 
                      name="githubProfileUrl" 
                      label="Github Profile Url" 
                      control={control} 
                      defaultValue={asset?.metadata?.tenantsocialInformation?.githubProfileUrl}
                      style={{marginTop: 0}}
                  />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}>
                  <FacebookIcon sx={{ marginRight: 1, fontSize: 30 }} />
                  <FormTextField 
                      name="facebookProfileUrl" 
                      label="Facebook Profile Url" 
                      control={control} 
                      defaultValue={asset?.metadata?.tenantsocialInformation?.facebookProfileUrl}
                      style={{marginTop: 0}}
                  />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}>
                  <XIcon sx={{ marginRight: 1, fontSize: 30 }} />
                  <FormTextField 
                      name="xProfileUrl" 
                      label="X Profile Url" 
                      control={control} 
                      defaultValue={asset?.metadata?.tenantsocialInformation?.xProfileUrl}
                      style={{marginTop: 0}}
                  />
                </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row'
              }}>
                <InstagramIcon sx={{ marginRight: 1, fontSize: 30 }} />
                <FormTextField 
                    name="instagramProfileUrl" 
                    label="Instagram Profile Url" 
                    control={control} 
                    defaultValue={asset?.metadata?.tenantsocialInformation?.instagramProfileUrl}
                    style={{marginTop: 0}}
                />
              </Box>
            </Grid>
          </Grid>

          <Divider component='div' style={{ marginTop: 20 }} />
          <Typography variant="subtitle1" style={{ marginBlock: 20 }}>Cloud Information</Typography>
          
          <Grid container spacing='20px'>
            <Grid item xs={12}>
              <FormTextField 
                  name="azureApiKey" 
                  label="Azure Api Key" 
                  control={control} 
                  defaultValue={asset?.metadata?.tenantsocialInformation?.azureApiKey}
                  style={{marginTop: 0}}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField 
                  name="awsApiKey" 
                  label="AWS Api Key" 
                  control={control} 
                  defaultValue={asset?.metadata?.tenantsocialInformation?.awsApiKey}
                  style={{marginTop: 0}}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    >
                    Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Col>
      </Row>
    </>
  );
};

export default TenantSocialForm;