export const locationTypes = [
  {
    id: 1,
    name: "Office"
  },
  {
    id: 2,
    name: "Sale Point"
  },
  {
    id: 3,
    name: "Factory"
  },
  {
    id: 4,
    name: "Warehouse"
  }
];