import { Backdrop, Box, Button, CircularProgress, Paper, Tab, Tabs, Typography} from "@mui/material"; 
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { enqueueSnackbar } from "notistack";
import { cleanup, getAsset } from "@/reducers/organizations/updateOrganization/updateOrganizationAction";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import TabPanel, { tabIndexProps } from "@/shared/components/MaterialTabPanel";
import InfoIcon from '@mui/icons-material/Info';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessSectorsList from "../../Supplier/components/businessSectorsList";
import OrganizationGeneralInfo from "./organizationGeneralInfo";
import LocationsList from "../../../Activities/EditStep/SASteps/components/customSurveyComponents/supplierLocations/LocationsList";
// import OrganizationLocationsForm from "../../../Activities/EditStep/SASteps/components/customSurveyComponents/organizationLocations/organizationLocationsForm";

const UpdateOrganization = () => {

    const dispatch = useDispatch();

    const { 
      currentTenant, isLoadingCurrentTenant
    } = useSelector(({ currentTenant }) => currentTenant);

    const {
        asset, isLoadingAsset, getAssetError
    } = useSelector(({ updateOrganization }) => updateOrganization);
    
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    useEffect(() => {
        if(currentTenant) {
            dispatch(getAsset(currentTenant.assetId));
        }

        return () => {
            dispatch(cleanup());
        }
    }, [currentTenant]);

    useEffect(() => {
        if(getAssetError){
            enqueueSnackbar(getAssetError, {variant: "error"});
        }
    }, [getAssetError]);

    const onChangedBusinessSectors = () => {
        dispatch(getAsset(currentTenant.assetId));
    };

    return(
      <Container>
        <CardPage>
          <CardPageBody>
                <CardPageHeader>
                    <CardPageTitleWrap>
                        <CardPageTitle>Update Organization</CardPageTitle>
                    </CardPageTitleWrap>
                </CardPageHeader>

                <Tabs 
                    variant="fullWidth"
                    value={tab} 
                    onChange={handleTabChange}
                >
                    <Tab 
                        label="General Information"
                        icon={<InfoIcon />} 
                        iconPosition="start" 
                        {...tabIndexProps(0)}
                    />
                    <Tab 
                        label="Business Sectors"
                        icon={<ViewModuleIcon />} 
                        iconPosition="start" 
                        {...tabIndexProps(1)}
                    />
                    <Tab 
                        label="Locations"
                        icon={<BusinessIcon />} 
                        iconPosition="start" 
                        {...tabIndexProps(2)}
                    />
                </Tabs>
                { isLoadingAsset || isLoadingCurrentTenant ? <CircularProgressWrapper /> :
                    <>
                        <TabPanel value={tab} index={0} sx={{py: 2}}>
                            <OrganizationGeneralInfo 
                                organization={currentTenant}
                            />
                        </TabPanel>
                        <TabPanel value={tab} index={1} sx={{py: 2}}>
                            <BusinessSectorsList
                                asset={asset}
                                onChangedBusinessSectors={onChangedBusinessSectors}
                            />
                        </TabPanel>
                        <TabPanel value={tab} index={2} sx={{py: 2}}>
                            <LocationsList
                                assetId={currentTenant.assetId}
                            />
                        </TabPanel>
                    </>
                }
          </CardPageBody>
        </CardPage>
      </Container>
    )
}

export default UpdateOrganization;