export const ratingScores = [
  {
      name: "Very Low",
      value: 1,
      color: "#00B050"
  },
  {
      name: "Low",
      value: 2,
      color: "#92D050"
  },
  {
      name: "Medium",
      value: 3,
      color: "#FFC000"
  },
  {
      name: "High",
      value: 4,
      color: "#FF8D00"
  },
  {
      name: "Critical",
      value: 5,
      color: "#FF0000"
  }
];

export const getRatingScoresGradient = () => {
  var gradient = "linear-gradient(to right";
  ratingScores.forEach(r => gradient += ", " + r.color);
  gradient += ")";

  return gradient;
};