import { Avatar, Box, Typography, CircularProgress } from "@mui/material";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import Message from "./message";
import BotAvatar from "../../../shared/components/chatbot/BotAvatar";

const Conversation = ({
    messages,
    isSendingMessage
}) => {
    return(
        <Box>
            {
                messages ? 
                    messages.map((message, index) => {
                            var showAvatar = !message.isMine;

                            return(
                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    { showAvatar && 
                                            <BotAvatar size="30px"/>
                                    }
                                    
                                    <Message 
                                        message={message}
                                        index={index}
                                        isMine={message.isMine}
                                        hasAvatar={!message.isMine}
                                    />
                                </Box>
                            );
                        })
                    :
                    <Box sx={{
                        display: 'flex', 
                        justifyContent: "center", 
                        alignItems: 'center', 
                        height: '100%'
                    }}>
                        <Typography>There aren't messages yet, send the first to start a conversation for this question.</Typography>
                    </Box>
            }
            { isSendingMessage &&
                <Box sx={{
                    display: 'flex'
                }}>
                    <BotAvatar size="30px"/>
                    <Message 
                        isLoading={true}
                        isMine={false}
                        hasAvatar={true} 
                    />                
                </Box>
            }
        </Box>
    );
}

export default Conversation;