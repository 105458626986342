import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getStakeholderActivitiesRequest,
  getStakeholderActivitiesSuccess,
  getStakeholderActivitiesError,
} from './stakeholderActivitiesAction';

const defaultState = {
  activities: null,
  isLoadingActivities: true,
  getActivitiesError: null,
};

export default handleActions(
  {
    [getStakeholderActivitiesRequest](state) {
      return {
        ...state,
        isLoadingActivities: true,
        getActivitiesError: null,
      };
    },
    [getStakeholderActivitiesSuccess](state, { payload }) {
      return {
        ...state,
        activities: payload,
        isLoadingActivities: false,
        getActivitiesError: null,
      };
    },
    [getStakeholderActivitiesError](state, { payload }) {
      return {
        ...state,
        isLoadingActivities: false,
        getActivitiesError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
