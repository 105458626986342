import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getActivityStatuses, getUsersInActivity, getCurrentActivities, deleteActivity, getSteps, getActivityTypes, getPriorities, cleanup } from "@/reducers/activity/listCurrentActivities/listCurrentActivitiesAction";
import { Backdrop, Button, ButtonGroup, Card, CardContent, CircularProgress, Grid, IconButton, TablePagination, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import AddIcon from '@mui/icons-material/Add';
import { enqueueSnackbar } from "notistack";
import EditActivityModal from "./EditActivityModal";
import { useState } from "react";
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { FormSelectField } from "@/shared/components/form/material-controls/SelectField";
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { SearchFilters } from "../../../shared/components/SearchFilters";
import ActivityList from "./ActivityList";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { FormMultiSelectField } from '@/shared/components/form/material-controls/MultiSelectField';

const Activities = ({startActivityType}) => {

    const roles = useSelector(({ auth }) => auth.roles);
    const addActivityRoles = ["Administrator", "Manager", "TenantAdministrator", "SystemAdministrator", "Auditor"];
    const showAddActivity = roles.filter(value => addActivityRoles.includes(value)).length > 0;

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { 
        currentActivities, isLoadingCurrentActivities, getCurrentActivitiesError, activitiesTotalCount,
        priorities, isLoadingPriorities, getPrioritiesError,
        statuses, isLoadingStatuses, getStatusesError,
        activityTypes, isLoadingActivityTypes, getActivityTypesError,
        usersInActivity, isLoadingUsersInActivity, getUsersInActivityError,
        isDeletingActivity, deletedActivity, deleteActivityError,
        steps
    } = useSelector(({ listCurrentActivities }) => listCurrentActivities);

    const [showActivityEditModal, setShowActivityEditModal] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);

    const startFilters = { pageSize: 15, page: 1 };
    if(startActivityType){
        startFilters.activityTypeId = startActivityType;
    }
    const [searchFilters, setSearchFilters] = useState(startFilters);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const handleChangePage = (event, newPage) => {
        setSearchFilters({ ...searchFilters, page: newPage + 1 });
    };

    const handleChangeRowsPerPage = (event) => {
        setSearchFilters({ ...searchFilters, pageSize: parseInt(event.target.value, 10) });
    };

    useEffect(() => {
        dispatch(getPriorities());
        dispatch(getActivityTypes());
        dispatch(getActivityStatuses());
        dispatch(getUsersInActivity());

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCurrentActivities(searchFilters));
    }, [searchFilters]);

    useEffect(() => {
        if(getCurrentActivitiesError){
          enqueueSnackbar(getCurrentActivitiesError, {variant: "error"});
        }
    }, [getCurrentActivitiesError]);

    useEffect(() => {
        if(getUsersInActivityError){
          enqueueSnackbar(getUsersInActivityError, {variant: "error"});
        }
    }, [getUsersInActivityError]);

    useEffect(() => {
        if(getStatusesError){
          enqueueSnackbar(getStatusesError, {variant: "error"});
        }
    }, [getStatusesError]);

    useEffect(() => {
        if(getPrioritiesError){
          enqueueSnackbar(getPrioritiesError, {variant: "error"});
        }
    }, [getPrioritiesError]);

    useEffect(() => {
        if(getActivityTypesError){
          enqueueSnackbar(getActivityTypesError, {variant: "error"});
        }
    }, [getActivityTypesError]);

    useEffect(() => {
        if(deleteActivityError){
          enqueueSnackbar(deleteActivityError, {variant: "error"});
        }
    }, [deleteActivityError]);

    const handleClickCreate = () => {
        navigate("ListCreate");
    }

    const handleActivityEditClick = (activity) => {
        setSelectedActivity(activity);
        setShowActivityEditModal(true);
    }

    const handleActivityDeleteClick = (activity) => {
        setSelectedActivity(activity);
        setOpenConfirmDelete(true);
    }

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);
        dispatch(deleteActivity(selectedActivity.workflowActivityId));
        setSelectedActivity(null);
    }

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setSelectedActivity(null);
    };

    useEffect(() => {
        if(deletedActivity){
            enqueueSnackbar("Activity deleted successfully", {variant: "success"});
            dispatch(getCurrentActivities(searchFilters));
        }
    }, [deletedActivity]);

    const onHideActivityEditModal = () => {
        setShowActivityEditModal(false);
        setSelectedActivity(null);
    };
    
    const onActivityUpdated = () => {
        setShowActivityEditModal(false);
        setSelectedActivity(null);
        dispatch(getCurrentActivities());
    }

    const onFilterSubmit = (data) => {
        setSearchFilters({ ...searchFilters, page: 1, ...data});
    }

    const loadSteps = (workflowActivityId) => {
        dispatch(getSteps(workflowActivityId));
    }

    return (
        <Container style={{paddingBottom: 20}}>
            <CardPage>
                <CardPageBody>
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Activities</CardPageTitle>
                            <CardPageSubhead>
                            Complete tasks for each activity.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                        <CardPageButtonWrap>
                            { showAddActivity && 
                                <Button variant="contained" color="primary"  onClick={handleClickCreate} endIcon={<AddIcon/>}>Add Activity</Button>
                            }
                        </CardPageButtonWrap>
                    </CardPageHeader>

                    <SearchFilters 
                        searchLabel='Search By Description'
                        onFilterSubmit={onFilterSubmit}
                        renderChildrenInputs={(control) => {
                            return(
                            <>
                                <Grid item xs={12} md={3}>
                                    <FormDateField
                                        style={{margin: 0}}
                                        name="startDate"
                                        label="Start Date"
                                        control={control}
                                    />                                
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormDateField
                                        style={{margin: 0}}
                                        name="endDate"
                                        label="End Date"
                                        control={control}
                                    />
                                </Grid>
                            </>
                            );
                        }}
                        renderAvancedFilters={(control) => {
                            return(
                            <>
                                <Grid item xs={12} md={4}>
                                    <FormSelectField
                                        style={{margin: 0}}
                                        name="activityTypeId"
                                        label="Activity Type"
                                        control={control}
                                        options={activityTypes}
                                        keyValue={{ id:"activityTypeId", label:"label" }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormSelectField
                                        style={{margin: 0}}
                                        name="priorityId"
                                        label="Priority"
                                        control={control}
                                        options={priorities}
                                        keyValue={{id: "workflowActivityPriorityId", label: "name"}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormMultiSelectField
                                        style={{margin: 0}}
                                        name="genericStatusIds"
                                        label="Status"
                                        control={control}
                                        options={statuses}
                                        keyValue={{id: "workflowGenericStatusId", label: "label"}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormSelectField 
                                        style={{margin: 0}}
                                        name="expired" 
                                        label="Expired" 
                                        control={control} 
                                        options={[
                                        { id: false, label: "No" }, 
                                        { id: true, label: "Si" }
                                        ]}
                                        keyValue={{id:"id", label:"label"}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormSelectField 
                                        style={{margin: 0}}
                                        name="canEdit" 
                                        label="Can Edit" 
                                        control={control} 
                                        options={[
                                        { id: false, label: "No" }, 
                                        { id: true, label: "Si" }
                                        ]}
                                        keyValue={{id:"id", label:"label"}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormSelectField
                                        style={{margin: 0}}
                                        name="editorUser"
                                        label="Editor User"
                                        control={control}
                                        options={usersInActivity}
                                        keyValue={{id: "id", label: "completeName"}}
                                    />
                                </Grid>
                            </>
                            );
                        }}
                    />

                    <ActivityList 
                        activities={currentActivities}
                        handleActivityEditClick={handleActivityEditClick}
                        handleActivityDeleteClick={handleActivityDeleteClick}
                        isLoading={isLoadingCurrentActivities || isLoadingActivityTypes}
                        loadSteps={loadSteps}
                        steps={steps}
                        activityTypes={activityTypes}
                    />

                    <TablePagination
                        component="div"
                        count={activitiesTotalCount}
                        page={searchFilters.page - 1}
                        onPageChange={handleChangePage}
                        rowsPerPage={searchFilters.pageSize}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[15, 30, 60, 90]}
                    />
                </CardPageBody>
            </CardPage>
        
        {showActivityEditModal &&
            <EditActivityModal 
                priorities={priorities}
                activity={selectedActivity}
                onHideModal={onHideActivityEditModal}
                open={showActivityEditModal}
                onActivityUpdated={onActivityUpdated}
            />
        }

        {openConfirmDelete &&
            <ConfirmationDialog 
                open={openConfirmDelete}
                onConfirm={handleConfirmDelete}
                onClose={handleOnCloseDelete}
                title="Delete Activity"
                body={`Are you sure you want to delete activity ${selectedActivity.description}?`}
                color='error'
                confirmButtonText='Delete'
            />
        }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isDeletingActivity}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}

export default Activities;