import { Backdrop, Button, CircularProgress, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, addUser } from "@/reducers/supplier/stakeholderUsers/addUser/stakeholderAddUserAction";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import AddStakeholderUserForm from "./addStakeholderUserForm";

const AddStakeholderUserModal = ({ stakeholderId, open, onHideModal, onUserAdded }) => {
    const dispatch = useDispatch();
    const { 
        isAddingUser, addedUser, addUserError
    } = useSelector(({ stakeholderAddUser }) => stakeholderAddUser);

    const { handleSubmit, watch, getValues, reset, control, setValue } = useForm();

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
        
      reset();
      onHideModal();
    }

    useEffect(() => {
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(addUserError){
            enqueueSnackbar(addUserError, {variant: "error"});
        }
    }, [addUserError]);

    useEffect(() => {
        if(addedUser){
          enqueueSnackbar(`User created successfuly.`, {variant: "success"});
          handleClose();
          onUserAdded();
        }
    }, [addedUser]);

    const onSubmit = (data) => {
        data.stakeholderId = stakeholderId;
        dispatch(addUser(data));
    }

    return(
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
            onClose={handleClose}
        >
          <DialogTitle>Add User</DialogTitle>

          <DialogContent>
            <AddStakeholderUserForm 
              watch={watch}
              control={control}
              setValue={setValue}
              getValues={getValues}
            />
          </DialogContent>

          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Add and send email</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isAddingUser}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
};

export default AddStakeholderUserModal;