import { Box, Icon, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import ReportGradient from "./reportGradient";
import { getRatingScoresGradient } from "@/utils/ratingScores";

const RiskCalculationScore = ({
  calculation
}) => {

  const gradient = getRatingScoresGradient();

  return(
    <Row>
      <Col md={8}>
          <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              flexWrap: 'wrap',
              mb: 3
          }}>
              <Box sx={{
                  display: 'flex'
              }}>
                  <Icon sx={{ mr: 1 }}>{calculation.icon}</Icon>
                  <Typography>{calculation.surveyName}</Typography>
              </Box>
              <Typography sx={{
                  ml: 4,
                  color: 'text.secondary',
                  fontSize: 10
              }}>({calculation.surveyCode})</Typography>
          </Box>
      </Col>
      <Col md={4}>
          <ReportGradient 
              calculation={calculation}
              gradient={gradient}
          />
      </Col>
    </Row>
  );
};

export default RiskCalculationScore;