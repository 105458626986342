import { Box, Divider, Skeleton, Typography } from "@mui/material";
import BotAvatar from "@/shared/components/chatbot/BotAvatar";
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorIcon from '@mui/icons-material/Error';

const Welcome = ({ 
    welcomeMessage, 
    isLoadingWelcomeMessage 
}) => {
    return(
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: 2,
            flexDirection: 'column'
        }}>
            <BotAvatar size='150px' />
            { isLoadingWelcomeMessage || !welcomeMessage ? <Skeleton sx={{ fontSize: '24px', width: '70%' }} /> :
                welcomeMessage.map(m => {
                    return(
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    marginTop: 3
                }}>
                            <>
                                { m.alertIcon && 
                                    <>
                                        <>
                                            { m.alertIcon === "info" ?
                                                <InfoIcon color="info" sx={{fontSize: '24px'}} />
                                            :
                                                m.alertIcon === "warning" ? 
                                                <WarningAmberIcon color="warning" sx={{fontSize: '24px'}} />
                                            :
                                                <ErrorIcon color="error" sx={{fontSize: '24px'}} />
                                            }
                                        </>

                                        <Divider 
                                            orientation="vertical" 
                                            component="div" 
                                            flexItem
                                            sx={{ marginInline: 1 }} 
                                        />
                                    </>
                                }

                                <Typography 
                                    sx={{
                                        fontSize: '16px'
                                    }} 
                                    color='text.primary'>
                                        {m.responseMessage}
                                </Typography>
                            </>
                </Box>
                );
                })
            }
        </Box>
    );
};

export default Welcome;