import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getSurvey, answerSurvey, saveDraft, cleanup } from "@/reducers/selfAssessment/answerSurvey/answerSurveyAction";
import "survey-core/defaultV2.min.css";
import { Model, SurveyError } from "survey-core";
import { Survey } from "survey-react-ui";
import { ReactSurveyElement, ReactElementFactory } from "survey-react-ui";
import "./survey.css";
import { enqueueSnackbar } from "notistack";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Backdrop, Badge, CircularProgress, IconButton, Tooltip } from "@mui/material";
import * as darkTheme from "./dark-theme.json";
import * as lightTheme from "./light-theme.json";
import ScoreChart from "./scoreChart";
import ChatModal from "./chat/chatModal";
import ChatIcon from '@mui/icons-material/Chat';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { useTranslation } from "react-i18next";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { analyzeSentiment } from "../../../../../../reducers/chatbot/chatbotAction";

const RenderSurvey = ({
    activitySurveyId, 
    isSupervisor,
    isEditor,
    surveyInfo,
    onSurveyCompleted,
    onSurveySaved,
    workflowActivityStepCompletedId
}) => {
    const {t} = useTranslation("common");
    const dispatch = useDispatch();

    const { 
      surveyJson, isLoadingSurveyJson, getSurveyJsonError,
      isSendingSurvey, sendSurveyResult, sendSurveyError,
      isSavingDraft, saveDraftResult, saveDraftError
    } = useSelector(({ answerSurvey }) => answerSurvey);

    const [surveyData, setSurveyData] = useState(null);

    const { mode } = useSelector(({ changeTheme }) => ({
      mode: changeTheme.theme,
    }));

    useEffect(() => {
        dispatch(getSurvey(surveyInfo.surveyId, activitySurveyId));
  
        return () => {
            dispatch(cleanup());
        }
    }, [surveyInfo]);

    const [showChatModal, setShowChatModal] = useState(false);
    const [chatData, setChatData] = useState(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [completeData, setCompleteData] = useState(null);

    const onHideChatModal = () => {
      setShowChatModal(false);
      setChatData(null);
    };
    const onClickChat = (code, questionText) => {
      setChatData({code, questionText});
      setShowChatModal(true);
    };

    const onClickAnalyze = (code, value) => {
      dispatch(analyzeSentiment(value));
    };
  
    useEffect(() => {
        if(getSurveyJsonError){
          enqueueSnackbar(getSurveyJsonError, {variant: "error"});
        }
    }, [getSurveyJsonError]);

    useEffect(() => {
      if(sendSurveyError){
        enqueueSnackbar("An error ocurred while saving the survey, please try again later.", {variant: "error"});
      }
    }, [sendSurveyError]);

    useEffect(() => {
      if(saveDraftError){
        enqueueSnackbar(saveDraftError, {variant: "error"});
      }
    }, [saveDraftError]);

    useEffect(() => {
      if(sendSurveyResult){
        if(sendSurveyResult.errors && Object.keys(sendSurveyResult.errors).length){
          addErrors(sendSurveyResult.errors);
        }
        else{
          enqueueSnackbar("Survey completed successfully", {variant: "success"});
          onSurveyCompleted(surveyInfo.surveyId, sendSurveyResult);
        }
      }
    }, [sendSurveyResult]);

    useEffect(() => {
      if(saveDraftResult){
        if(saveDraftResult.errors && Object.keys(saveDraftResult.errors).length){
          addErrors(saveDraftResult.errors);
        }
        else{
          enqueueSnackbar("Survey answers saved successfully", {variant: "success"});
          onSurveySaved(surveyInfo.surveyId, saveDraftResult);
        }
      }
    }, [saveDraftResult]);

    const handleConfirmComplete = () => {
      setOpenConfirmModal(false);
      var finalData = getDtoFromAnswers(completeData);
      dispatch(answerSurvey(finalData));
  }

  const handleOnCloseConfirm = () => {
      setOpenConfirmModal(false);
  };

    const addErrors = (errors) => {
      const questions = surveyData.getAllQuestions();
      const questionCodesWithError = Object.keys(errors);

      var pageNames = [];
      
      questionCodesWithError.forEach((c) => {
        const question = questions.find(q => q.jsonObj.code === c);
        question.addError(new SurveyError(errors[c]));
        
        if(!pageNames.includes(question.page.jsonObj.name))
        pageNames.push(question.page.jsonObj.name);        
      });

      var minPageIndexWithError;

      for(var i = 0; i < surveyData.visiblePages.length; i++){
        if(pageNames.includes(surveyData.visiblePages[i].jsonObj.name)){
          minPageIndexWithError = i;
          break;
        }
      }

      surveyData.currentPageNo = minPageIndexWithError;
      surveyData.currentPage.focusFirstErrorQuestion();
    };

    const recursiveParseOldAnswers = (answer, questionType) => {
      let parsedContent = {};

      if(answer.content.selections){
        parsedContent = answer.content.selections.map(a => a.id);
      }
      else if(questionType === 'text' || questionType === 'comment'){
        parsedContent = answer.content.text;
      }
      else if(questionType === 'radiogroup' || questionType === 'dropdown'){
        parsedContent = answer.content.id;
      }
      else if(questionType === 'boolean'){
        parsedContent = answer.content.checked;
      }
      else if(questionType === 'file'){
        parsedContent = [answer.content.file];
      }
      else if(answer.content.content){
        parsedContent = [answer.content];
      }

      return parsedContent;
    }

    const parseOldAnswers = () => {
      var parsedAnswers = [];

      var questions = [];
      surveyJson.model.pages.forEach(page => {
        recursiveFindQuestions(page.elements, questions);
      });

      surveyJson.data.forEach((answer) => {
        if(answer.code && answer.content){
            var foundQuestion = questions.find(q => q.code === answer.code);
            
            if(foundQuestion){
              let parsedContent = {};
              if(foundQuestion.type === "matrixdropdown"){
                answer.content.rows.forEach(r => {
                  parsedContent[r.row] = {};
        
                  r.values.forEach(v => {
                    var foundColumn = foundQuestion.columns.find(c => c.name === v.column);
                    parsedContent[r.row][v.column] = recursiveParseOldAnswers(v, foundColumn.cellType);
                  });
                });
              }
              else{
                parsedContent = recursiveParseOldAnswers(answer, foundQuestion.type);
              }
              
              parsedAnswers[foundQuestion.name] = parsedContent;
            }
        }
      });

      return parsedAnswers;
    }

    const recursiveFindQuestions = (currentQuestions, savedQuestions) => {
      currentQuestions.forEach(q => {
        if(q.elements && q.elements.length){
          recursiveFindQuestions(q.elements, savedQuestions);
        }
        else{
          savedQuestions.push(q);
        }
      })
    }

    const getDtoFromAnswers = (data) => {
      var questions = [];
      surveyJson.model.pages.forEach(page => {
        recursiveFindQuestions(page.elements, questions);
      });

      var answers = [];
      for(var answer in data)
      {
        var foundQuestion = questions.find(q => q.name === answer);
        
        let answerContent;


        if(foundQuestion.type === "matrixdropdown"){
          var rowNames = Object.keys(data[answer]);
          
          var answerContentArray = [];

          for(var r in rowNames){
            var rowValues = data[answer][rowNames[r]];
            var rowContent = [];

            var columnNames = Object.keys(rowValues);
            for(var c in columnNames){
              var foundColumn = foundQuestion.columns.find(col => col.name === columnNames[c]);
              let subAnswerContent;

              if(foundColumn.choices){
                if(rowValues[columnNames[c]] && rowValues[columnNames[c]].length){

                  var answerSubContentArray = [];
                  for(var a in rowValues[columnNames[c]]){
      
                    var foundChoice = foundColumn.choices.find(ch => ch.value === rowValues[columnNames[c]][a]);
                    if(foundChoice){
                      answerSubContentArray.push({ 
                        id: foundChoice.value,
                        text: foundChoice.text
                      });
                    }
                  }
                  subAnswerContent = answerSubContentArray;
                }
                else{
      
                  var foundChoice = foundColumn.choices.find(ch => ch.value === rowValues[columnNames[c]]);
                  if(foundChoice){
                    
                    subAnswerContent = { 
                      id: foundChoice.value,
                      text: foundChoice.text
                    };
                  }
                }
              }
              else if(foundColumn.cellType === "boolean"){
                answerContent = { 
                  id: foundColumn.answerId,
                  checked: data[answer]
                };
              }
              else if(foundQuestion.type === "file"){
                answerContent = { 
                  id: foundColumn.answerId,
                  file: data[answer]
                };
              }
              else{
                subAnswerContent = { 
                  id: foundColumn.answerId,
                  text: rowValues[columnNames[c]]
                };
              }

              rowContent.push({
                column: columnNames[c],
                content: subAnswerContent
              });
            }

            answerContentArray.push({
              row: rowNames[r],
              values: rowContent
            });
          }

          answerContent = answerContentArray;
        }
        else if(foundQuestion.choices){
          if(data[answer] && data[answer].length){

            var answerContentArray = [];
            for(var a in data[answer]){

              var foundChoice = foundQuestion.choices.find(c => c.value === data[answer][a]);
              if(foundChoice){
                answerContentArray.push({ 
                  id: foundChoice.value,
                  text: foundChoice.text
                });
              }
            }

            answerContent = answerContentArray;
          }
          else{

            var foundChoice = foundQuestion.choices.find(c => c.value === data[answer]);
            if(foundChoice){
              
              answerContent = { 
                id: foundChoice.value,
                text: foundChoice.text
              };
            }
          }
        }
        else if(foundQuestion.type === "boolean"){
          answerContent = { 
            id: foundQuestion.answerId,
            checked: data[answer]
          };
        }
        else if(foundQuestion.type === "file"){
          answerContent = { 
            id: foundQuestion.answerId,
            file: data[answer][0]
          };
        }
        else{
          answerContent = { 
            id: foundQuestion.answerId,
            text: data[answer]
          };
        }

        answers.push({
          questionCode: foundQuestion.code,
          content: answerContent
        });

      }

      const finalData = {
        activitySurveyId,
        surveyId: surveyInfo.surveyId,
        workflowActivityStepCompletedId,
        answers
      };

      return finalData;
    }

    const setReadOnlyQuestions = (surveyClon) => {
      var questions = [];
      surveyClon.pages.forEach(page => {
        recursiveFindQuestions(page.elements, questions);
      });

      const answers = parseOldAnswers();
      
      questions.forEach(q => {
        if(answers[q.name]){
          const conversation = surveyJson.data.find(d => d.code === q.code)?.conversation;
          
          if(!conversation){
            q.readOnly = true;
          }
        }
      });
    };

    useEffect(() => {
        if(surveyJson){
            const surveyClon = JSON.parse(JSON.stringify(surveyJson.model));
            surveyClon.showProgressBar = "top";
            surveyClon.progressBarType = "requiredQuestions";
            surveyClon.widthMode = "responsive";
            surveyClon.pagePrevText = t("app.survey.previous");
            surveyClon.pageNextText = t("app.survey.next");
            surveyClon.completeText = t("app.survey.complete");

            var anyConversation = false;
            if(isEditor && surveyJson.isReadOnly){
              surveyJson.data.forEach(q => {
                if(q.conversation){
                  anyConversation = true;
                }
              });

              setReadOnlyQuestions(surveyClon);
            }

            const surveyModel = new Model(surveyClon);

            if(surveyInfo.calculation) {
              surveyModel.calculation = surveyInfo.calculation;
            }
            else{
              surveyModel.calculation = { value: 1 };
            }
            
            if(surveyJson.data){
              surveyModel.data = parseOldAnswers();
            }


            if(!isEditor){
              surveyModel.mode = "display";
            }

            // surveyModel.onAfterRenderSurvey.add((sender, options) => {
            //   if(surveyJson){
            //       if(surveyJson.model.customClass){
            //         options.htmlElement.classList.add(surveyJson.model.customClass);
            //       }
            //       if(surveyJson.model.customStyle){
            //         options.htmlElement.style.cssText += surveyJson.model.customStyle;
            //         options.htmlElement.attributeStyleMap.append(surveyJson.model.customStyle);
            //       }
            //   }
            // });
          
            // surveyModel.onAfterRenderPage.add((sender, options) => {
            //   if(surveyJson){
            //     var page = surveyJson.model.pages.find(p => p.name === options.page.propertyHash.name);
          
            //     if(page){
            //       if(page.customStyle){
            //         options.htmlElement.style.cssText += page.customStyle;
            //       }
            //       if(page.customClass){
            //         options.htmlElement.classList.add(page.customClass);
            //       }
            //     }
            //   }
            // });
            
            // surveyModel.onAfterRenderQuestion.add((sender, options) => {
            //   if(surveyJson){
            //     var page = surveyJson.model.pages.find(p => p.name === options.question.propertyHash.parent.propertyHash.name);
            //     var question = page.elements.find(e => e.name === options.question.propertyHash.name);
                
            //     if(question.customStyle){
            //       options.htmlElement.style.cssText += question.customStyle;
            //     }
            //     if(question.customClass){
            //       options.htmlElement.classList.add(question.customClass);
            //     }
            //   }
            // });
            
            // survey.onAfterRenderQuestionInput.add((sender, options) => {
            //   if(dataSurveys){
            //     console.log("input");
            //     console.log(options.question.propertyHash.name);
            //     console.log(options.question.propertyHash.choices);
            //     console.log(options.question.propertyHash.id);
            //     console.log(sender);
            //   }
            // });
          
            // survey.onUpdateChoiceItemCss.add((sender, options) => {
            //   console.log("updatecss");
            //   console.log(options)
            // });
            // survey.onAfterRenderQuestionInput.add((sender, options) => {
            //   if(options.question.customCss){
            //     var htmlElement = options.htmlElement;
            //     htmlElement.classList.add(options.question.customCss);
            //   }
            // });

            surveyModel.onSettingQuestionErrors.add((sender, question, errors) => {
              console.log("onSettingQuestionErrors", question, errors);
          });
          
            surveyModel.onCompleting.add((sender, options) => {
              options.allowComplete = false;
              setCompleteData(sender.data);
              setOpenConfirmModal(true);
          });

          if(!surveyJson.isReadOnly && isEditor){
            surveyModel.addNavigationItem({
              id: "save-draft",
              title: t("app.survey.saveDraft"),
              action: () => {
                const data = surveyModel.getAllValues();
                const answerSurvey = getDtoFromAnswers(data);

                const finalData = {
                  answerSurvey,
                  progress: surveyModel.getProgress()
                };

                dispatch(saveDraft(finalData));
              }
            });
          }

          if(isSupervisor){
            surveyModel.addLayoutElement({
              id: "progressbar-percentage",
              component: "sv-progressbar-percentage",
              container: "right",
              data: surveyModel
            });
          }

          if(surveyInfo.percentCompleted === 1){
            
            surveyModel.onGetQuestionTitleActions.add((_, opt) => {

              if(surveyModel.data[opt.question.jsonObj.name]){
                const conversation = surveyJson.data.find(d => d.code === opt.question.jsonObj.code).conversation;
                
                const actions = [];

                if(isSupervisor && (opt.question.jsonObj.type === "text" || opt.question.jsonObj.type === "comment")){
                  actions.push({
                    onClick: () => onClickAnalyze(opt.question.jsonObj.code, opt.question.value),
                    component: 'AnalyzeButton'
                  });
                }

                if(isSupervisor || conversation){
                  actions.push({
                    hasChat: conversation,
                    openChat: () => onClickChat(opt.question.jsonObj.code, opt.question.jsonObj.title),
                    component: 'ChatButton'
                  });
                }

                opt.titleActions = actions;
              }
            });
          }

          setSurveyData(surveyModel);
        }
    }, [surveyJson]);

    useEffect(() => {
      if(surveyData){
        if(mode === 'dark')
          surveyData.applyTheme(darkTheme);
        else
          surveyData.applyTheme(lightTheme);
      }
    }, [surveyData, mode]);

  return (
    <>
        {isLoadingSurveyJson || !surveyData ? <CircularProgressWrapper /> :
        <SurveyWrapper>
          <Survey model={surveyData} />
        </SurveyWrapper>
        }

        { showChatModal &&
          <ChatModal
            open={showChatModal}
            onHideModal={onHideChatModal}
            surveyInfo={surveyInfo}
            questionCode={chatData.code}
            questionText={chatData.questionText}
            activitySurveyId={activitySurveyId}
          />
        }

        {openConfirmModal &&
          <ConfirmationDialog 
              open={openConfirmModal}
              onConfirm={handleConfirmComplete}
              onClose={handleOnCloseConfirm}
              title="Complete survey"
              body={`Sei sicuro di voler procedere? La conferma chiude il task dell’attività e verrà inviata una mail al responsabile della Campagna.`}
              color='primary'
              confirmButtonText='Complete'
          />
        }

        <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isSendingSurvey || isSavingDraft}
          >
          <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
};

export default RenderSurvey;

const SurveyWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
`;

const ScoreChartWrapper = ({surveyModel}) => {

  const { 
    saveDraftResult
  } = useSelector(({ answerSurvey }) => answerSurvey);

  const [score, setScore] = useState(surveyModel.calculation ? surveyModel.calculation.value : 1);

  useEffect(() => {
    if(saveDraftResult){
      setScore(saveDraftResult.calculation.value);
    }
  }, [saveDraftResult]);

  return(
    <ScoreChart 
      large={true}
      score={score}
      height={200}
      width={200}
    />
  );
}

class RatingScoreChart extends ReactSurveyElement {
  render() {
      return (
        <div style={{width: 300, display: 'flex', justifyContent: 'center'}}>
          <ScoreChartWrapper surveyModel={this.props.model}/>
        </div>
      );
  }
}

ReactElementFactory.Instance.registerElement("sv-progressbar-percentage", props => {
  return React.createElement(RatingScoreChart, props);
});

class ChatButton extends ReactSurveyElement {
  render() {
      const handleOnClick = () => {
        this.props.item.openChat();
      }

      console.log(this.props);

      return (
        <Tooltip title="Chat">
          <IconButton onClick={() => handleOnClick()}>
            { this.props.item.hasChat ?
            <Badge variant="dot" color="error">
              <ChatIcon color="action"/>
            </Badge> 
            : 
              <ChatIcon color="action"/>
            }
          </IconButton>
        </Tooltip>
      );
  }
}

ReactElementFactory.Instance.registerElement("ChatButton", props => {
  return React.createElement(ChatButton, props);
});


class AnalyzeButton extends ReactSurveyElement {
  render() {
      const handleOnClick = () => {
        this.props.item.onClick();
      }

      return (
        <Tooltip title="Analize sentiment">
          <IconButton onClick={() => handleOnClick()}>
              <TroubleshootIcon color="action"/>
          </IconButton>
        </Tooltip>
      );
  }
}

ReactElementFactory.Instance.registerElement("AnalyzeButton", props => {
  return React.createElement(AnalyzeButton, props);
});