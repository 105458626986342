import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  toggleChatbot,
  reset,
  getWelcomeMessageRequest,
  getWelcomeMessageSuccess,
  getWelcomeMessageError,
  sendMessageRequest,
  sendMessageSuccess,
  sendMessageError,
  awakeRequest,
  awakeSuccess,
  awakeError,
  finishWritingMessage,
  analyzeSentimentRequest,
  analyzeSentimentSuccess,
  analyzeSentimentError
} from './chatbotAction';

const defaultState = {
  show: localStorage.getItem("showChatbotOnStartup") === "true",
  requestId: null,
  status: null,
  messages: [],
  isSendingMessage: false,
  sendMessageError: null,
  isAwaking: false,
  awakeError: null,
  isLoadingWelcomeMessage: true,
  welcomeMessage: null,
  getWelcomeMessageError: null
};

export default handleActions(
  {
    [toggleChatbot](state) {
      return {
        ...state,
        show: !state.show
      };
    },
    [reset](state) {
      return {
        ...defaultState,
        welcomeMessage: state.welcomeMessage,
        isLoadingWelcomeMessage: false,
        show: true
      };
    },
    [getWelcomeMessageRequest](state) {
      return {
        ...state,
        isLoadingWelcomeMessage: true,
        getWelcomeMessageError: null,
      };
    },
    [getWelcomeMessageSuccess](state, { payload }) {
      return {
        ...state,
        welcomeMessage: payload,
        isLoadingWelcomeMessage: false,
        getWelcomeMessageError: null,
        status: payload.status
      };
    },
    [getWelcomeMessageError](state, { payload }) {
      return {
        ...state,
        isLoadingWelcomeMessage: false,
        getWelcomeMessageError: payload,
      };
    },
    [sendMessageRequest](state, { payload }) {
      const messagesClon = JSON.parse(JSON.stringify(state.messages));
      messagesClon.push({
        isMine: true,
        sendDate: new Date(),
        text: payload
      });

      return {
        ...state,
        isSendingMessage: true,
        sendMessageError: null,
        messages: messagesClon
      };
    },
    [sendMessageSuccess](state, { payload }) {
      const messagesClon = JSON.parse(JSON.stringify(state.messages));
      messagesClon.push({
        isMine: false,
        sendDate: new Date(),
        text: payload.responseMessage,
        alertIcon: payload.alertIcon,
        responseId: payload.responseId
      });

      return {
        ...state,
        isSendingMessage: false,
        sendMessageError: null,
        messages: messagesClon,
        status: payload.status
      };
    },
    [sendMessageError](state, { payload }) {
      return {
        ...state,
        isSendingMessage: false,
        sendMessageError: payload
      };
    },
    [awakeRequest](state, { payload }) {
      const messagesClon = JSON.parse(JSON.stringify(state.messages));
      messagesClon.push({
        isMine: true,
        sendDate: new Date(),
        text: "Awake!"
      });

      return {
        ...state,
        isSendingMessage: true,
        isAwaking: true,
        sendMessageError: null,
        messages: messagesClon
      };
    },
    [awakeSuccess](state, { payload }) {
      const messagesClon = JSON.parse(JSON.stringify(state.messages));
      messagesClon.push({
        isMine: false,
        sendDate: new Date(),
        text: payload.responseMessage,
        alertIcon: payload.alertIcon,
        responseId: payload.responseId
      });

      return {
        ...state,
        isSendingMessage: false,
        isAwaking: false,
        sendMessageError: null,
        messages: messagesClon,
        status: payload.status
      };
    },
    [awakeError](state, { payload }) {
      return {
        ...state,
        isSendingMessage: false,
        awakeError: payload
      };
    },
    [finishWritingMessage](state, { payload }) {
      const messagesClon = JSON.parse(JSON.stringify(state.messages));
      messagesClon[payload].finished = true;

      return {
        ...state,
        messages: messagesClon
      };
    },
    [analyzeSentimentRequest](state, { payload }) {
      const newMessages = [{
        isMine: true,
        sendDate: new Date(),
        text: payload
      }];

      return {
        ...defaultState,
        show: true,
        isSendingMessage: true,
        sendMessageError: null,
        messages: newMessages
      };
    },
    [analyzeSentimentSuccess](state, { payload }) {
      const messagesClon = JSON.parse(JSON.stringify(state.messages));
      messagesClon.push({
        isMine: false,
        sendDate: new Date(),
        text: payload.responseMessage,
        alertIcon: payload.alertIcon,
        responseId: payload.responseId
      });

      return {
        ...state,
        isSendingMessage: false,
        sendMessageError: null,
        messages: messagesClon,
        status: payload.status
      };
    },
    [analyzeSentimentError](state, { payload }) {
      return {
        ...state,
        isSendingMessage: false,
        sendMessageError: payload
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
},
    defaultState,
);