import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ADD_BUSINESS_UNIT');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const addBusinessUnitRequest = createAction('ADD_BUSINESS_UNIT_POST_REQUEST');
export const addBusinessUnitSuccess = createAction('ADD_BUSINESS_UNIT_POST_SUCCESS');
export const addBusinessUnitError = createAction('ADD_BUSINESS_UNIT_POST_ERROR');

export const addBusinessUnit = (request) => async (dispatch) => {
  try {
    dispatch(addBusinessUnitRequest());
    const resp = await instance.post(`/v1/BusinessUnit/Post`, request);

    dispatch(addBusinessUnitSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addBusinessUnitError(e.response.data.description));
    else
      dispatch(addBusinessUnitError(e.message));
  }
};

export const getBusinessUnitsRequest = createAction('ADD_BUSINESS_UNIT_GET_STAKEHOLDER_TYPES_REQUEST');
export const getBusinessUnitsSuccess = createAction('ADD_BUSINESS_UNIT_GET_STAKEHOLDER_TYPES_SUCCESS');
export const getBusinessUnitsError = createAction('ADD_BUSINESS_UNIT_GET_STAKEHOLDER_TYPES_ERROR');

export const getBusinessUnits = () => async (dispatch) => {
  try {
    dispatch(getBusinessUnitsRequest());
    const resp = await instance.get(`/v1/BusinessUnit/GetListByFilter`);

    dispatch(getBusinessUnitsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getBusinessUnitsError(e.response.data.description));
    else
      dispatch(getBusinessUnitsError(e.message));
  }
};