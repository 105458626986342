import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { Backdrop, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { useForm } from "react-hook-form";
import { IsValidEmail } from "@/utils/formValidations";
import { getCurrentTenant } from "@/reducers/currentTenant/currentTenantAction";
import { updateTenant, cleanup } from "@/reducers/tenantSetup/generalInformation/generalInformationAction";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';

const TenantGeneralInformation = () => {
  
  const { 
    activity, 
    workflowActivityStepCompletedId, 
    isEditor,
    handleRefreshProgress,
    activityType,
    handleClickNext
} = useOutletContext();

  const dispatch = useDispatch();

  const { 
      currentTenant, isLoadingCurrentTenant, getCurrentTenantError
  } = useSelector(({ currentTenant }) => currentTenant);

  const { 
    isUpdatingTenant, updatedTenant, updateTenantError
  } = useSelector(({ tenantSetupGeneralInformation }) => tenantSetupGeneralInformation);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    }
  }, []);

  const { handleSubmit, reset, control, setValue, getValues } = useForm();

  useEffect(() => {
    if(getCurrentTenantError){
        enqueueSnackbar(getCurrentTenantError, {variant: "error"});
    }
  }, [getCurrentTenantError]);

  useEffect(() => {
      if(updateTenantError){
          enqueueSnackbar(updateTenantError, {variant: "error"});
      }
  }, [updateTenantError]);

  useEffect(() => {
    if(updatedTenant){
      enqueueSnackbar(`Tenant information updated successfuly.`, {variant: "success"});
      handleRefreshProgress();
      dispatch(getCurrentTenant());
    }
  }, [updatedTenant]);

  const onSubmit = (data) => {
    data.workflowActivityId = activity.workflowActivityId;
    data.workflowActivityStepCompletedId = workflowActivityStepCompletedId;
    dispatch(updateTenant(data));
  }

  return (
      <>
        { isLoadingCurrentTenant ? <CircularProgressWrapper /> :
        <>
            <Typography variant="subtitle1" style={{ marginTop: 20 }}>Contact Information</Typography>
            <Row>
                <Col md={12}>
                    <FormTextField
                        name="name" 
                        label="Name" 
                        control={control}
                        rules={{ required: 'This field is required' }}
                        defaultValue={currentTenant.name}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormTextField
                        name="emailAddress" 
                        label="Email" 
                        control={control}
                        rules={{ 
                            required: 'This field is required',
                            validate: {
                                matchPattern: (email) => IsValidEmail(email) ||
                                "Email address must be a valid address"
                            }
                        }}
                        defaultValue={currentTenant.emailAddress}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormTextField
                        name="pecEmailAddress" 
                        label="Pec Email" 
                        control={control}
                        rules={{ 
                            required: 'This field is required',
                            validate: {
                                matchPattern: (email) => IsValidEmail(email) ||
                                "Email address must be a valid address"
                            }
                        }}
                        defaultValue={currentTenant.pecEmailAddress}
                    />
                </Col>
            </Row>
            <Row>
            <Col md={12}>
                <Divider component='div' style={{ marginTop: 20 }} />
                <Typography variant="subtitle1" style={{ marginTop: 20 }}>Billing Information</Typography>
                <Row>
                    <Col md={12}>
                        <FormTextField 
                            name="billingName" 
                            label="Billing Name" 
                            control={control} 
                            rules={{ required: 'This field is required' }}
                            defaultValue={currentTenant.billingName}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormTextField 
                            name="billingAddress" 
                            label="Billing Address" 
                            control={control} 
                            defaultValue={currentTenant.billingAddress}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormTextField 
                            name="billingCountry" 
                            label="Billing Country" 
                            control={control} 
                            defaultValue={currentTenant.billingCountry}
                        />
                    </Col>
                    <Col md={6}>
                        <FormTextField 
                            name="billingCity" 
                            label="Billing City" 
                            control={control} 
                            defaultValue={currentTenant.billingCity}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormTextField 
                            name="vat" 
                            label="VAT" 
                            control={control} 
                            defaultValue={currentTenant.vat}
                        />
                    </Col>
                    <Col md={6}>
                        <FormTextField 
                            name="vatCode" 
                            label="SDI Code" 
                            control={control} 
                            defaultValue={currentTenant.vatCode}
                        />
                    </Col>
                </Row>
            

              <Row style={{marginTop: 20}}>
                  <Col md={12}>
                      <Grid
                          container
                          justifyContent="flex-end"
                      >
                          <Grid item>
                              <Button
                                  onClick={handleSubmit(onSubmit)}
                                  variant="contained"
                              >
                                  Save
                              </Button>
                          </Grid>
                      </Grid>
                  </Col>
              </Row>
          </Col>
        </Row>
        </>
        }

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingTenant}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </>
  );
};

export default TenantGeneralInformation;