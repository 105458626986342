import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useMemo, useState } from "react";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Col, Row } from "react-bootstrap";
import RiskCalculationScore from "../../../../AssetManagement/AssetInventory/components/riskCalculationScore";
import RiskRadar from "../../../../AssetManagement/AssetInventory/components/riskRadar";

const AssetReportAccordion = ({
  asset,
  loadReport
}) => {

  const { 
      assetReports
  } = useSelector(({ auAssetReports }) => auAssetReports);

  const handleChange = (event, isExpanded) => {
    if(isExpanded && (!assetReports || !assetReports[asset.assetId])){
      loadReport();
    }
  };

  const [report, setReport] = useState(null);

  useEffect(() => {
    if(assetReports && assetReports[asset.assetId]){
      setReport(assetReports[asset.assetId]);
    }
  }, [assetReports]);

  return(
    <Accordion onChange={handleChange}>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{asset.assetName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        { !report || report.isLoading ? <CircularProgressWrapper /> :
          <Row>
            <Col md={6}>
                { report.data.map(s => 
                    <RiskCalculationScore calculation={s} />
                )}
            </Col>
            <Col md={6}>
                { report.data.length >= 3 && 
                    <RiskRadar calculations={report.data}/>
                }
            </Col>
        </Row>
        }
      </AccordionDetails>
      </Accordion>
  );
};

export default AssetReportAccordion;