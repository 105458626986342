import { Col, Row } from "react-bootstrap";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Box, Card, CardContent, Chip, Typography } from "@mui/material";
import dayjs from "dayjs";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';

const Stories = ({
    isLoading,
    stories
}) => {
    console.log(stories);
    return(
        isLoading || !stories ? <CircularProgressWrapper height={400} /> :
        <Row>
            {stories.stories.map(s => 
                <Col md={12} style={{ marginBottom: 30 }}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" fontSize={22} marginBottom={2}>{s.title}</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    marginBottom: 2,
                                    alignItems: 'center'
                                }}
                            >
                                { s.source?.name && 
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <LanguageIcon sx={{
                                            marginRight: '4px'
                                        }} />
                                        <Typography marginRight={2}>
                                            {s.source.name}
                                        </Typography>
                                    </Box>
                                }

                                { s.published_at && 
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <CalendarMonthIcon sx={{
                                            color: 'text.secondary',
                                            marginRight: '4px'
                                        }} />
                                        <Typography sx={{
                                            color: 'text.secondary',
                                            fontSize: 14
                                        }}>
                                            {dayjs(s.published_at).format('LLL')}
                                        </Typography>
                                    </Box>
                                }
                            </Box>

                            <Box sx={{
                                display: 'flex',
                            }}>
                                { s.summary.sentences && s.summary.sentences.length > 0 &&
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: "1 1 0%",
                                        paddingRight: 2
                                    }}>
                                        {s.summary.sentences.map(sentence => 
                                            <Typography marginBottom={1} fontSize={14}>
                                                {sentence}
                                            </Typography>    
                                        )}
                                    </Box>
                                }
                                { s.media && s.media.length > 0 && 
                                    <Box sx={{
                                        width: '200px'
                                    }}>
                                        <img alt="mdedia" width='100%' height='auto' src={s.media[0].url} />
                                    </Box>
                                }
                            </Box>

                            { s.entities && s.entities.length > 0 &&
                                <Box sx={{
                                    display: 'flex',
                                    marginTop: 1
                                }}>
                                    {s.entities.map(e => {
                                        if(e.title?.surface_forms?.length){
                                            const sxBackgroundColor = {};
                                            if(e.title.sentiment.polarity === "negative"){
                                                sxBackgroundColor.backgroundColor = "#DD142E";
                                            }
                                            else if(e.title.sentiment.polarity === "positive"){
                                                sxBackgroundColor.backgroundColor = "#00C3A3";
                                            }

                                            return <Chip 
                                                sx={{marginRight: 1, ...sxBackgroundColor}}
                                                label={e.title.surface_forms[0].text}
                                            />
                                        }

                                        return <></>;
                                    })}
                                </Box>
                            }
                        </CardContent>
                    </Card>
                </Col>
            )}
        </Row>
    );
};

export default Stories;