import styled from "styled-components";
import { darken } from "polished";
import {
  colorAccent,
  colorBackground,
  colorRed,
  colorRedHover,
  colorDarkRed,
  colorDustyWhite,
  colorLightText,
  colorVeryLightRed,
  colorWhite,
  backgroundLoginImage,
  backgroundLoginVideo
} from "@/utils/palette";
import {
  borderLeft,
  borderRight,
  marginLeft,
  marginRight,
  right,
  left,
  paddingLeft,
} from "@/utils/directions";
import { PasswordIcon } from "../form/Password";
import { Button } from "../Button";
import {
  FormContainer,
  FormGroup,
  FormGroupIcon,
  FormFieldButton,
  FormGroupLabel,
} from "../form/FormElements";



export const AccountWrap = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;

  z-index: -1;
`;

export const AccountWrapImage = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background: ${backgroundLoginImage};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
`;

export const AccountContent = styled.div`
  margin: auto;
  padding: 10px;
  text-align: ${left};
`;

export const AccountCard = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap:wrap;
  padding: 50px 60px;
  max-width: 530px;
  width: 100%;
  background-color: ${colorBackground};
  border-radius: 2%;
  box-shadow:
      /*bottom shadow*/ 0px 20px 20px rgba(0, 0, 0, 0.4),
    0px 5px 10px rgba(0, 0, 0, 0.4),
    /*long bottom shadow*/ 0px 70px 50px rgba(0, 0, 0, 0.6),
    /*right shadow*/ 30px 50px 50px rgba(0, 0, 0, 0.4),
    /*left shadow*/ -30px 50px 50px rgba(0, 0, 0, 0.4),
    /*right inset*/ inset 20px 0px 60px rgba(0, 0, 0, 0.3),
    /*left inset*/ inset -20px 0px 60px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 520px) {
    padding: 35px 30px;
  }
`;

export const AccountHead = styled.div`
  margin-bottom: 30px;
  ${paddingLeft}: 10px;
  ${borderLeft}: 2px solid ${colorRed};
`;

export const AccountFooter = styled.div`
  margin-top: 30px;

`;

export const AccountTitle = styled.h3``;

export const AccountLogo = styled.span`
  font-weight: 700;
  color: ${colorWhite};
`;

export const AccountLogoAccent = styled.span`
  color: ${colorRed};
`;

export const AccountOr = styled.div`
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
  position: relative;

  &:before,
  &:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: ${colorDustyWhite};
    position: absolute;
    top: 10px;
  }

  &:before {
    ${left}: 0;
  }

  &:after {
    ${right}: 0;
  }
`;

export const AccountSocial = styled.div`
  text-align: center;
`;

export const AccountPhoto = styled(AccountWrap)`
  background: url(img/404/bg_404.jpeg) no-repeat center;
  background-size: cover;

  ${FormGroupLabel} {
    color: ${colorLightText};
  }

  ${AccountCard} {
    background-color: rgba(0, 10, 16, 0.8);
    color: ${colorWhite};
  }

  ${FormGroupIcon}, ${PasswordIcon}, ${FormFieldButton} {
    background: transparent;
  }

  ${FormGroupIcon}, ${PasswordIcon}, input:not(:last-child) {
    ${borderRight}: none;
  }

  ${FormGroupIcon}, ${PasswordIcon}, ${FormFieldButton}, input {
    border-color: ${colorWhite};
  }

  ${FormFieldButton}, input {
    ${borderLeft}: none
  }

  ${FormFieldButton}.active {
    svg {
      fill: ${colorAccent};
    }
  }

  input {
    color: ${colorWhite};
    box-shadow: none;
  }

  p {
    color: ${colorWhite};
  }

  ${AccountTitle} {
    color: ${colorWhite};
  }
`;

export const AccountButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -10px;
  margin-bottom: -20px;
  ${marginRight}: 0;

  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
  }
`;

export const AccountButton = styled(Button)`
  width: 50%;
  ${marginRight}: 0;
  display: inline-block;
`;

export const AccountForgotPassword = styled.div`
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  bottom: -18px;
  ${right}: 0;

  a {
    color: ${colorRed};

    &:hover {
      color: ${colorRedHover};
      text-decoration: none;
    }
  }
`;

export const ForgotFormGroup = styled(FormGroup)`
  margin-bottom: 40px;
`;

export const AccountHaveAccount = styled.div`
  text-align: center;
  margin-top: 20px;

  a {
    color: ${colorRed};
    transition: all 0.3s;

    &:hover {
      color: ${colorRedHover};
      text-decoration: none;
    }
  }
`;

export const LoginForm = styled(FormContainer)`
  width: 100%;

  .alert-danger {
    color: ${colorDarkRed};
    background-color: ${colorVeryLightRed};
  }
  .alert {
    position: relative;
    padding: 12px 20px;
    margin-bottom: 16px;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 100%;
  }
`;

const AccountSocialButton = styled(Button)`
  display: inline-block;
  height: 38px;
  width: 38px;
  border-radius: 5px;
  padding: 9px;
  transition: all 0.3s;
  border: none;
  ${marginRight}: 10px;

  &:last-child {
    ${marginRight}: 0;
  }

  svg {
    fill: ${colorWhite};
    height: 20px;
    width: 20px;
  }
`;

export const AccountSocialButtonFacebook = styled(AccountSocialButton)`
  background: #4766a4;

  &:hover {
    background: ${darken(0.1, "#4766a4")};
  }
`;

export const AccountSocialButtonGoogle = styled(AccountSocialButton)`
  background: #c74d4d;

  &:hover {
    background: ${darken(0.1, "#c74d4d")};
  }
`;

export const AccountSocialButtonOffice = styled(AccountSocialButton)`
  background: #c46a3fff;

  &:hover {
    background: ${darken(0.1, "#c46a3fff")};
  }
`;

export const AccountSocialButtonFirebase = styled(AccountSocialButton)`
  background: #4287f5;
  padding: 0 0 0 5px;

  &:before {
    background-color: ${darken(0.1, "#4287f5")};
  }

  &:hover {
    background: ${darken(0.1, "#4287f5")};
  }
`;

export const AccountSocialButtonMetaMask = styled(AccountSocialButton)`
  background: #ececec;
  padding: 5px;
`;
export const AccountSocialButtonAuth0 = styled(AccountSocialButton)``;

export const Logo = styled.img`
  margin: 0px 12px 0px 0px;
  max-width: 18%;
  max-height: 70%;
`;

export const LogoCompany = styled.img`
  height: 35x;
  width: 50px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  color: #fff;
`;

export const FormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

export const HeaderSubhead = styled.h4`
  color: ${colorWhite};
`;
export const FooterSubhead = styled.div`
  color: ${colorWhite};
`;
export const AccountHeader = () => (
  <AccountHeaderWrapper>
    <Logo src={process.env.REACT_APP_LOGIN_LOGO} alt="Oracolo.io - Digital Governance Platform"/>
    <AccountHead>
        <AccountTitle>
            <AccountLogo style={{fontSize: '30px'}}>
            {` ${process.env.REACT_APP_LOGIN_TITLE}`}
            {" "}
            <AccountLogoAccent>{process.env.REACT_APP_LOGIN_TITLE_RED}</AccountLogoAccent>
            </AccountLogo>
        </AccountTitle>
        <HeaderSubhead style={{fontSize: '16px'}}>{process.env.REACT_APP_LOGIN_SUBTITLE}</HeaderSubhead>
    </AccountHead>
  </AccountHeaderWrapper>
);

export const AccountHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const AccountLogoFooter = () => (
  <AccountFooter>
      <FooterSubhead style={{fontSize: '12px', color: '#808080', textAlign: 'center', width: '100%'}}>Oracolo Cloud Platform - Ver. 1.1 rc March ©2024 All Copyright Reserved.<br/>Powered by <a href="meccanicamente.com">meccanicamente.com</a></FooterSubhead>
      {/* <LogoCompany src={process.env.REACT_APP_LOGIN_TENANT_LOGO} alt="meccanicamente.com"/>    */}
  </AccountFooter>
);

export const SubtitleWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const BackgroundImage = styled.img`
  display: flex;
  width: 100%;
`;