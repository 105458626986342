import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Backdrop, Box, Button, Card, CardContent, CircularProgress, IconButton, Typography} from "@mui/material"; 
import AddIcon from '@mui/icons-material/Add';
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { getAssetTypes, deleteAssetType, cleanup } from "@/reducers/assetType/view/assetTypeAction";
import MaterialReactTable from "material-react-table";
import CreateAssetTypeModal from "../../../AssetManagement/AssetTypes/components/createAssetTypeModal";
import EditAssetTypeModal from "../../../AssetManagement/AssetTypes/components/editAssetTypeModal";
import SendTestEmailModal from "../../../Settings/Options/components/sendTestEmailModal";
import { useForm } from "react-hook-form";
import { getCurrentEmailAccount, updateEmailAccount, createEmailAccount, cleanup as cleanupSMTP } from "@/reducers/options/optionsAction";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormCheckboxField } from '@/shared/components/form/material-controls/CheckboxField';
import styled from "styled-components";
import MailLockIcon from '@mui/icons-material/MailLock';

const TenantAssetTypesSMTP = () => {
  
  const { 
    activity, 
    workflowActivityStepCompletedId, 
    isEditor,
    handleRefreshProgress,
    activityType,
    handleClickNext
} = useOutletContext();

  const dispatch = useDispatch();

  const { 
    assetTypes, isFetching, error,
    isDeletingAssetType, deletedAssetType, deleteAssetTypeError, 
  } = useSelector(({ assetType }) => assetType);

  useEffect(() => {
      dispatch(getAssetTypes());

      return () => {
        dispatch(cleanup());
      }
  }, [dispatch]);

  useEffect(() => {
    if(error){
        enqueueSnackbar(error, {variant: "error"});
    }
  }, [error]);

  useEffect(() => {
    if(deleteAssetTypeError){
        enqueueSnackbar(deleteAssetTypeError, {variant: "error"});
    }
  }, [deleteAssetTypeError]);

  const onClickCreateModal = () => {
    setShowCreateModal(true);
  };

  const [showCreateModal, setShowCreateModal] = useState(false);

  const onHideCreateModal = () => {
    setShowCreateModal(false);
  };

  const onAssetTypeCreated = () => {
    setShowCreateModal(false);
    dispatch(getAssetTypes());
  }

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState(null);

  const handleClickDelete = (assetType) => {
    setEntityToDelete(assetType);
    setOpenConfirmDelete(true);
  }

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);
    dispatch(deleteAssetType(entityToDelete.id));
    setEntityToDelete(null);
  } 

  const handleOnCloseDelete = () => {
      setOpenConfirmDelete(false);
      setEntityToDelete(null);
  };

  useEffect(() => {
      if(deletedAssetType){
          enqueueSnackbar("Asset Type deleted successfully", {variant: "success"});
          dispatch(getAssetTypes());
      }
  }, [deletedAssetType]);

  // Update
  const [showAssetTypeEditModal, setShowAssetTypeEditModal] = useState(false);
  const [selectedAssetType, setSelectedAssetType] = useState(null);

  const handleClickEdit = (assetType) => {
    setSelectedAssetType(assetType);
    setShowAssetTypeEditModal(true);
  }

  const onHideAssetTypeEditModal = () => {
    setShowAssetTypeEditModal(false);
    setSelectedAssetType(null);
  };

  const onAssetTypeUpdated = () => {
    setShowAssetTypeEditModal(false);
    setSelectedAssetType(null);
    dispatch(getAssetTypes());
  }

  const columns = [
    { 
      accessorKey: 'nameTraslated',
      header: 'Name'
    },
    { 
      accessorKey: 'descriptionTraslated',
      header: 'Description'
    },
    { 
      accessorKey: 'systemVersionData', 
      header: 'System Version',
      minWidth: 150,
      flex: 0.5,
    },
    { 
      accessorKey: 'isSystem', 
      header: 'System',
      minWidth: 150,
      flex: 0.5,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }) => cell.getValue() ? 
        <DoneIcon sx={{ color: 'text.secondary' }} fontSize="small" /> 
        : 
        <CloseIcon sx={{ color: 'text.secondary' }} fontSize="small" />
    }
  ];

  const renderActionsColumn = ({ row, table }) => {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
          { !row.original.isReadOnly &&
            <IconButton
              onClick={() =>{ handleClickEdit(row.original) }}
            >
              <EditIcon />
            </IconButton>
          }
          { !row.original.isSystem && !row.original.isReadOnly &&
            <IconButton
              onClick={() => handleClickDelete(row.original)}
            >
              <DeleteIcon />
            </IconButton>
          }
        </Box>
      )
  };


  //------------------- SMTP

  const { 
    emailAccount, isLoadingEmailAccount, getEmailAccountError,
    isUpdatingEmail, updatedEmail, updateEmailError,
    isCreatingEmail, createdEmail, createEmailError
  } = useSelector(({ options }) => options);

  const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();
  const [openTestEmailModal, setOpenTestEmailModal] = useState(false);

  useEffect(() => {
      dispatch(getCurrentEmailAccount());
  
      return () => {
        dispatch(cleanupSMTP());
      }
  }, [dispatch]);

  useEffect(() => {
      if(updateEmailError){
          enqueueSnackbar(updateEmailError, {variant: "error"});
      }
  }, [updateEmailError]);
  
  useEffect(() => {
      if(createEmailError){
          enqueueSnackbar(createEmailError, {variant: "error"});
      }
  }, [createEmailError]);

  useEffect(() => {
      if(updatedEmail){
          enqueueSnackbar("SMTP settings updated successfully", {variant: "success"});
      }
  }, [updatedEmail]);

  useEffect(() => {
      if(createdEmail){
          enqueueSnackbar("SMTP settings updated successfully", {variant: "success"});
          dispatch(getCurrentEmailAccount());
      }
  }, [createdEmail]);

  const onSubmit = (data) => {
      if(emailAccount){
          data.emailAccountSettingId = emailAccount.emailAccountSettingId;
          dispatch(updateEmailAccount(data));
      }
      else{
          dispatch(createEmailAccount(data));
      }
  }

  const onClickSendTestEmail = () => {
      setOpenTestEmailModal(true);
  };

  const onHideTestEmailModal = () => {
      setOpenTestEmailModal(false);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px'
          }}>
            <Button variant="contained" color="primary"  onClick={onClickCreateModal} endIcon={<AddIcon/>}>Add Asset Type</Button>
          </Box>
        
          <CardPageGridWrap>
            <MaterialReactTable 
                data={assetTypes}
                columns={columns} 
                initialState={{ 
                  density: 'compact', 
                  pagination: { pageIndex: 0, pageSize: 100 } 
                }}
                state={{
                  isLoading: isFetching
                }}
                enableRowActions
                positionActionsColumn="last"
                renderRowActions={renderActionsColumn}
                enableExpanding
                enableExpandAll
                getSubRows={(originalRow) => originalRow.subRows}
            />
          </CardPageGridWrap>
        </Col>
      </Row>

      { showCreateModal &&
        <CreateAssetTypeModal
            onHideModal={onHideCreateModal}
            open={showCreateModal}
            onAssetTypeCreated={onAssetTypeCreated}
            assetTypes={assetTypes}
        />
      }

      { showAssetTypeEditModal &&
        <EditAssetTypeModal
            onHideModal={onHideAssetTypeEditModal}
            open={showAssetTypeEditModal}
            onAssetTypeUpdated={onAssetTypeUpdated}
            assetType={selectedAssetType}
            assetTypes={assetTypes}
        />
      }

      { openConfirmDelete &&
          <ConfirmationDialog 
              open={openConfirmDelete}
              onConfirm={handleConfirmDelete}
              onClose={handleOnCloseDelete}
              title="Delete Asset Type"
              body={`Are you sure you want to delete ${entityToDelete.name.it}?`}
              color='error'
              confirmButtonText='Delete'
          />
      }

      <Row style={{ marginTop: '20px' }}>
        <Col md={12}>
          <Card>
            <CardContent>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}>
                    <TitleContainer>
                        <MailLockIcon />
                        <Typography variant='h5'>SMTP Settings</Typography>
                    </TitleContainer>
                    <Button 
                        // disabled={!emailAccount}
                        variant="contained"
                        color="warning"
                        onClick={onClickSendTestEmail}
                    >
                        Send test email
                    </Button>
                </Box>
                {isLoadingEmailAccount ? <CircularProgressWrapper /> :        
                    <Row>
                        <Col md={4}>
                            <FormTextField
                                name="emailAddress"
                                label="Email"
                                control={control}
                                defaultValue={emailAccount?.emailAddress}
                            />
                        </Col>
                        <Col md={4}>
                            <FormTextField
                                name="userName"
                                label="Username"
                                control={control}
                                defaultValue={emailAccount?.userName}
                            />
                        </Col>                                    
                        <Col md={4}>
                            <FormTextField
                                name="password"
                                label="Password"
                                control={control}
                                defaultValue={emailAccount?.password}
                                type="password"
                            />
                        </Col>
                        <Col md={4}>
                            <FormTextField
                                name="smtpUrl"
                                label="SMTP Server"
                                control={control}
                                defaultValue={emailAccount?.smtpUrl}
                            />
                        </Col>
                        <Col md={4}>
                            <FormTextField
                                name="smtpPort"
                                label="SMTP Port"
                                control={control}
                                type="number"
                                defaultValue={emailAccount?.smtpPort}
                            />
                        </Col>
                        <Col md={4}>
                            <FormCheckboxField
                                name="smtpUseSSL"
                                label="Use SMTP SSL"
                                control={control}
                                defaultValue={emailAccount?.smtpUseSSL}
                                style={{marginTop: '20px'}}
                            />
                        </Col>

                        <Col md={12}>
                            <Box sx={{
                                display: 'flex', 
                                justifyContent: 'flex-end',
                                marginTop: '20px'
                            }}>
                                <Button 
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Update settings
                                </Button>
                            </Box>
                        </Col>
                    </Row>
                }
            </CardContent>
        </Card>
      </Col>
    </Row>

    { openTestEmailModal && 
        <SendTestEmailModal 
            open={openTestEmailModal}
            onHideModal={onHideTestEmailModal}
        />
    }

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} 
          open={isDeletingAssetType || isUpdatingEmail || isCreatingEmail}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;

  & > svg{
    margin-right: 5px;
    font-size: 30px;
  }
`;

export default TenantAssetTypesSMTP;