import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_AU_UPDATE_ASSET');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateAssetRequest = createAction('AU_UPDATE_ASSET_REQUEST');
export const updateAssetSuccess = createAction('AU_UPDATE_ASSET_SUCCESS');
export const updateAssetError = createAction('AU_UPDATE_ASSET_ERROR');

export const updateAsset = (data) => async (dispatch) => {
  try {
    dispatch(updateAssetRequest());
    const resp = await instance.post(`/v1/ActivityCampaign/AddOrUpdateActivitySurvey`, data);
    
    dispatch(updateAssetSuccess(resp.data.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateAssetError(e.response.data.description));
    else
      dispatch(updateAssetError(e.message));
  }
};

export const getRolesWithUsersRequest = createAction('AU_UPDATE_ASSET_GET_USERS_WITH_ROLES_REQUEST');
export const getRolesWithUsersSuccess = createAction('AU_UPDATE_ASSET_GET_USERS_WITH_ROLES_SUCCESS');
export const getRolesWithUsersError = createAction('AU_UPDATE_ASSET_GET_USERS_WITH_ROLES_ERROR');

export const getRolesWithUsers = (roles, stakeholderId) => async (dispatch) => {
  try {
    dispatch(getRolesWithUsersRequest());
    
    const resp = await instance.post(`/v1/UserAndRole/GetUsersByRoleIds`, { 
      roleIds: roles,
      stakeholderId: stakeholderId
    });
    
    dispatch(getRolesWithUsersSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRolesWithUsersError(e.response.data.description));
    else
      dispatch(getRolesWithUsersError(e.message));
  }
};