import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import TenantLocationsList from "./components/tenantLocationsList";

const TenantLocations = () => {
  
  const { 
    activity, 
    workflowActivityStepCompletedId, 
    isEditor,
    handleRefreshProgress,
    activityType,
    handleClickNext
} = useOutletContext();

  const dispatch = useDispatch();

  const { 
      currentTenant, isLoadingCurrentTenant, getCurrentTenantError,
  } = useSelector(({ currentTenant }) => currentTenant);

  useEffect(() => {
    if(getCurrentTenantError){
        enqueueSnackbar(getCurrentTenantError, {variant: "error"});
    }
  }, [getCurrentTenantError]);

  return (
    isLoadingCurrentTenant ? <CircularProgressWrapper /> :
      <>
        <TenantLocationsList 
          assetId={currentTenant.assetId}
          workflowActivityId={activity.workflowActivityId}
          workflowActivityStepCompletedId={workflowActivityStepCompletedId}
          handleRefreshProgress={handleRefreshProgress}
        />
      </>
  );
};

export default TenantLocations;