import { createAction } from 'redux-actions';
import { instance } from "@/config/interceptor";

export const cleanupAction = createAction('CLEANUP_AU_REPORT');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getAssetReportRequest = createAction('AU_REPORT_GET_ASSET_REPORT_REQUEST');
export const getAssetReportSuccess = createAction('AU_REPORT_GET_ASSET_REPORT_SUCCESS');
export const getAssetReportError = createAction('AU_REPORT_GET_ASSET_REPORT_ERROR');

export const getAssetReport = (campaignId, assetId) => async (dispatch) => {
  try {
    dispatch(getAssetReportRequest({ assetId: assetId }));
    const resp = await instance.get(`/v1/Asset/GetCalculationByCampaignAndAsset?campaignId=${campaignId}&assetId=${assetId}`);
    
    dispatch(getAssetReportSuccess({ assetId: assetId, data: resp.data }));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAssetReportError({ assetId: assetId, error: e.response.data.description }));
    else
      dispatch(getAssetReportError({ assetId: assetId, error: e.message }));
  }
};