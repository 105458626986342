import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Modal, TextField, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import dayjs from "dayjs";
import { useId } from "react";

const ScheduleSendModal = ({
  onHideModal, 
  showModal, 
  onClickSchedule
}) => {

  const handleClose = (event, reason) => {
    onHideModal();
  }
  return(
    <Modal
      open={showModal}
      onClose={handleClose}
    >
      <Box style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <StaticDateTimePicker 
          orientation="landscape" 
          defaultValue={dayjs(new Date())} 
          onAccept={() => onClickSchedule()}
          slots={{
            actionBar: CustomActionBar,
            onCustomClose: handleClose
          }}
        />
      </Box>
    </Modal>
      // <Dialog
      //     fullWidth={true}
      //     maxWidth='xs'
      //     scroll='paper'
      //     open={showModal}
      //     onClose={handleClose}
      // >
      //     <DialogTitle>Schedule Surveys</DialogTitle>
      //     <DialogContent>
      //       <Row>
      //         <Col md={12}>
      //           <StaticDateTimePicker />
      //         </Col>
      //       </Row>
          
      //     </DialogContent>
      //     <DialogActions>
      //         <Button onClick={handleClose}>Close</Button>
      //         <Button 
      //             variant="contained" 
      //             onClick={onClickSchedule}
      //         >
      //           Schedule
      //         </Button>
      //     </DialogActions>
      // </Dialog>
  );
};

function CustomActionBar(props) {
  const { onAccept, onClear, onCancel, onSetToday, actions, className } = props;

  return (
    <DialogActions className={className}>
      <Button
        onClick={() => props.ownerState.slots.onCustomClose()}
      >
        Cancel
      </Button>
      <Button
        onClick={() => onAccept()}
      >
        Ok
      </Button>
    </DialogActions>
  );
}

export default ScheduleSendModal;