import { Card } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import ReactECharts from 'echarts-for-react';
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import dayjs from "dayjs";

const SentimentOverTime = ({
    isLoading,
    data,
    entity
}) => {
    const {theme} = useSelector(({changeTheme}) => changeTheme);

    const options = useMemo(() => {
        if(data && data.positive){

            const dates = data.positive.time_series.map((t, index) => {
                return dayjs(t.published_at).format('D MMM YY');
            });

            return {
                title: {
                    text: "Sentiment Over Time (" + entity + ")"
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    left: 'right'
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: {
                  type: 'category',
                  data: dates
                },
                yAxis: {
                  type: 'value'
                },
                series: [
                    {
                        name: 'Negative',
                        type: 'line',
                        stack: 'total',
                        data: data.negative.time_series.map(t => t.count),
                        itemStyle: { color: "#DD142E"}
                    },
                    {
                        name: 'Positive',
                        type: 'line',
                        stack: 'total',
                        data: data.positive.time_series.map(t => t.count),
                        itemStyle: { color: "#00C3A3"}
                    },
                    {
                        name: 'Neutral',
                        type: 'line',
                        stack: 'total',
                        data: data.neutral.time_series.map(t => t.count),
                        itemStyle: { color: "#B1B1B1"}
                    }
                ]
              };
        }
        else{
            return null;
        }
    }, [data]);

    return(
        
        <Card sx={{
            padding: 2
        }}>
            {isLoading || !data || !data.positive ? <CircularProgressWrapper height={400} /> :
                <ReactECharts
                    theme={theme}
                    style={{height: 400}}
                    notMerge={true}
                    option={options}
                />
            }
        </Card>
    );
};

export default SentimentOverTime;