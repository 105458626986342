import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAuditCampaignRequest,
  getAuditCampaignSuccess,
  getAuditCampaignError,
  getSurveysRequest,
  getSurveysSuccess,
  getSurveysError,
  getWorkflowRolesRequest,
  getWorkflowRolesSuccess,
  getWorkflowRolesError,
  getAssetsRequest,
  getAssetsSuccess,
  getAssetsError,
  getRolesAndUsersRequest,
  getRolesAndUsersSuccess,
  getRolesAndUsersError,
  sendSurveysRequest,
  sendSurveysSuccess,
  sendSurveysError
} from './AUSendSurveysAction';

const defaultState = {
  auditCampaign: null, 
  isLoadingAuditCampaign: true, 
  getAuditCampaignError: null,
  surveys: [],
  isLoadingSurveys: true,
  getSurveysError: null,
  roles: null,
  isLoadingRoles: true,
  getRolesError: null,
  assets: [],
  isLoadingAssets: true,
  getAssetsError: null,
  rolesAndUsers: null,
  isLoadingRolesAndUsers: true,
  getRolesAndUsersError: null,
  isSendingSurveys: false,
  sentSurveys: false,
  sendSurveysError: null
};

export default handleActions(
  {
    [getAuditCampaignRequest](state) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: true,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignSuccess](state, { payload }) {
      return {
        ...state,
        auditCampaign: payload,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignError](state, { payload }) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: payload,
      };
    },
    [getSurveysRequest](state) {
      return {
        ...state,
        surveys: [],
        isLoadingSurveys: true,
        getSurveysError: null,
      };
    },
    [getSurveysSuccess](state, { payload }) {
      return {
        ...state,
        surveys: payload,
        isLoadingSurveys: false,
        getSurveysError: null,
      };
    },
    [getSurveysError](state, { payload }) {
      return {
        ...state,
        surveys: [],
        isLoadingSurveys: false,
        getSurveysError: payload,
      };
    },
    [getWorkflowRolesRequest](state) {
      return {
        ...state,
        isLoadingRoles: true,
        getRolesError: null,
      };
    },
    [getWorkflowRolesSuccess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isLoadingRoles: false,
        getRolesError: null,
      };
    },
    [getWorkflowRolesError](state, { payload }) {
      return {
        ...state,
        isLoadingRoles: false,
        getRolesError: payload,
      };
    },
    [getAssetsRequest](state) {
      return {
        ...state,
        assets: [],
        isLoadingAssets: true,
        getAssetsError: null,
      };
    },
    [getAssetsSuccess](state, { payload }) {
      return {
        ...state,
        assets: payload,
        isLoadingAssets: false,
        getAssetsError: null,
      };
    },
    [getAssetsError](state, { payload }) {
      return {
        ...state,
        assets: [],
        isLoadingAssets: false,
        getAssetsError: payload,
      };
    },
    [getRolesAndUsersRequest](state) {
      return {
        ...state,
        isLoadingRolesAndUsers: true,
        getRolesAndUsersError: null,
      };
    },
    [getRolesAndUsersSuccess](state, { payload }) {
      return {
        ...state,
        rolesAndUsers: payload,
        isLoadingRolesAndUsers: false,
        getRolesAndUsersError: null,
      };
    },
    [getRolesAndUsersError](state, { payload }) {
      return {
        ...state,
        isLoadingRolesAndUsers: false,
        getRolesAndUsersError: payload,
      };
    },
    [sendSurveysRequest](state) {
      return {
        ...state,
        isSendingSurveys: true,
        sendSurveysError: null,
        sentSurveys: false
      };
    },
    [sendSurveysSuccess](state, { payload }) {
      return {
        ...state,
        isSendingSurveys: false,
        sendSurveysError: null,
        sentSurveys: true
      };
    },
    [sendSurveysError](state, { payload }) {
      return {
        ...state,
        isSendingSurveys: false,
        sendSurveysError: payload,
        sentSurveys: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);