import { Divider, Typography} from "@mui/material"; 
import { useForm } from "react-hook-form";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { Col, Container, Row } from "react-bootstrap";

const OrganizationGeneralInfo = ({
    organization
}) => {

    const { handleSubmit, reset, setValue, watch, control, getValues } = useForm();
    
    return(
        <>
            <Row>
                <Col md={12}>
                    <FormTextField
                        name="name" 
                        label="Name" 
                        control={control}
                        defaultValue={organization.name}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormTextField
                        name="emailAddress" 
                        label="Email" 
                        control={control}
                        defaultValue={organization.emailAddress}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormTextField
                        name="pecEmailAddress" 
                        label="Pec Email" 
                        control={control}
                        defaultValue={organization.pecEmailAddress}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default OrganizationGeneralInfo;