import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  deleteBusinessSectorRequest,
  deleteBusinessSectorSuccess,
  deleteBusinessSectorError,
  getNaicsRequest,
  getNaicsSuccess,
  getNaicsError
} from './stakeholderBusinessSectorsAction';

const defaultState = {
  isDeletingBusinessSector: false,
  deleteBusinessSectorsError: null,
  deletedBusinessSector: false,
  naics: null,
  isLoadingNaics: true,
  getNaicsError: null
};

export default handleActions(
  {
    [getNaicsRequest](state) {
      return {
        ...state,
        naics: null,
        isLoadingNaics: true,
        getNaicsError: null,
      };
    },
    [getNaicsSuccess](state, { payload }) {
      return {
        ...state,
        naics: payload,
        isLoadingNaics: false,
        getNaicsError: null,
      };
    },
    [getNaicsError](state, { payload }) {
      return {
        ...state,
        naics: null,
        isLoadingNaics: false,
        getNaicsError: payload,
      };
    },
    [deleteBusinessSectorRequest](state) {
      return {
        ...state,
        isDeletingBusinessSector: true,
        deletedBusinessSector: false,
        deleteBusinessSectorsError: null,
      };
    },
    [deleteBusinessSectorSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingBusinessSector: false,
        deletedBusinessSector: true,
        deleteBusinessSectorsError: null,
      };
    },
    [deleteBusinessSectorError](state, { payload }) {
      return {
        ...state,
        isDeletingBusinessSector: false,
        deletedBusinessSector: false,
        deleteBusinessSectorsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);