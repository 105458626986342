import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_STAKEHOLDER_ACTIVITIES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getStakeholderActivitiesRequest = createAction('GET_STAKEHOLDER_ACTIVITIES_REQUEST');
export const getStakeholderActivitiesSuccess = createAction('GET_STAKEHOLDER_ACTIVITIES_SUCCESS');
export const getStakeholderActivitiesError = createAction('GET_STAKEHOLDER_ACTIVITIES_ERROR');

export const getStakeholderActivities = (data) => async (dispatch) => {
  try {
    dispatch(getStakeholderActivitiesRequest());
    const resp = await instance.get(`/v1/Workflow/GetActivityChildren`, { 
      params: { ...data }
    });
    
    if(resp.data.isSuccessed)
      dispatch(getStakeholderActivitiesSuccess(resp.data.data));
    else
      dispatch(getStakeholderActivitiesError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getStakeholderActivitiesError(e.response.data.description));
    else
      dispatch(getStakeholderActivitiesError(e.message));
  }
};