import { Backdrop, Box, Button, CircularProgress, Container, Icon, Paper, Step, StepContent, StepLabel, Stepper, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { getParameterUnits, getParameterTypes, complete, cleanup } from "@/reducers/selfAssessment/carbonFootprintSurvey/carbonFootprintSurveyAction";
import { enqueueSnackbar } from "notistack";
import EnergyTab from "./energyTab";
import FlightsTab from "./flightsTab";
import CalculateIcon from '@mui/icons-material/Calculate';
import CarsTab from "./carsTab";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';

const tabs = [
    {
        label: 'Consumi',
        icon: 'energy_savings_leaf',
        title: 'Calcolatore dei consumi',
        description: `Indicare il consumo per ogni tipologia di fonte energetica.`,
    },
    {
        label: 'Voli',
        icon: 'flight',
        title: "Calcolatore dell'impronta ecologica dei voli",
        description: 'Si possono inserire informazioni per un massimo di 3 itinerari di volo',
    },
    {
        label: 'Auto',
        icon: 'directions_car',
        title: "Calcolatore dell'impronta ecologica delle auto",
        description: `Si possono inserire informazioni per un massimo di 2 auto`,
    },
  ];

const CarbonFootprintSurvey = ({
    activitySurvey,
    surveyInfo,
    activitySurveyId,
    isSupervisor,
    workflowActivityStepCompletedId,
    onComplete
}) => {
    const dispatch = useDispatch();

    const { 
        parameterUnits, isLoadingParameterUnits, getParameterUnitsError,
        parameterTypes, isLoadingParameterTypes, getParameterTypesError,
        isCompleting, completed, completeError
    } = useSelector(({ carbonFootprintSurvey }) => carbonFootprintSurvey);

    const { handleSubmit, reset, setValue, control, getValues } = useForm();

    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        dispatch(getParameterUnits());
        dispatch(getParameterTypes());
        
        setValue("airs", activitySurvey?.emissionCo2?.emissionData?.travel?.airs);
        setValue("cars", activitySurvey?.emissionCo2?.emissionData?.travel?.cars);
        setFlights(activitySurvey?.emissionCo2?.emissionData?.travel?.airs);
        setCars(activitySurvey?.emissionCo2?.emissionData?.travel?.cars);

        return () => {
            dispatch(cleanup());
        }
    }, []);

    useEffect(() => {
        if(getParameterUnitsError){
          enqueueSnackbar(getParameterUnitsError, {variant: "error"});
        }
    }, [getParameterUnitsError]);

    useEffect(() => {
        if(getParameterTypesError){
          enqueueSnackbar(getParameterTypesError, {variant: "error"});
        }
    }, [getParameterTypesError]);

    useEffect(() => {
        if(completeError){
          enqueueSnackbar(completeError, {variant: "error"});
        }
    }, [completeError]);

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [flights, setFlights] = useState([]);
    const [cars, setCars] = useState([]);

    const onFlightAdd = (flight) => {
        const flights = getValues("airs");
        const flightsClone = flights && flights.length ? [...flights] : [];

        flightsClone.push(flight);
        setValue("airs", flightsClone);
        setFlights(flightsClone);
    };
    
    const onFlightRemove = (flight) => {
        const flightsClone = [...getValues("airs")];
        const index = flightsClone.indexOf(flight);
        flightsClone.splice(index, 1);
        
        setValue("airs", flightsClone);
        setFlights(flightsClone);
    };

    const onCarAdd = (car) => {
        const cars = getValues("cars");
        const carsClone = cars && cars.length ? [...cars] : [];

        carsClone.push(car);
        setValue("cars", carsClone);
        setCars(carsClone);
    };

    const onCarRemove = (car) => {
        const carsClone = [...getValues("cars")];
        const index = carsClone.indexOf(car);
        carsClone.splice(index, 1);
        
        setValue("cars", carsClone);
        setCars(carsClone);
    };

    useEffect(() => {
        if(completed){
            enqueueSnackbar("Survey saved successfully", {variant: "success"});
            onComplete(surveyInfo.surveyId);
        }
    }, [completed]);

    const handleConfirmComplete = () => {
        const finalData = {
            id: activitySurveyId,
            workflowActivityStepCompletedId,
            surveyId: surveyInfo.surveyId,
            emissionData: {
                energy: {
                    electricityValue: formData.electricityValue,
                    electricityUnitId: formData.electricityUnitId,
                    naturalGasValue: formData.naturalGasValue,
                    naturalGasUnitId: formData.naturalGasUnitId,
                    propaneValue: formData.propaneValue,
                    propaneUnitId: formData.propaneUnitId,
                    lpgValue: formData.lpgValue,
                    lpgUnitId: formData.lpgUnitId,
                    coalValue: formData.coalValue,
                    coalUnitId: formData.coalUnitId,
                    woodenPelletValue: formData.woodenPelletValue,
                    woodenPelletUnitId: formData.woodenPelletUnitId
                },
                travel: {
                    airs: formData.airs,
                    cars: formData.cars
                }
            }
        };

        dispatch(complete(finalData));
        
        setOpenConfirmModal(false);
        setFormData(null);
    }
  
    const handleOnCloseConfirm = () => {
        setOpenConfirmModal(false);
        setFormData(null);
    };

    const onSubmit = (data) => {
        setFormData(data);
        setOpenConfirmModal(true);
    }
  
    return (
        isLoadingParameterUnits || isLoadingParameterTypes ? <CircularProgressWrapper /> :

        <Box sx={{ width: '100%', paddingTop: '10px' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" centered>
                <Tab label={tabs[0].label} {...a11yProps(0)} />
                <Tab label={tabs[1].label} {...a11yProps(1)} />
                <Tab label={tabs[2].label} {...a11yProps(2)} />
            </Tabs>
            
            <CustomTabPanel 
                value={tabValue} 
                index={0}
                icon={tabs[0].icon}
                title={tabs[0].title}
                description={tabs[0].description}
            >
                <EnergyTab 
                    control={control}
                    parameterUnits={parameterUnits}
                    emissionCo2={activitySurvey.emissionCo2}
                />
            </CustomTabPanel>

            <CustomTabPanel 
                value={tabValue} 
                index={1}
                icon={tabs[1].icon}
                title={tabs[1].title}
                description={tabs[1].description}
            >
                <FlightsTab 
                    onFlightAdd={onFlightAdd}
                    onFlightRemove={onFlightRemove}
                    flights={flights}
                    parameterTypes={parameterTypes}
                />
            </CustomTabPanel>

            <CustomTabPanel 
                value={tabValue} 
                index={2}
                icon={tabs[2].icon}
                title={tabs[2].title}
                description={tabs[2].description}
            >
                <CarsTab 
                    onCarAdd={onCarAdd}
                    onCarRemove={onCarRemove}
                    cars={cars}
                    parameterTypes={parameterTypes}
                />
            </CustomTabPanel>

            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '20px'
            }}>
                <Button variant="contained" color="co2GreenColor" size="large" onClick={handleSubmit(onSubmit)}>Save</Button>
            </Box>

            {openConfirmModal &&
                <ConfirmationDialog 
                    open={openConfirmModal}
                    onConfirm={handleConfirmComplete}
                    onClose={handleOnCloseConfirm}
                    title="Complete survey"
                    body={`Sei sicuro di voler procedere? La conferma chiude il task dell’attività e verrà inviata una mail al responsabile della Campagna.`}
                    color='primary'
                    confirmButtonText='Complete'
                />
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isCompleting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

  function CustomTabPanel(props) {
    const { children, value, index, icon, title, description, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Box sx={{
                paddingTop: '20px',
                width: '100%'
            }}>                    
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        marginTop: '20px',
                        marginBottom: '30px'
                    }}>
                        <Icon sx={{fontSize: "50px", marginRight: '10px', color: 'co2GreenColor.main'}}>{icon}</Icon>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Typography variant="h5" color='co2GreenColor.main'>{title}</Typography>
                            <Typography variant="subtitle1" color='text.secondary' fontSize='14px'>{description}</Typography>
                        </Box>
                    </Box>
                    
                    <Container maxWidth="lg">
                        {children}
                    </Container>

            </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

export default CarbonFootprintSurvey;