import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getAuditCampaign, getCampaignReport, cleanup } from "@/reducers/auditCampaign/report/AUReportAction";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import DoughnutChart from "@/shared/components/charts/DoughnutChart";
import AssetReportAccordion from "./components/assetReportAccordion";
import { Divider } from "@mui/material";
import CampaignRatingScoreChart from "./components/campaignRatingScoreChart";
import { getAssetReport } from "@/reducers/auditCampaign/report/assetReports/AUAssetReportsAction";

const AUCampaignReport = () => {

    const { 
        activity, 
        workflowActivityStepCompletedId, 
        isEditor,
        handleRefreshProgress
    } = useOutletContext();

    const dispatch = useDispatch();
    
    const { 
        auditCampaign, isLoadingAuditCampaign, getAuditCampaignError,
        report, isLoadingReport, getReportError
    } = useSelector(({ auReport }) => auReport);

    useEffect(() => {
        dispatch(getAuditCampaign(activity.workflowActivityId));

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getAuditCampaignError){
          enqueueSnackbar(getAuditCampaignError, {variant: "error"});
        }
    }, [getAuditCampaignError]);

    useEffect(() => {
        if(getReportError){
          enqueueSnackbar(getReportError, {variant: "error"});
        }
    }, [getReportError]);

    useEffect(() => {
        if(auditCampaign){
            dispatch(getCampaignReport(auditCampaign.id));
        }
    }, [auditCampaign]);

    const loadReport = (assetId) => {
        dispatch(getAssetReport(auditCampaign.id, assetId));
    };

    return(
        <Row>
            {isLoadingAuditCampaign || !auditCampaign ? <CircularProgressWrapper /> :
                <>
                    <Col md={12}>
                        { isLoadingReport || !report ? <CircularProgressWrapper /> :
                            <CampaignRatingScoreChart 
                                data={report}
                            />
                        }
                    </Col>

                    <Col md={12}>
                        <Divider component='div' sx={{ marginBlock: 2 }} />
                    </Col>
                    
                    <Col md={12}>
                        { auditCampaign.campaignAssets.map((a) => 
                            <AssetReportAccordion 
                                asset={a}
                                loadReport={() => loadReport(a.assetId)}
                            />
                        )}
                    </Col>
                </>
            }
        </Row>
    );
};

export default AUCampaignReport;