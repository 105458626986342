import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { FormSelectField } from "@/shared/components/form/material-controls/SelectField";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { updateBusinessUnit, getBusinessUnit, getBusinessUnits, cleanup } from "@/reducers/businessUnits/updateBusinessUnit/updateBusinessUnitAction";

const UpdateBusinessUnit = () => {
    const { id } = useParams();
    const { t } = useTranslation("common");

    const dispatch = useDispatch();
    const { 
      businessUnit, isLoadingBusinessUnit, getBusinessUnitError,
      isUpdatingBusinessUnit, updatedBusinessUnit, updateBusinessUnitError,
      businessUnits, isLoadingBusinessUnits, getBusinessUnitsError
    } = useSelector(({ updateBusinessUnit }) => updateBusinessUnit);

    const navigate = useNavigate();
    const { handleSubmit, watch, getValues, reset, control, setValue } = useForm();

    useEffect(() => {
      dispatch(getBusinessUnit(id));
      dispatch(getBusinessUnits());

      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
    
    useEffect(() => {
        if(getBusinessUnitError){
            enqueueSnackbar(getBusinessUnitError, {variant: "error"});
        }
    }, [getBusinessUnitError]);

    useEffect(() => {
      if(getBusinessUnitsError){
      enqueueSnackbar(getBusinessUnitsError, {variant: "error"});
      }
  }, [getBusinessUnitsError]);

  useEffect(() => {
    if(updateBusinessUnitError){
    enqueueSnackbar(updateBusinessUnitError, {variant: "error"});
    }
}, [updateBusinessUnitError]);

    useEffect(() => {
        if(updatedBusinessUnit){
          enqueueSnackbar(`Business Unit updated successfuly.`, {variant: "success"});
          navigate(-1);
        }
    }, [updatedBusinessUnit]);

    const onSubmit = (data) => {
      data.businessUnitId = id;
      dispatch(updateBusinessUnit(data));
    }

    const onClickCancel = () => {
        navigate(-1);
    }

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageTitleWrap>
                        <CardPageTitle>Update Business Unit</CardPageTitle>
                    </CardPageTitleWrap>
                    { isLoadingBusinessUnit || isLoadingBusinessUnits ? <CircularProgressWrapper /> : 
                      <>
                        <Row>
                            <Col md={12}>
                                <FormTextField 
                                    name="name" 
                                    label="Name" 
                                    control={control} 
                                    rules={{ required: 'This field is required' }}
                                    defaultValue={businessUnit.name}
                                />
                            </Col>
                            <Col md={12}>
                                <FormTextField 
                                    name="description" 
                                    label="Description" 
                                    control={control} 
                                    defaultValue={businessUnit.description}
                                />
                            </Col>
                            <Col md={12}>
                                <FormSelectField 
                                    name="businessUnitParentId" 
                                    label="Parent Business Unit" 
                                    control={control}
                                    options={businessUnits.filter(b => b.businessUnitId !== businessUnit.businessUnitId) ?? []}
                                    keyValue={{id: "businessUnitId", label: "name"}}
                                    defaultValue={businessUnit.businessUnitParentId}
                                />
                            </Col>
                        </Row>
                        
                        <Row style={{marginTop: 20}}>
                            <Col md={12}>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Button
                                            onClick={onClickCancel}
                                            variant="outlined"
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={handleSubmit(onSubmit)}
                                            variant="contained"
                                        >
                                            Update
                                        </Button>
                                    </Grid>
                                    
                                </Grid>
                            </Col>
                        </Row>
                      </>
                    }
                </CardPageBody>
            </CardPage>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUpdatingBusinessUnit}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default UpdateBusinessUnit;