import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateTenantRequest,
  updateTenantSuccess,
  updateTenantError,
} from './generalInformationAction';

const defaultState = {
  isUpdatingTenant: false,
  updateTenantError: null,
  updatedTenant: false,
};

export default handleActions(
  {
    [updateTenantRequest](state) {
        return {
            ...state,
            isUpdatingTenant: true,
            updateTenantError: null,
            updatedTenant: false
        };
    },
    [updateTenantSuccess](state, { payload }) {
        return {
            ...state,
            isUpdatingTenant: false,
            updateTenantError: null,
            updatedTenant: true
        };
    },
    [updateTenantError](state, { payload }) {
        return {
            ...state,
            isUpdatingTenant: false,
            updateTenantError: payload,
            updatedTenant: false
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);