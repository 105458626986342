import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_TENANT_SETUP_ADD_LOCATION');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const addLocationRequest = createAction('TENANT_SETUP_ADD_LOCATION_POST_REQUEST');
export const addLocationSuccess = createAction('TENANT_SETUP_ADD_LOCATION_POST_SUCCESS');
export const addLocationError = createAction('TENANT_SETUP_ADD_LOCATION_POST_ERROR');

export const addLocation = (request) => async (dispatch) => {
  try {
    dispatch(addLocationRequest());
    const resp = await instance.post(`/v1/TenantSetup/AddLocation`, request);

    dispatch(addLocationSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addLocationError(e.response.data.description));
    else
      dispatch(addLocationError(e.message));
  }
};
