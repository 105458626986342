import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import StakeholderForm from "./stakeholderForm";
import { complete, cleanup } from "@/reducers/selfAssessment/stakeholderInformationSurvey/stakeholderInformationSurveyAction";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';

const StakeholderInformationSurvey = ({
    activitySurvey,
    surveyInfo,
    activitySurveyId,
    isSupervisor,
    workflowActivityStepCompletedId,
    onComplete
}) => {
    const dispatch = useDispatch();

    const { 
        isCompleting, completedResult, completeError
    } = useSelector(({ stakeholderInformationSurvey }) => stakeholderInformationSurvey);

    const { handleSubmit, reset, setValue, watch, control, getValues } = useForm();

    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        return () => {
            dispatch(cleanup());
        }
    }, []);

    useEffect(() => {
        if(completeError){
          enqueueSnackbar(completeError, {variant: "error"});
        }
    }, [completeError]);

    useEffect(() => {
        if(completedResult){
            enqueueSnackbar("Survey saved successfully", {variant: "success"});
            onComplete(surveyInfo.surveyId, completedResult);
        }
    }, [completedResult]);

    const handleOnCloseConfirm = () => {
        setOpenConfirmModal(false);
        setFormData(null);
    };

    const handleConfirmComplete = () => {
        const finalData = {
            id: activitySurveyId,
            supplierInfo: formData,
            workflowActivityStepCompletedId,
            surveyId: surveyInfo.surveyId
        };

        dispatch(complete(finalData));
        
        setOpenConfirmModal(false);
        setFormData(null);
    }

    const onSubmit = (data) => {
        setFormData(data);
        setOpenConfirmModal(true);
    }

    return (
        <Box sx={{ width: '100%', paddingTop: '10px' }}>
            <StakeholderForm 
                stakeholderInfo={activitySurvey.supplierInfo}
                control={control}
                setValue={setValue}
                watch={watch}
            />

            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '20px'
            }}>
                <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)}>Save</Button>
            </Box>

            {openConfirmModal &&
                <ConfirmationDialog 
                    open={openConfirmModal}
                    onConfirm={handleConfirmComplete}
                    onClose={handleOnCloseConfirm}
                    title="Complete survey"
                    body={`Sei sicuro di voler procedere? La conferma chiude il task dell’attività e verrà inviata una mail al responsabile della Campagna.`}
                    color='primary'
                    confirmButtonText='Complete'
                />
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isCompleting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
};

export default StakeholderInformationSurvey;