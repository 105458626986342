import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { complete, cleanup } from "@/reducers/selfAssessment/stakeholderLocationsSurvey/stakeholderLocationsSurveyAction";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import LocationsList from "./LocationsList";

const StakeholderLocationsSurvey = ({
    activitySurvey,
    surveyInfo,
    activitySurveyId,
    isSupervisor,
    workflowActivityStepCompletedId,
    onComplete
}) => {

    const dispatch = useDispatch();

    const { 
        isCompleting, completedResult, completeError
    } = useSelector(({ stakeholderLocationsSurvey }) => stakeholderLocationsSurvey);

    const { 
        locations
    } = useSelector(({ locations }) => locations);

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(cleanup());
        }
    }, []);

    useEffect(() => {
        if(completeError){
          enqueueSnackbar(completeError, {variant: "error"});
        }
    }, [completeError]);

    useEffect(() => {
        if(completedResult){
            enqueueSnackbar("Survey saved successfully", {variant: "success"});
            onComplete(surveyInfo.surveyId, completedResult);
        }
    }, [completedResult]);

    const handleOnCloseConfirm = () => {
        setOpenConfirmModal(false);
    };

    const handleConfirmComplete = () => {
        const finalData = {
            id: activitySurveyId,
            workflowActivityStepCompletedId,
            surveyId: surveyInfo.surveyId
        };

        if(locations && locations.length){
            finalData.locations = locations.map(l => {
                return {
                    dictionaryCountryId: l.metadata?.locationInformation?.dictionaryCountryId,
                    countryCode: l.metadata?.locationInformation?.countryCode
                };
            });
        }

        dispatch(complete(finalData));
        
        setOpenConfirmModal(false);
    }

    const onSubmit = () => {
        setOpenConfirmModal(true);
    }

    return (
        <Box sx={{ width: '100%', paddingTop: '20px' }}>
            <LocationsList 
              assetId={activitySurvey.assetActivitySurveys[0].id}
            />

            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '20px'
            }}>
                <Button variant="contained" size="large" onClick={onSubmit}>Save</Button>
            </Box>

            {openConfirmModal &&
                <ConfirmationDialog 
                    open={openConfirmModal}
                    onConfirm={handleConfirmComplete}
                    onClose={handleOnCloseConfirm}
                    title="Complete survey"
                    body={`Sei sicuro di voler procedere? La conferma chiude il task dell’attività e verrà inviata una mail al responsabile della Campagna.`}
                    color='primary'
                    confirmButtonText='Complete'
                />
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isCompleting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
};

export default StakeholderLocationsSurvey;