import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getAssets, sendSurveys, getRolesAndUsers, getWorkflowRoles, getAuditCampaign, getSurveys, cleanup } from "@/reducers/auditCampaign/sendSurveys/AUSendSurveysAction";
import { enqueueSnackbar } from "notistack";
import { getStakeholderActivities } from "@/reducers/auditCampaign/stakeholderActivities/stakeholderActivitiesAction";
import ReducedActivityList from "../../../Home/components/reducedActivityList";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';

const AUCampaignListActivities = () => {
  const { 
    activity, 
    workflowActivityStepCompletedId,
    isEditor,
    handleRefreshProgress,
    handleClickNext
  } = useOutletContext();
  
  const dispatch = useDispatch();

  const { 
    activities, isLoadingActivities, getActivitiesError,
  } = useSelector(({ stakeholderActivities }) => stakeholderActivities);

  useEffect(() => {
      const data = {
        workflowActivityId: activity.workflowActivityId
      };

      dispatch(getStakeholderActivities(data));

      return () => {
          dispatch(cleanup());
      }
  }, [dispatch]);

  useEffect(() => {
      if(getActivitiesError){
        enqueueSnackbar(getActivitiesError, {variant: "error"});
      }
  }, [getActivitiesError]);

  return(
    <>
      {isLoadingActivities ? <CircularProgressWrapper /> : 
        <ReducedActivityList activities={activities} />
      }
    </>
  );
};

export default AUCampaignListActivities;